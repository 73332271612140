import React from 'react';

export const Logo = (props) => {

  const getDomain = () => {
    return window.location.href.split('/')[2].split(':')[0];
  }
  
  const getLogo = () => {
    return require(`../../assets/img/logo/${getDomain()}.png`);
  }
  
  return (
    <img
      alt="Logo"
      src={ getLogo() }
      style={{
        height: "40px"
      }}
      {...props}
    />
  );
};

export const PortalLogo = (props) => {
  const getDomain = () => {
    return window.location.href.split('/')[2].split(':')[0];
  }

  const getLogo = () => {
    return require(`../../assets/img/logo/${getDomain()}.png`);
  }

  return (
    <img
      alt="Logo"
      src={ getLogo() }
      style={{
        height: "30px"
      }}
      {...props}
    />
  );
}