import * as React from 'react';
import { Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Logo } from './Logo';
import { Input } from "@progress/kendo-react-inputs";
import { setRecordValue } from '../CaUI/CaGenericDataEntry';
import { loginStyles } from "./loginStyles.css";
import { Loader } from "@progress/kendo-react-indicators";

export const Login = props => {
  const [userFormData, setUserFormData] = React.useState({
    userName: '',
    password: '',
    domain: ''
  });
  const [invalidForm, setInvalidForm] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [displayLoading, setDisplayLoading] = React.useState(false);
  
  const validateInput = () => {
    let invalid = false;
    if (!userFormData.userName || userFormData.userName === "")
      invalid = true;
    if (!userFormData.password || userFormData.password === "")
      invalid = true;
    return invalid;
  }

  // Display the error message in a notification towards the top
  const handleErrors = response => {
    if (!response.ok) {
      setErrorMessage("Unable to log in at this time. Please try again later.");
      setInvalidForm(true);
    }
    return response;
  }
  
  const handleSubmit = e => {
    e.preventDefault();
    setInvalidForm(false);
    setErrorMessage("");
    setDisplayLoading(true);
    
    const error = validateInput();

    if (!error) {
      let data = userFormData;
      data.domain = getDomain();
      
      let options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      }

      const saveUrl = `api/User/Login`;

      fetch(saveUrl, options)
        .then(handleErrors)
        .then(response => {return response.json()})
        .then(user => {
          if (!user || user === "") {
            setErrorMessage("Bad username and/or password.");
            setInvalidForm(true);
            setDisplayLoading(false);
          } else {
            document.title = user.tenantName;
            props.toggleUI(true, user);  
          }
        })
        .catch(e => {
          setDisplayLoading(false);
        });
    } else {
      setErrorMessage("Username and Password are required!");
      setInvalidForm(true);
      setDisplayLoading(false);
    }
  }
  
  const TitleComponent = props => {
    return <div className={".ca-window-titlebar"}>
      <Logo />
    </div>
  }

  const getDomain = () => {
    return window.location.href.split('/')[2].split(':')[0];
  }
  
  return (
    <Window
      title={<TitleComponent />}
      onClose={() => props.toggleVisibility(false)}
      initialHeight={400}
      initialWidth={350}
      minWidth={350}
      minHeight={400}
      appendTo={document.getElementById('root')}
      minimizeButton={() => null}
      maximizeButton={() => null}
      closeButton={() => null}
      style={loginStyles}
    >
      <form className="k-form">
        <div className={"row"}>
          <div className={"col-md-12"} style={{paddingTop: "10px"}}>
            <h4><b>Please log in</b></h4>
          </div>
        </div>
        {invalidForm && <div className={"row"}>
          <div className={"col-md-12"}><span style={{color: "#FD72B0"}}>{errorMessage}</span></div>
        </div>
        }
        <div className={"row"}>
          <div className={"col-md-12"}>
            <label className="k-form-field">
              <span><b>User Name</b></span>
              <Input
                value={userFormData.userName}
                name="userName"
                onChange={e => setRecordValue(e, userFormData, setUserFormData)}
                required={true}
              />
            </label>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-12"}>
            <label className="k-form-field">
              <span><b>Password</b></span>
              <Input
                value={userFormData.password}
                name="password"
                type="password"
                onChange={e => setRecordValue(e, userFormData, setUserFormData)}
                required={true}
              />
            </label>
          </div>
        </div>
        <span className={'k-form-separator'} />
        <span>&nbsp;</span>
        <div className={"row"}>
          { displayLoading &&
            <div className={"col-md-12 text-right"}>
              <span><b>Please Wait...</b>&nbsp;&nbsp;&nbsp;<Loader themeColor={"info"} size={"small"} type={"converging-spinner"}/></span>
            </div>
          }
          { !displayLoading &&
            <div className={"col-md-12 text-right"}>
              <Button
                className="k-primary ca-edit-dialog-button"
                onClick={(e) => handleSubmit(e)}>
                <b>Login</b>
              </Button>
            </div>
          }
        </div>
      </form>
    </Window>
  );
}