import * as React from 'react';
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { CaContainerGridStyle } from "./CaUIStyles";

export function telerikGridWithState(WrappedGrid, baseUrl, detailComponent, rowsPerPage) {
    return class StatefulGrid extends React.Component {
        constructor(props) {
            super(props);
            this.state = { dataState: { skip: 0, take: rowsPerPage ? rowsPerPage : 10 } };
            this.refreshGrid = this.refreshGrid.bind(this);
            this.deleteRecord = this.deleteRecord.bind(this);
        }

        render() {
            return (
                <WrappedGrid
                    style={this.props.style ? this.props.style : CaContainerGridStyle}
                    filterable={true}
                    sortable={true}
                    pageable={{ pageSizes: true }}
                    {...this.props}
                    total={this.state.total}
                    data={this.state.data}
                    detail={detailComponent}
                    expandField={detailComponent ? "expanded" : ""}
                    onExpandChange={this.expandChange}
                    skip={this.state.dataState.skip}
                    pageSize={this.state.dataState.take}
                    filter={this.state.dataState.filter}
                    sort={this.state.dataState.sort}
                    onDataStateChange={this.handleDataStateChange}
                    rowRender={this.props.rowRender}
                    additionalFilters={this.props.additionalFilters}
                />
            );
        }

        componentDidMount() {
            this.refreshGrid(this.props.additionalFilters);
        }

        handleDataStateChange = (changeEvent) => {
            this.setState({ dataState: changeEvent.data });
            if (changeEvent.data.filter)
            {
                if (changeEvent.data.filter.filters && (changeEvent.data.filter.filters[0].value.length > 2 || changeEvent.data.filter.filters[0].value == 0))
                {
                    this.fetchData(changeEvent.data);
                }
            }
            else
            {
                this.fetchData(changeEvent.data);
            }
        }

        fetchData(dataState) {
            let queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state.
            const hasGroups = dataState.group && dataState.group.length;
            const init = { method: 'GET', accept: 'application/json', headers: {} };

            fetch(`${baseUrl}?${queryStr}`, init)
                .then(response => response.json())
                .then(({ data, total }) => {
                    this.setState({
                        data: hasGroups ? translateDataSourceResultGroups(data) : data,
                        total,
                        dataState
                    });
                }).catch(e => {
                    console.log(e);
                });
        }

        deleteRecord(api, options, callback) {
            const refreshGrid = this.refreshGrid;
            fetch(api, options)
                .then((response) => {
                    refreshGrid();
                    callback(response);
                })
                .catch(function (err) { });
        }

        refreshGrid(additionalFilters) {
            if (additionalFilters && additionalFilters.field && additionalFilters.value != null)
            {
                if (!this.state.dataState.filter)
                {
                    this.state.dataState.filter = {filters:[additionalFilters]};
                }
                else
                {
                    var newFilter = this.state.dataState.filter.filters.find(element => element.field == additionalFilters.field);
                    if (newFilter)
                    {
                        newFilter.value = additionalFilters.value;
                    }
                    else
                    {
                        this.state.dataState.filter.filters.push(additionalFilters);
                    }
                }
            }
            else if (additionalFilters && additionalFilters.field && !additionalFilters.value)
            {
                var additionalFiltersField = this.state.dataState.filter.filters.find(element => element.field == additionalFilters.field)
                var index = this.state.dataState.filter.filters.indexOf(additionalFiltersField);
                if (index > -1)
                {
                    if (this.state.dataState.filter.filters.length == 1)
                    {
                        this.state.dataState.filter = null;
                    }
                    else
                    {
                        this.state.dataState.filter.filters.splice(index, 1);
                    }
                }
            }
            this.fetchData(this.state.dataState);
        }

        expandChange = (event) => {
            event.dataItem.expanded = !event.dataItem.expanded;
            this.forceUpdate();
        }
    }
}