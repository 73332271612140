import * as React from 'react';
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";

export const toggleNotify = (state, hook) => {
  let newState = Object.assign({}, state);
  hook(state);
  if (state.visible) {
    setTimeout(() => {
      newState.visible = false;
      hook(newState);
    }, 8000)
  }
}

export const CaNotification = props => {
  return (
    <NotificationGroup
      style={{
        top: 0, left: '50%', transform: 'translateX(-50%)'
      }}
    >
      <Fade enter={true} exit={true}>
        {props.settings.visible && <Notification
          type={{ style: props.settings.type, icon: true}}
          closable={true}
          onClose={props.onClose}
        >
          {props.settings.message}
        </Notification>}
      </Fade>
    </NotificationGroup>
  );
}