import React, { Component } from 'react';
import { Scheduler, MonthView, DayView, WeekView, SchedulerItem, SchedulerHeader} from '@progress/kendo-react-scheduler';
import { ToolbarSeparator } from "@progress/kendo-react-buttons";
import { AssignmentDataEntry } from "./assignment/AssignmentDataEntry";
import { QuoteDataEntry } from "./Quote/QuoteDataEntry";
import moment from 'moment';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { beginAuthentication } from "./security/GoogleAuth";
import UserContext from './CaUI/CaContext';
import googleLogo from "../assets/img/btn_google_signin_dark_normal_web.png";

export const Home = props => {
  const user = React.useContext(UserContext);
  const [calendarData, setCalendarData] = React.useState([{
    id: 0,
    title: 'Breakfast with Tom',
    start: new Date("2020-12-02T11:30:00.000Z"),
    end: new Date("2020-12-02T12:00:00.000Z"),
  }]);
  const [oAuthDialogVisible, setOAuthDialogVisible] = React.useState(false);
  const [oAuthDialogStatus, setOAuthDialogStatus] = React.useState({
    title: 'OAuth Success',
    message: 'Authentiation Successful.'
  });

  const onAuthComplete = event => {
    const status = {
      title: 'Success',
      message: 'Authentiation Successful.'
    };
    if (event.data === "OAuthSuccess") {
      user.googleAuthorized = true;
      setOAuthDialogStatus(status);
    } else {
      status.title = 'Unauthorized';
      status.message = "Failed to Authenticate with Google. Please try again later.";
      setOAuthDialogStatus(status);
    }
    setOAuthDialogVisible(true);
  }
  
  const [visible, setVisible] = React.useState(false);
  const [quoteVisible, setQuoteVisible] = React.useState(false);
  const [editId, setEditId] = React.useState(0);
  const [lastDate, setLastDate] = React.useState(new Date());
  const handleErrors = response => {
    if (!response.ok) {
      // TODO: Handle error
    }
    return response;
  }

  const handleVisibility = isVisible => {
    setVisible(isVisible);
    if (!isVisible) {
      fetchCalendarAppointments(lastDate);
    }
  }

  const handleQuoteVisibility = isVisible => {
    setQuoteVisible(isVisible);
    if (!isVisible) {
      fetchCalendarAppointments(lastDate);
    }
  }
  
  const CustomItem = props => {
    return <SchedulerItem
      { ...props }
      style={{
        ...props.style,
      }}
      className={props.dataItem.appointmentType === 'Assignment' ? "CaAssignmentAppointment" : "CaQuoteAppointment"}
      onClick={(e) => {
        if (props.dataItem.appointmentType === 'Assignment') {
          setEditId(e.target.props.dataItem.key);
          setVisible(true);
        } else {
          setEditId(e.target.props.dataItem.key);
          setQuoteVisible(true);
        }
      }}
    />
  }

  const fetchCalendarAppointments = (date) => {
    setCalendarData([]);
    const start = moment(date).startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
    const end = moment(date).endOf('month').add(7, 'days').format('YYYY-MM-DD');
    
    const url = `./api/Calendar/FetchAppointments/${start}/${end}`;
    const options ={
      method: 'GET',
      headers: {'content-type': 'application/json'}
    };

    fetch(url, options)
      .then(handleErrors)
      .then(response => {
        return response.json()
      })
      .then(data => {
        // massage dates (remember they come over in json as strings)
        for (let i = 0; i < data.length; i++) {
          data[i].start = new Date(data[i].start);
          data[i].end = new Date(data[i].end);
          data[i].isAllDay = false;
        }
        setCalendarData(data);
      })
      .catch(e => {});
  }

  const handleSchedulerChangeEvent = e => {
    setLastDate(e.value);
    fetchCalendarAppointments(e.value);
  }
  
  React.useEffect(() => {
    fetchCalendarAppointments(new Date());
  }, []);
  
  const customHeader = props => {
    return <SchedulerHeader>
      {props.children}
      <ToolbarSeparator />
      &nbsp;&nbsp;Google Sync:&nbsp;&nbsp; {user.googleAuthorized ? <b style={{color: "#519a76"}}>Enabled</b> : <b style={{color: "#D45A5A"}}>Disabled</b>}
      {user.googleAuthorized ? 
        <React.Fragment/> 
        : 
        <button 
          className={"k-button k-button-clear"} 
          style={{margin: 0, padding: 0}} 
          onClick={() => {beginAuthentication(onAuthComplete);}}
        >
        <img style={{width: '141px', height:'34px'}} src={googleLogo} alt="Authorize with Google"/>
      </button>}
    </SchedulerHeader>
  }
  
  return (
    <React.Fragment>
      <Scheduler header={customHeader} item={CustomItem} data={calendarData} timezone="Etc/UTC" onDateChange={handleSchedulerChangeEvent}>
        <MonthView />
        <WeekView />
        <DayView />
      </Scheduler>
      { visible && <AssignmentDataEntry editId={editId} toggleVisibility={handleVisibility} /> }
      { quoteVisible && <QuoteDataEntry editId={editId} toggleVisibility={handleQuoteVisibility} /> }
      { oAuthDialogVisible &&
      <Dialog title={oAuthDialogStatus.title} onClose={() => setOAuthDialogVisible(false)}>
        <p style={{ margin: "25px", textAlign: "center" }}>{oAuthDialogStatus.message}</p>
        <DialogActionsBar>
          <button className="k-button" onClick={() => setOAuthDialogVisible(false)}>Close</button>
        </DialogActionsBar>
      </Dialog>
      }
    </React.Fragment>
  );
}