import * as React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import { ProviderDataEntry } from "./ProviderDataEntry";
import { telerikGridWithState } from "../CaUI/TelerikGridWithState";
import { CaDateCell } from "../CaUI/CaDateCell";
import { CaGridHeader } from "../CaUI/CaGridHeader";
import { CaButton } from "../CaUI/CaButtons";
import moment from 'moment';

/*
 This component shows detailed information in 
 */
const DetailComponent = (props) => {
  return(
    <section>
      <p><strong>Phone:</strong> {props.dataItem.phone}</p>
      <p><strong>Email</strong> {props.dataItem.email}</p>
    </section>
  );
}

// Stateful grid contains some wizz-bang features taken from Telerik Docs, such as infinite paging, sorting, etc...
// that we would like on by default. So we're going to semi-wrap this into a reusable component for you to use.
const StatefulGrid = telerikGridWithState(Grid, 'api/Provider/GetProviderGridView', DetailComponent);

export const ProviderGridView = props => {
  const [visible, setVisible] = React.useState(false);
  const [editId, setEditId] = React.useState(0);
  const [addVisible, setAddVisible] = React.useState(false);
  const gridElement = React.createRef();
  const [additionalFilters, setAdditionalFilters] = React.useState({});

  const handleAddNewClick = e => {
    setAddVisible(true);
  }

  const handleEditClick = (e, id) => {
    setEditId(id);
    setVisible(true);
  }

  const handleVisibility = isVisible => {
    setVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  const handleAddVisibility = isVisible => {
    setAddVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  return (
    <React.Fragment>
      <CaGridHeader title={"Providers"} onAddNewClick={handleAddNewClick} />
      <StatefulGrid ref={gridElement}>
        <GridColumn field="id" title="ID" width="150px" />
        <GridColumn field="name" title="Name" />
        <GridColumn field="city" title="City" />
        <GridColumn field="billedThisYear" title={moment().year() + " Billing"} format="{0:c}" filterable={false} />
        <GridColumn field="totalBilled" title="Total Billed" format="{0:c}" filterable={false} />
        <GridColumn field="status" title="Status" filterable={false} />
        <GridColumn filterable={false} width="60px"
            cell={ props => (
                <td>
                    <CaButton 
                        className="k-primary ca-edit-dialog-button" 
                        icon="edit"
                        onClick={(e) => handleEditClick(e, props.dataItem.id)}
                    />
                </td>
            )}
        />
      </StatefulGrid>
      { visible && <ProviderDataEntry editId={editId} toggleVisibility={handleVisibility} /> }
      { addVisible && <ProviderDataEntry toggleVisibility={handleAddVisibility} /> }
    </React.Fragment>
  );
}