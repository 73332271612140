import React, { Component } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { Home } from './components/Home';
import { QuoteGridView } from "./components/Quote/QuoteGridView";
import { AssignmentView } from "./components/assignment/AssignmentView";
import { CustomerGridView } from "./components/Customer/CustomerGridView";
import { InvoiceGridView } from "./components/Invoice/InvoiceGridView";
import { ProviderGridView } from "./components/Provider/ProviderGridView";
import { BillGridView } from "./components/Bill/BillGridView";
import { CashJrnlGridView } from "./components/CashJrnl/CashJrnlGridView";
import { DrawerRouterContainer } from './components/DrawerRouterContainer';
import { Login } from "./components/security/Login";
import { UserProvider } from "./components/CaUI/CaContext";
import { NotifyContext } from "./components/CaUI/CaContext";
import { OAuthSuccessView } from "./components/security/OAuthSuccessView";
import { OAuthFailure} from "./components/security/OAuthFailure";
import { PayInvoice } from './components/Invoice/PayInvoice';
import { AcceptQuote } from './components/Quote/AcceptQuote'

export default class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      showGems: false, 
      showLogin: false, 
      showPayment: false,
      user: {}, 
      theme: './assets/css/Default.css',
      globalNotify: {
        showNotify: false,
        notifyMessge: 'Loading Please Wait...',
        setShowNotify: this.setShowNotify  
      }
    };
    
    this.checkLoginStatus = this.checkLoginStatus.bind(this);
    this.setTheme = this.setTheme.bind(this);
  }
  
  setShowNotify = (value, message) => {
    let currentState = Object.assign({}, this.state);
    currentState.globalNotify.showNotify = value;
    currentState.globalNotify.message = message;
    this.setState({...currentState});
  }

  setLoginStatus = (valid, user) => {
    let currentState = Object.assign({}, this.state);
    currentState.showGems = valid;
    currentState.showLogin = !valid;
    currentState.user = user;
    this.setState({...currentState});
    if (user.colorScheme) {
      this.setTheme(user.colorScheme);
    }
  }
  
  logout = () => {
    let currentState = Object.assign({}, this.state);
    const logoutUrl = `api/User/Logout`;
    let options = {
      method: 'POST'
    }

    fetch(logoutUrl, options)
      .then(response => {
        currentState.showGems = false;
        currentState.showLogin = true;
        currentState.user = {};
        this.setState({ ...currentState});
        this.setTheme("./assets/css/Default.css");
      })
      .catch(e => {});
  }
  
  setTheme = (theme) => {
    let currentState = Object.assign({}, this.state);
    currentState.theme = theme;
    this.setState({...currentState});
  }

  checkLoginStatus = () => {
    let currentState = Object.assign({}, this.state);
    const loginStatusUrl = 'api/User/CheckStatus';
    fetch(loginStatusUrl)
      .then(response => {
        return response.json();
      })
      .then(user => {
        if (user && user.loggedIn) {
          document.title = user.tenantName;
          currentState.showLogin = false;
          currentState.showGems = true;
          currentState.user = user;
          this.setState({ ...currentState });
          
          if (user.colorScheme) {
            this.setTheme(user.colorScheme);
          }
        } else {
          currentState.showLogin = true;
          currentState.showGems = false;
          currentState.user = user;
          this.setState({ ...currentState });
        }
      })
      .catch(e => {});
  }
  
  componentDidMount() {
    // TODO: check URL for payInvoice?GUID=0000-00000-00000-00000 if found, do not check login status.
    if (window.location.href.includes("/payInvoice?")) {
      let state = Object.assign({}, this.state);
      state.showPayment = true;
      this.setState({...state});
    } else if (window.location.href.includes("/CheckAcceptQuote?")) {
      let state = Object.assign({}, this.state);
      state.showAcceptQuote = true;
      this.setState({...state});
    } else {
      this.checkLoginStatus();
    }
  }

  render () {
    return (
      <React.Fragment>
        <link rel="stylesheet" href={this.state.theme} />
        { this.state.showPayment && <PayInvoice />}
        { this.state.showAcceptQuote && <AcceptQuote />}
        { this.state.showLogin && <Login toggleUI={this.setLoginStatus}/> }
        { this.state.showGems &&
          <UserProvider value={this.state.user}>
            <NotifyContext.Provider value={this.state.globalNotify}>
              <HashRouter>
                <DrawerRouterContainer loggedInUser={this.state.user} logOut={this.logout} changeTheme={this.setTheme}>
                  <Switch>
                    <Route exact={true} path="/" component={Home} />
                    <Route exact={true} path="/Quote" component={QuoteGridView} />
                    <Route exact={true} path="/Assignment" component={AssignmentView} />
                    <Route exact={true} path="/Customer" component={CustomerGridView} />
                    <Route exact={true} path="/Invoice" component={InvoiceGridView} />
                    <Route exact={true} path="/Provider" component={ProviderGridView} />
                    <Route exact={true} path="/Bill" component={BillGridView} />
                    <Route exact={true} path="/CashJrnl" component={CashJrnlGridView} />
                    <Route exact={true} path="/OAuthSuccess" component={OAuthSuccessView} />
                    <Route exact={true} path="/OAuthFailure" component={OAuthFailure} />
                  </Switch>
                </DrawerRouterContainer>
              </HashRouter>
            </NotifyContext.Provider>
          </UserProvider>
        }
      </React.Fragment>
    );
  }
}
