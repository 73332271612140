import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardActions,
    CardImage,
} from "@progress/kendo-react-layout";

export const PayInvoice = (props) => {
    const [invoiceDetails, setInvoiceDetails] = React.useState({
        name: "",
        invoiceNo: "",
        cost: "",
        description: "",
        assignmentType: "",
        assignmentDate: "",
        guid: "",
        ready: false,
        note: ""
    });
    const [dialogContent, setDialogContent] = React.useState({
        title: "",
        message: "",
        paymentSuccessful: false,
        showPayButton: true
    });
    const [showAlert, setShowAlert] = React.useState(false);

    const createJournalEntry = (invoiceInfo) => {
        let options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(invoiceInfo)
        }

        // TODO: Update to return an ID to use for transaction.
        fetch(`./api/CashJrnl/CreateCustomerPayment`, options)
            .then(response => {
                if (response.status === 500) {
                    setDialogContent({
                        paymentSuccessful: false,
                        showPayButton: false,
                        title: "Payment Failed",
                        message: <React.Fragment>
                            <p>An error occurred while processing this payment. Please contact us using invoice# {invoiceInfo.invoiceNo} as a reference.</p>
                        </React.Fragment>
                    });
                    setShowAlert(true);
                } else {
                    return response.text();
                }
            })
            .then(result => {
                if (result) {
                    setDialogContent({
                        paymentSuccessful: true,
                        showPayButton: false,
                        title: "Payment Success",
                        message: <React.Fragment>
                            <p>Thank you for your payment! <br /><br /><b>NOTE: The ID for this transaction is {result}. Please keep this for your records.</b></p>
                        </React.Fragment>
                    });
                    setShowAlert(true);
                }
            })
            .catch(e => {
                setDialogContent({
                    paymentSuccessful: true,
                    showPayButton: false,
                    title: "Payment Failed",
                    message: <React.Fragment>
                        <p>An error occurred while processing this payment. Please contact us using invoice# {invoiceInfo.invoiceNo} as a reference.</p>
                    </React.Fragment>
                });
            })
    }

    const checkQueryStatus = (query, invoiceInfo) => {
        if (query.get("success")) {
            createJournalEntry(invoiceInfo);
        }

        if (query.get("canceled")) {
            setDialogContent({
                showPayButton: true,
                paymentSuccessful: false,
                title: "Payment Cancelled",
                message: "Payment process has been cancelled."
            });
            setShowAlert(true);
        }
    }

    const prepUi = (invoiceInfo, query) => {
        if (invoiceInfo.ready) {
            invoiceInfo.name = `${invoiceInfo.name} - INV: ${invoiceInfo.invoiceNo} FOR: - ${invoiceInfo.assignmentType}`
            setInvoiceDetails(invoiceInfo);
            checkQueryStatus(query, invoiceInfo);
        } else {
            setInvoiceDetails(invoiceInfo);
            // note field returned will have the message to display to the user on why invoice is not ready for processing
            setDialogContent({
                paymentSuccessful: false,
                showPayButton: false, // set to true to hide the button
                title: "Alert",
                message: invoiceInfo.note
            });
            setShowAlert(true);
        }
    }

    React.useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        fetch(`./api/Invoice/GetInvoiceForPayment?guid=${query.get("guid")}`, { method: 'GET'})
            .then(response => response.json())
            .then(invoiceInfo => {
                if (invoiceInfo !== null) {
                    prepUi(invoiceInfo, query);
                }
            });
    }, []);

    const getLogo = () => {
        return require(`../../assets/img/logo/localhost.png`);
    }

    return (
        <React.Fragment>
            <div className={"row"}>
                <div className={"col-md-12"} style={{ margin: "20px" }}>&nbsp;</div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>&nbsp;</div>
                <div className={"col-md-6"}>
                    <form action="./api/Invoice/SubmitPayment" method="POST">
                        <Card orientation="vertical" style={{ boxShadow: "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)" }}>
                            <div>
                                <CardHeader style={{ background: "#4052B6" }}>
                                    <CardImage src={getLogo()} style={{ height: "80px", width: "200px" }} />
                                    <CardTitle style={{ margin: "10px", color: "#ffffff" }}>Payment Submission for Invoice No. {invoiceDetails.invoiceNo}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <input type="hidden" name="invoiceNo" value={invoiceDetails.invoiceNo} />
                                    <input type="hidden" name="name" value={invoiceDetails.name} />
                                    <input type="hidden" name="guid" value={invoiceDetails.guid} />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}>Assignment Type</div>
                                        <div className={"col-md-6"}>
                                            <label className="k-form-field">
                                                <Input
                                                    type="text"
                                                    value={invoiceDetails.assignmentType}
                                                    name="assignmentType"
                                                    readOnly
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-3"}>Assignment Date</div>
                                        <div className={"col-md-6"}>
                                            <label className="k-form-field">
                                                <Input
                                                    type="text"
                                                    value={invoiceDetails.assignmentDate}
                                                    name="assignmentDate"
                                                    readOnly
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-3"}>Description</div>
                                        <div className={"col-md-9"}>
                                            <label className="k-form-field">
                                                <Input
                                                    type="text"
                                                    value={invoiceDetails.description}
                                                    name="description"
                                                    style={{width: "512px"}}
                                                    readOnly
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-3"}>Cost</div>
                                        <div className={"col-md-6"}>
                                            <label className="k-form-field">
                                            {/* <NumericTextBox
                                                format="c2"
                                                value={invoiceDetails.cost}
                                                name="cost"
                                                spinners={false}
                                                min={0}
                                                readOnly
                                            /> */}

                                            <Input
                                                type="text"
                                                value={invoiceDetails.cost}
                                                name="cost"
                                                readOnly
                                            />
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        &nbsp;
                                    </div>
                                </CardBody>
                                <CardActions>
                                    {dialogContent.showPayButton && <Button className="btn btn-primary" type="submit">
                                        <b>Click Here to Enter Payment</b>
                                    </Button>}
                                </CardActions>
                            </div>
                        </Card>
                    </form>
                    <div>&nbsp;</div>
                    {(!dialogContent.paymentSuccessful && dialogContent.showPayButton) && <div 
                        style={{padding: "10px"}}>
                            <b>NOTE:</b> You will be redirected to a secure page to submit your payment for this completed assignment.
                    </div>}
                    {dialogContent.paymentSuccessful && <div style={{textAlign: "center"}}>
                        <b>Thank you for your payment. You may close this browser window.</b>
                    </div>}
                </div>
                <div className={"col-md-3"}>&nbsp;</div>
            </div>

            {showAlert && <Dialog title={dialogContent.title} onClose={() => setShowAlert(false)}>
                <p
                    style={{
                        margin: "10px",
                        width: "225px"
                    }}
                >
                    {dialogContent.message}
                </p>
                <DialogActionsBar>
                    <button className="k-button" onClick={() => setShowAlert(false)}>
                        Close
                    </button>
                </DialogActionsBar>
            </Dialog>}
        </React.Fragment>
    );
}