import * as React from "react";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const CurrencyCell = props => {
  let value = props.dataItem[props.field];
  return (
    <td>{value === null ? '' : formatter.format(value)} </td>
  );
}

export const CaCurrencyCell = (props) => <CurrencyCell {...props} />;
