import * as React from 'react';
import { Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { telerikGridWithState} from "./TelerikGridWithState";
import { Input } from "@progress/kendo-react-inputs";
import ReactDOM from 'react-dom';
import {CaPrimaryButton} from "./CaUIStyles";
import {CaButton} from "./CaButtons";

export const CaSelectionList = props => {
  const StatefulGrid = telerikGridWithState(Grid, props.apiUrl, undefined, 5);
  const [visible, setVisible] = React.useState(false);
  const [displayValue, setDisplayValue] = React.useState("");

  React.useEffect(() => {
    setDisplayValue(props.initialVal);
  }, [props.initialVal]);

  const handleSelectClick = (e, selectedValue, selectedDisplayValue) => {
    let evArgs = {
      target: {
        value: selectedValue,
        props: {
          name: props.name
        }
      }
    }
    // Set the display value based on the selection
    setDisplayValue(selectedDisplayValue);
    // Pass the correct value back to the data entry screen
    props.onChange(evArgs);
  }
  var inputStyle = { position: "absolute", width: "85%"};
  var buttonStyle = { 
    position: "absolute", 
    right: "20px",
    "background-image": "none",
    "margin-left": "25px",
    "font-weight": "bold"
  };
  
  // custom style for the Stateful grid
  var gridStyle = {
    "margin-top": "15px",
    "margin-bottom": "15px",
    "height": "84%"
  }
  
  const getColumnTitle = fieldName => {
    let name = "";
    if (props.columnTitles) {
      name = props.columnTitles.find
    }
  }
  
  const rowRender = (element, obj) => {
    const rowProps = {
      ...element.props,
      onDoubleClick: () => {
        console.log('Double click on '+ obj.dataItem[props.returnCol]);
        handleSelectClick(undefined, obj.dataItem[props.returnCol], obj.dataItem[props.displayCol]);
        setVisible(false);
      }
    };
    return React.cloneElement(element, {...rowProps});
  }
  
  return(
    <React.Fragment>
      <div>
        <div>
          <Input
            name={ props.name }
            value={ displayValue }
            required={ props.required }
            onChange={ props.onChange }
            style={ inputStyle }
            disabled={ props.disabled }
            />
          <CaButton
            icon={"search"}
            onClick={(e) => {
              e.preventDefault();
              setVisible(true);
            }}
            style={ buttonStyle }
            disabled={ props.disabled }
          />
        </div>
      </div>
      { visible && <Window
        title={props.title} 
        onClose={() => setVisible(false)}
        initialHeight={510}
        initialWidth={1024}
        appendTo={document.getElementById('root')}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <StatefulGrid style={ gridStyle } rowRender={rowRender}>
          {props.columns.map((col) => {
            if (typeof col === 'object')
              return <GridColumn field={col.field} title={col.title} />
            else
              return <GridColumn field={col} title="" />
          })}
          <GridColumn 
            filterable={false} width={"60px"}
            cell={ cellProps => (
              <td>
                <CaButton
                  className={"k-primary ca-edit-dialog-button"}
                  icon={"check"}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectClick(e, cellProps.dataItem[props.returnCol], cellProps.dataItem[props.displayCol]);
                    setVisible(false);
                  }} 
                />
              </td>
            )}
          />
        </StatefulGrid>
        <div className={"text-right"}>
          <CaButton
            onClick={(e) => setVisible(false)}
          >
            Cancel
          </CaButton>
        </div>
      </Window> }
    </React.Fragment>
  );
}

