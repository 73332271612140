import * as React from 'react';
import { Input, MaskedTextBox, NumericTextBox, Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CaSelectInput } from '../CaUI/CaSelectInput';
import { CaGenericDataEntry, setRecordValue } from '../CaUI/CaGenericDataEntry';
import { CreateBill } from "../Bill/CreateBill";
import { CaButton } from "../CaUI/CaButtons";
import moment from 'moment';

export const ProviderDataEntry = props => {
  const [record, setRecord] = React.useState({});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(!!props.editId);
  const [editId, setEditId] = React.useState(props.editId);
  const [createBillVisible, setCreateBillVisible] = React.useState(false);

  const formatPreSaveData = () => {
    let saveRecord = Object.assign({}, record);

    if ((saveRecord.state !== null) && (typeof saveRecord.state === 'object'))
    {
        saveRecord.state = saveRecord.state.id;
    }
    if ((saveRecord.status !== null) && (typeof saveRecord.status === 'object'))
    {
        saveRecord.status = saveRecord.status.id;
    }
    if ((saveRecord.category !== null) && (typeof saveRecord.category === 'object'))
    {
        saveRecord.category = saveRecord.category.id;
    }
    if ((saveRecord.qualification !== null) && (typeof saveRecord.qualification === 'object'))
    {
        saveRecord.qualification = saveRecord.qualification.id;
    }

    return saveRecord;
  }

  const formatFetched = (record) => {
    let fr = record;
    fr.startDate = fr.startDate !== null ? new Date(fr.startDate) : null;
    fr.endDate = fr.endDate !== null ? new Date(fr.endDate) : null;
    return fr;
  }

  const validateRecord = () => {
    let error = false;
    let messages = [];

    if (!record.providerId)
    {
        messages.push("Provider ID is required!");
        error = true;
    }
    if (!record.name)
    {
        messages.push('Name is required!');
        error = true;
    }
    if (!record.startDate)
    {
        messages.push("Start Date is required!");
        error = true;
    }
    if (!record.email)
    {
        messages.push("Email is required!");
        error = true;
    }
    if (record.endDate && record.endDate < record.startDate)
    {
        messages.push("End Date must be after Start Date!");
        error = true;
    }

    return ({
      error: error,
      messages: messages
    });
  }
  
  const prepAndSetRecordValue = (e, name) => {
    let event = {
      target: {
        props: {
          name: name
        },
        value: e.value
      }
    }

    setRecordValue(event, record, setRecord);
  }

  const formComponents = () => {
    return(
      <React.Fragment>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>ID</span>
              <Input
                value={record.providerId}
                onChange={e => setRecordValue(e, record, setRecord)}
                name="providerId"
                required={true}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Name</span>
              <Input
                value={record.name}
                name="name"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
                <span>Phone</span>
                  <MaskedTextBox
                    value={record.phone === null ? '': record.phone}
                    name="phone"
                    //mask={"(000) 000-0000 aaaa"}
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Email</span>
              <Input
                value={record.email}
                name="email"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
              />
            </label>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-3"}>&nbsp;</div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Category</span>
              <CaSelectInput
                selectedValue={record.category}
                name="category"
                onChange={e => setRecordValue(e, record, setRecord)}
                apiUrl={"api/CodeMast/GetComboItems/CATEGORY/1"}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Qualification</span>
              <CaSelectInput
                selectedValue={record.qualification}
                name="qualification"
                onChange={e => setRecordValue(e, record, setRecord)}
                apiUrl={"api/CodeMast/GetComboItems/QUALIFY/1"}
              />
            </label>
          </div>
          <div className={"col-md-3"} style={{paddingTop: '20px'}}>
            <label className="k-form-field">
              <span>Disable SMS Notifications&nbsp;&nbsp;&nbsp;</span>
              <Checkbox
                value={record.notifyOptOut}
                onChange={e => prepAndSetRecordValue(e, "notifyOptOut")}
                name="notifyOptOut"
              />
            </label>
          </div>
        </div>
        <TabStrip selected={selectedTab} onSelect={(e) => {setSelectedTab(e.selected)} }>
          <TabStripTab title={"Address Information"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Address 1</span>
                  <Input
                    value={record.address1}
                    name="address1"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Address 2</span>
                  <Input
                    value={record.address2}
                    name="address2"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>City</span>
                  <Input
                    value={record.city}
                    name="city"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>State</span>
                  <CaSelectInput
                    selectedValue={record.state}
                    name="state"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    apiUrl={"api/CodeMast/GetComboItems/STATE/1"}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Zip</span>
                  <Input
                    value={record.zip}
                    name="zip"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title={"Accounting"}>
            <div className={"row"}>
                <div className={"col-md-3"}>
                  <label className="k-form-field">
                    <span>Status</span>
                    <CaSelectInput
                        selectedValue={record.status}
                        name="status"
                        onChange={e => setRecordValue(e, record, setRecord)}
                        apiUrl={"api/CodeMast/GetComboItems/ACCTSTATUS/2"}
                    />
                  </label>
                </div>
                <div className={"col-md-3"}>
                  <label className="k-form-field">
                    <span>Rate</span>
                    <NumericTextBox
                        format="c2"
                        min={0}
                        value={record.rate}
                        name="rate"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Start Date</span>
                  <DatePicker
                    value={record.startDate}
                    name="startDate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>End Date</span>
                  <DatePicker
                    value={record.endDate}
                    name="endDate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                    <span>Gets 1099</span>
                    <Input
                        value={record.gets1099}
                        name="gets1099"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                    </label>
                </div>
                    <div className={"col-md-3"}>
                    <label className="k-form-field">
                    <span>EIN/SSN</span>
                    <Input
                        value={record.einSsn}
                        name="einSsn"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                    <span>Bank Routing</span>
                    <Input
                        value={record.bankRouting}
                        name="bankRouting"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                    <span>Bank Account</span>
                    <Input
                        value={record.bankAccount}
                        name="bankAccount"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                    </label>
                </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>{moment().year()} Billing</span>
                  <NumericTextBox
                    name="billedThisYear"
                    value={record.billedThisYear}
                    format="c2"
                    disabled={true}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Total Billed</span>
                  <NumericTextBox
                    name="totalBilled"
                    value={record.totalBilled}
                    format="c2"
                    disabled={true}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
      </React.Fragment>
    );
  }

  const handleOpenCreateBillWindow = (e) => {
    setCreateBillVisible(true);
  }

  const DataEntryTitle = () => {
    return <div>
      <span>{ editMode ? 'Edit Provider' : 'New Provider' }</span>
      { editMode &&
        <React.Fragment>
          <span style={{ paddingLeft: '10px' }}>|</span>
          <span style={{ paddingLeft: '10px' }}>
              <CaButton
                onClick={(e) => handleOpenCreateBillWindow(e)}
              >
                Create Bill
              </CaButton>
          </span>
        </React.Fragment>
      }
    </div>;
  }

  return(
    <React.Fragment>
      <CaGenericDataEntry
        editId={props.editId}
        toggleVisibility={props.toggleVisibility}
        crudUrl={'api/Provider'}
        fetchUrl={'api/Provider/ProviderExtended'}
        onFormatSaveData={formatPreSaveData}
        onFormatFetchedData={formatFetched}
        title={<DataEntryTitle />}
        formControls={formComponents}
        onValidateRecord={validateRecord}
        onRecordFetched={(record, setEditId)=> {
          setRecord(record);
          setEditId(record.providerId);
          if (record.providerId) {
            setEditMode(true);
          }
        }}
      />
      { createBillVisible && <CreateBill providerId={editId} toggleVisibility={setCreateBillVisible}/> }
    </React.Fragment>
  )
}
