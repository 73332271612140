import * as React from 'react';
import {CaContainerGridHeaderContents} from "../CaUI/CaUIStyles";
import {Button} from "@progress/kendo-react-buttons";
import {BadgeContainer} from "@progress/kendo-react-indicators";

export const CaGridHeader = props => {
  return(
    <React.Fragment>
      <div className={"col-md-12 CaContainerGridHeader"}>
        <div style={ CaContainerGridHeaderContents }>
          {props.title}
          <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>|</span>
          <Button className={"CaPrimaryButton"} onClick={props.onAddNewClick}>
            <BadgeContainer>
              <span className="k-icon k-i-plus-circle" />&nbsp;
              <b>Add</b>
            </BadgeContainer>
          </Button>
          {props.children}
        </div>
      </div>
    </React.Fragment>
  )
}