import * as React from 'react';
import { Input, TextArea, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CaSelectionList } from '../CaUI/CaSelectionList';
import { toggleNotify } from "../CaUI/CaNotification";
import { CaGenericDataEntry, setRecordValue, setTextAreaRecordValue } from '../CaUI/CaGenericDataEntry';
import { CashJrnlDataEntry } from '../CashJrnl/CashJrnlDataEntry';
import { CaButton } from '../CaUI/CaButtons';

export const BillDataEntry = props => {
  const [record, setRecord] = React.useState({type: "MEMO"});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(!!props.editId);
  const [cashJrnlVisible, setCashJrnlVisible] = React.useState(false);
  const [cashJrnlRecord, setCashJrnlRecord] = React.useState(0);

  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment/>
  });

  const formatPreSaveData = () => {
    let saveRecord = Object.assign({}, record);

    if ((saveRecord.provider !== null) && (typeof saveRecord.provider === 'object'))
    {
        saveRecord.provider = saveRecord.provider.id;
    }
    saveRecord.lastUpdate = new Date();

    return saveRecord;
  }

  const formatFetched = (record) => {
    let fr = record;
    fr.billDate = fr.billDate !== null ? new Date(fr.billDate) : null;
    fr.dueDate = fr.dueDate !== null ? new Date(fr.dueDate) : null;
    fr.paidDate = fr.paidDate !== null ? new Date(fr.paidDate) : null;
    fr.lastUpdate = fr.lastUpdate !== null ? new Date(fr.lastUpdate) : null;
    fr.lastExport = fr.lastExport !== null ? new Date(fr.lastExport) : null;
    return fr;
  }

  const validateRecord = () => {
    let error = false;
    let messages = [];

    if (!record.providerId) messages.push('Provider is required!');
    if (!record.billDate) messages.push("Bill Date is required!");

    if (messages.length > 0) error = true;
    return ({
      error: error,
      messages: messages
    });
  }

  const payBill = () => {
    const url = `/api/CashJrnl/CreateCashJrnlFromBill/${record.billId}`;
    const options = {
      method: 'GET',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(handleResponse)
      .then(response => {
        setCashJrnlRecord(response);
        setCashJrnlVisible(true);
      })
      .catch(function(err) {});
  }

  const handleResponse = (response) => {
    if (!response.ok) {
      response.json().then(payload => {
        let message;
        message = <React.Fragment>
          <span>{payload.message}</span>
        </React.Fragment>;
        let notify = {
          visible: true,
          type: 'warning',
          message: message
        }
        toggleNotify(notify, setNotify);
      });
      return response;
    }
    else {
      return response.json();
    }
  }

  const formComponents = () => {
    return(
      <React.Fragment>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>ID</span>
              <Input
                value={record.billId}
                disabled={true}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Provider</span>
              <CaSelectionList
                title="Select Provider"
                name="providerId"
                apiUrl="api/Provider/GetProviderList"
                columns={[
                  {field: "id", title: "ID"},
                  {field: "name", title: "Name"}
                ]}
                returnCol="id"
                displayCol="providerName"
                initialVal={record.providerName}
                recordField={record.providerId}
                onChange={e => setRecordValue(e, record, setRecord)}
              />
            </label>
          </div>
        </div>
        <div class="row">
          <div className={"col-md-12"}>
            <label className="k-form-field">
              <span>Description</span>
              <TextArea
                rows={3}
                value={record.description}
                onChange={e => setTextAreaRecordValue(e, record, setRecord, "description")}
              />
            </label>
          </div>
        </div>
        <TabStrip selected={selectedTab} onSelect={(e) => {setSelectedTab(e.selected)} }>
          <TabStripTab title={"Accounting"}>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Bill Date</span>
                        <DatePicker
                            value={record.billDate}
                            name="billDate"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            required={true}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Due Date</span>
                        <DatePicker
                            value={record.dueDate}
                            name="dueDate"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            required={true}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Paid Date</span>
                        <DatePicker
                            value={record.paidDate}
                            name="paidDate"
                            onChange={e => setRecordValue(e, record, setRecord)}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Last Update</span>
                        <DatePicker
                            value={record.lastUpdate}
                            name="lastUpdate"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            disabled={true}
                        />
                    </label>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Amount</span>
                        <NumericTextBox
                            format="c2"
                            min={0}
                            value={record.amount}
                            name="amount"
                            onChange={e => setRecordValue(e, record, setRecord)}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Balance</span>
                        <NumericTextBox
                            format="c2"
                            min={0}
                            value={record.balance}
                            name="balance"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            disabled={true}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Last Export</span>
                        <DatePicker
                            value={record.lastExport}
                            name="lastExport"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            disabled={true}
                        />
                    </label>
                </div>
            </div>
          </TabStripTab>
        </TabStrip>
      </React.Fragment>
    );
  }

  const DataEntryTitle = () => {
    return <div>
      <span>{ editMode ? 'Edit Bill' : 'New Bill' }</span>
      { editMode &&
        <React.Fragment>
          <span style={{ paddingLeft: '10px' }}>|</span>
          <span style={{ paddingLeft: '10px' }}>
              <CaButton
                onClick={() => payBill()}
              >
                Pay
              </CaButton>
          </span>
        </React.Fragment>
      }
    </div>;
  }

  return(
    <React.Fragment>
      <CaGenericDataEntry
        editId={props.editId}
        toggleVisibility={props.toggleVisibility}
        crudUrl={'api/Bill'}
        fetchUrl={'api/Bill/BillExtended'}
        onFormatSaveData={formatPreSaveData}
        onFormatFetchedData={formatFetched}
        title={<DataEntryTitle />}
        formControls={formComponents}
        onValidateRecord={validateRecord}
        onRecordFetched={(record, setEditId)=> {
          setRecord(record);
          setEditId(record.billId);
          if (record.billId) {
            setEditMode(true);
          }
        }}
      />
      { cashJrnlVisible && <CashJrnlDataEntry record={cashJrnlRecord} toggleVisibility={() => setCashJrnlVisible(false)} /> }
    </React.Fragment>
  )
}
