import * as React from 'react';
import { Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import {CaNotification, toggleNotify} from "./CaNotification";
import {CaButton} from "./CaButtons";

export const setRecordValue = (e, record, setRecord) => {
  let changed = record;
  changed[e.target.props.name] = e.target.value;
  setRecord({...changed});
}

export const setTextAreaRecordValue = (e, record, setRecord, name) => {
  let changed = record;
  changed[name] = e.value;
  setRecord({...changed});
}

export const CaGenericDataEntry = props => {
  const [editId, setEditId] = React.useState(props.editId);
  const [displaySaveIndicator, setDisplaySaveIndicator] = React.useState(false);

  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment/>
  });
  
  const displayValidateError = (statuses) => {
    let notify = {
      visible: true,
      type: 'error',
      message: [
        <React.Fragment>
          <span key={1}>One or more errors were found. Please fix the errors mentioned below and try again:</span>
        </React.Fragment>,
      ]
    }
    
    let counter = 2;
    statuses.messages.map((msg) => {
      notify.message.push(
        <React.Fragment>
          <span key={counter++}><li>{msg}</li></span>
        </React.Fragment>
      );
    });
    toggleNotify(notify, setNotify);
  }
    
  const saveRecord = e => {
    const status = props.onValidateRecord();
    let notify = {
      visible: true,
      type: 'success',
      message: ''
    };

    if (!status.error) {
      let saveRecord = props.onFormatSaveData();
      let options = {
        method: editId ? 'PUT' : 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(saveRecord)
      }

      const saveUrl = `${props.crudUrl}/${editId ? editId : ''}`;
      setDisplaySaveIndicator(true);

      fetch(saveUrl, options)
        .then(handleErrors)
        .then(response => {
          if (response.status >= 400) return;
          
          notify.type = 'success';
          if (response.status === 201) {
            // 201 - record created, let's get that and update the UI.
            notify.message = <span>Record Created.</span>;
            toggleNotify(notify, setNotify);
            return response.json();
          } else {
            response.text().then(message => {
              if (message !== "") {
                notify.message = <span>Record [{editId}] Saved. Warning! {message}</span>
              } else {
                notify.message = <span>Record [{editId}] Saved.</span>
              }
              toggleNotify(notify, setNotify);
            });
          }
        })
        .then(record => {
          if (record) {
            record = props.onFormatFetchedData(record);
            props.onRecordFetched(record, setEditId);
          }
        })
        .catch(e => {
          setDisplaySaveIndicator(false);
        });
    } else {
      displayValidateError(status);
    }
  }
    
  const getSelectedSaves = () => {
    if (!props.augmentSave) return [];
    
    let haveSaves = [];

    // save feature may have been augmented with other records to save, check 
    // if these records were selected to be saved when the submit button was clicked, 
    // otherwise we're going to use the default save.
    for (let x = 0; x < props.augmentSave.length; x++) {
      if (props.augmentSave[x].selected) {
        haveSaves.push(props.augmentSave[x]);
      }
    }
    return haveSaves;
  }
  
  const handleSubmit = e => {
    e.preventDefault();
    let selectedSaves = getSelectedSaves();
    
    if (selectedSaves.length > 0) {
      for (let x = 0; x < selectedSaves.length; x++) {
        const result = selectedSaves[x].onValidate();
        if (!result.error) {
          selectedSaves[x].onSave(setDisplaySaveIndicator);  
        } else {
          displayValidateError(result);
        }
      }
    } else {
      saveRecord(e);  
    }
  }

  // Display the error message in a notification towards the top
  const handleErrors = response => {
    let notify = {
      visible: true,
      type: 'error',
      message: ''
    }
    setDisplaySaveIndicator(false);
    if (!response.ok) {
      response.text().then(text => {
        notify.type = 'error';
        notify.message = 'An error occurred while completing this request. ' + text;
        toggleNotify(notify, setNotify);
      });
    }
    return response;
  }

  const fetchRecord = () => {
    const init = { method: 'GET', accept: 'application/json', headers: {} };
    const url = props.fetchUrl ? props.fetchUrl : props.crudUrl;

    fetch(`${url}/${editId}`, init)
      .then(handleErrors)
      .then(response => response.json())
      .then((result) => {
        // need to format dates returned as Kendo DatePicker controls only work with Date objects
        result = props.onFormatFetchedData(result);
        props.onRecordFetched(result, setEditId);
      });
  }

  React.useEffect(() => {
    if (props.editId)
      fetchRecord();
    else {
      setEditId(props.editId);
    }
  }, [props.editId]);
  
  return (
    <React.Fragment>
      <Window
        // title={props.editId ? `Edit ${props.title}` : `New ${props.title}`}
        title={props.title}
        onClose={() => props.toggleVisibility(false)}
        initialHeight={props.initialHeight ? props.initialHeight : 600}
        initialWidth={props.initialWidth ? props.initialWidth : 1024}
        appendTo={document.getElementById('root')}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <form className="k-form">
          {props.formControls()}
          <span className={'k-form-separator'} />
          <span>&nbsp;</span>
          { displaySaveIndicator &&
            <div className={"col-md-12 text-right"}>
              <span><b>Please Wait...</b>&nbsp;&nbsp;&nbsp;<Loader themeColor={"info"} size={"small"} type={"converging-spinner"}/></span>
            </div>
          }
          { !displaySaveIndicator &&
            <div className={"col-md-12 text-right"}>
              <CaButton 
                onClick={(e) => handleSubmit(e)}>
                Save
              </CaButton>&nbsp;&nbsp;
              <Button style={{"font-weight": "bold"}} onClick={() => props.toggleVisibility(false)}>Close</Button>
            </div>
          }
        </form>
        {props.confirmDialog}
        <CaNotification settings={notify} onClose={() => setNotify(false)}/>
        {props.customNotify}
      </Window>
    </React.Fragment>
  );
}