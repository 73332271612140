import * as React from 'react';
import { Grid, GridColumn, GridCell } from '@progress/kendo-react-grid';
import { BillDataEntry } from "./BillDataEntry";
import { ProviderDataEntry} from "../Provider/ProviderDataEntry";
import { telerikGridWithState } from "../CaUI/TelerikGridWithState";
import { CaDateCell } from "../CaUI/CaDateCell";
import UserContext from "../CaUI/CaContext";
import { CaGridHeader } from "../CaUI/CaGridHeader";
import { CaButton } from "../CaUI/CaButtons";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from '@progress/kendo-react-inputs';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { toggleNotify } from "../CaUI/CaNotification";
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";

/*
 This component shows detailed information in 
 */
const DetailComponent = (props) => {
  return(
    <section>
      <div class="row">
        <div class="col-4">
          <p><strong>Amount:&nbsp;&nbsp;</strong> ${props.dataItem.amount}</p>
        </div>
        <div class="col-4">
          <p><strong>Balance:&nbsp;&nbsp;</strong> ${props.dataItem.balance}</p>
        </div>
      </div>
    </section>
  );
}

const balanceCell = props => {
  const cellProps = Object.assign({}, props);
  cellProps.className = "cashjrnl-gridview-balance";
  return <GridCell {...cellProps} />;
}

// Stateful grid contains some wizz-bang features taken from Telerik Docs, such as infinite paging, sorting, etc...
// that we would like on by default. So we're going to semi-wrap this into a reusable component for you to use.
const StatefulGrid = telerikGridWithState(Grid, 'api/Bill/GetBillGridView', DetailComponent);

export const BillGridView = props => {
  const [visible, setVisible] = React.useState(false);
  const [editId, setEditId] = React.useState(0);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [deleteId, setDeleteId] = React.useState(0);
  const user = React.useContext(UserContext);
  const [addVisible, setAddVisible] = React.useState(false);
  const gridElement = React.createRef();
  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment />
  });
  const [editProvider, setEditProvider] = React.useState({
    editId: 0,
    visible: false
  });
  const [additionalFilters, setAdditionalFilters] = React.useState({
    field: "Balance",
    operator: "neq",
    value: 0
  });

  const handleAddNewClick = e => {
    setAddVisible(true);
  }

  const handleEditClick = (e, id) => {
    setEditId(id);
    setVisible(true);
  }

  const handleConfirmationClick = () => {
    setConfirmationVisible(false);
    deleteBill(deleteId);
  }

  const deleteBill = (id) => {
    const url = `/api/Bill/${id}`;
    const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' }
    }

    gridElement.current.deleteRecord(url, options, handleDelete);
  }

  const handleDelete = (response) => {
      let type;
      if (response.ok) {
          type = 'success';
      } else {
          type = 'warning';
      }
      response.json().then(payload => {
          let message;
          if (payload) {
              message = <React.Fragment>
                  <span>{payload.message}</span>
              </React.Fragment>;
          }
          let notify = {
              visible: true,
              type: type,
              message: message
          }
          toggleNotify(notify, setNotify);
          setDeleteId(0);
      });
  }

  const closeNotification = () => {
    let state = Object.assign({}, notify);
    state.visible = false;
    setNotify({ ...state });
}

  const handleDisplayProviderEntry = (e, id) => {
    e.preventDefault();
    let provObj = Object.assign({}, editProvider);
    provObj.editId = id;
    provObj.visible = true;
    setEditProvider({...provObj});
  }

  const closeProviderEntry = (e) => {
    let provObj = Object.assign({}, editProvider);
    provObj.visible = false;
    setEditProvider({...provObj});
    gridElement.current.refreshGrid();
  }

  const handleVisibility = isVisible => {
    setVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  const handleAddVisibility = isVisible => {
    setAddVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  return (
    <React.Fragment>
      <CaGridHeader title={"Bills"} onAddNewClick={handleAddNewClick} >
        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>|</span>
        Open Balance Only&nbsp;&nbsp;
        <Checkbox
            onChange={e => {
              if (e.target.element.current.checked) {
                setAdditionalFilters({
                  field: "Balance",
                  operator: "neq",
                  value: 0
                });
                gridElement.current.refreshGrid({
                  field: "Balance",
                  operator: "neq",
                  value: 0
                });
              } else {
                setAdditionalFilters({
                  field: "Balance"
                });
                gridElement.current.refreshGrid({
                  field: "Balance"
                });
              }
            }}
            defaultChecked={true}
        />
      </CaGridHeader>
      <StatefulGrid ref={gridElement} additionalFilters={additionalFilters}>
        <GridColumn field="id" title="ID" width="150px" />
        <GridColumn field="billDate" title="Bill Date" format="{0:d}" width="175px" cell={CaDateCell} filter="date"/>
        <GridColumn 
          field="provider" 
          title="Provider"
          cell={ props => {
            let value = props.dataItem[props.field];
            let idField = props.dataItem.providerId;
            return (
              <td><a href={'#'} onClick={(e) => handleDisplayProviderEntry(e, idField)}><b>{value}</b></a></td>
            );
          }}
        />
        <GridColumn field="description" title="Description" width="250px" />
        <GridColumn field="amount" title="Amount" width="100px" format="{0:c}" filterable={false} />
        <GridColumn field="balance" title="Balance" width="100px" format="{0:c}" cell={balanceCell} filterable={false} />
        <GridColumn filterable={false} width="60px"
                    cell={ props => (
                      <td>
                        <CaButton
                          className="k-primary ca-edit-dialog-button"
                          icon="edit"
                          onClick={(e) => handleEditClick(e, props.dataItem.id)}
                        />
                      </td>
                    )}
        />
        <GridColumn
            filterable={false}
            width="60px"
            cell={props => (
                <td title="Delete">
                    <Button
                        className="k-primary ca-delete-button"
                        icon="delete"
                        onClick={() => {
                            setDeleteId(props.dataItem.id);
                            setConfirmationMessage("Are you sure you want to delete Bill [" + props.dataItem.id + "]?");
                            setConfirmationTitle("Delete Bill?");
                            setConfirmationVisible(true);
                        }}
                    />
                </td>
            )}
        />
      </StatefulGrid>
      <NotificationGroup
          style={{
              top: 0, left: '50%', transform: 'translateX(-50%)'
          }}
      >
          <Fade enter={true} exit={true}>
              {notify.visible && <Notification
                  type={{ style: notify.type, icon: true }}
                  closable={true}
                  onClose={() => closeNotification()}
              >
                  {notify.message}
              </Notification>}
          </Fade>
      </NotificationGroup>
      { visible && <BillDataEntry editId={editId} toggleVisibility={handleVisibility} /> }
      { addVisible && <BillDataEntry toggleVisibility={handleAddVisibility} /> }
      { editProvider.visible && <ProviderDataEntry editId={editProvider.editId} toggleVisibility={closeProviderEntry} /> }
      { confirmationVisible && <CaConfirmDialog
        title={confirmationTitle}
        message={confirmationMessage}
        toggleVisibility={setConfirmationVisible}
        onYesClick={() => { handleConfirmationClick() }}
      />}
    </React.Fragment>
  );
}