import * as React from "react";

const DateCell = props => {
  let value = props.dataItem[props.field];
  return (
    <td className={props.className}>{value === null ? '' : (new Date(value)).toLocaleDateString("en-US")} </td>
  );
}

export const CaDateCell = (props) => <DateCell {...props} />;
