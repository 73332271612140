import * as React from 'react';
import {useState, useEffect} from 'react';
import { ComboBox } from "@progress/kendo-react-dropdowns";

export const CaSelectInput = props => {
  let [selectData, setSelectData] = useState();
  
  const fetchComboBoxData = () => {
    fetch(props.apiUrl, { method: 'GET'})
      .then(response => response.json())
      .then(json => {
        setSelectData(json);
      });
  }

  const getVal = (val) => {
    // do not run if we have no combo box items.
    if (!selectData) {
      return val;
    }
    
    if ((typeof val === 'string') || (typeof val === 'number')) {
      let selected = val;
      for (let x = 0; x < selectData.length; x++) {
        if (val === selectData[x].id) {
          selected = selectData[x];
        }
      }
      
      return selected;
    } else {
      return val;
    }
  }
  
  useEffect(() => {fetchComboBoxData()}, []);
  
  return (
    <ComboBox filterable={false} dataItemKey="id" textField="name" data={selectData} value={getVal(props.selectedValue)} {...props} />
  );
}