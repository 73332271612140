import * as React from 'react';
import { Input, TextArea, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CaSelectionList } from '../CaUI/CaSelectionList';
import { CaSelectInput } from '../CaUI/CaSelectInput';
import { CaGenericDataEntry, setRecordValue, setTextAreaRecordValue } from '../CaUI/CaGenericDataEntry';

export const InvoiceDataEntry = props => {
  const [record, setRecord] = React.useState({type: "MEMO"});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(!!props.editId);

  const formatPreSaveData = () => {
    let saveRecord = Object.assign({}, record);

    if ((saveRecord.customer !== null) && (typeof saveRecord.customer === 'object'))
    {
        saveRecord.customer = saveRecord.customer.id;
    }
    saveRecord.lastUpdate = new Date();

    return saveRecord;
  }

  const formatFetched = (record) => {
    let fr = record;
    fr.invDate = fr.invDate !== null ? new Date(fr.invDate) : null;
    fr.dueDate = fr.dueDate !== null ? new Date(fr.dueDate) : null;
    fr.paidDate = fr.paidDate !== null ? new Date(fr.paidDate) : null;
    fr.lastUpdate = fr.lastUpdate !== null ? new Date(fr.lastUpdate) : null;
    fr.lastExport = fr.lastExport !== null ? new Date(fr.lastExport) : null;
    return fr;
  }

  const validateRecord = () => {
    let error = false;
    let messages = [];

    if (!record.customerId) messages.push('Customer is required!');
    if (!record.invDate) messages.push("Invoice Date is required!");

    if (messages.length > 0) error = true;
    return ({
      error: error,
      messages: messages
    });
  }

  const formComponents = () => {
    return(
      <React.Fragment>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>ID</span>
              <Input
                value={record.invoiceId}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Customer</span>
              <CaSelectionList
                title="Select Customer"
                name="customerId"
                apiUrl="api/Customer/GetCustomerList"
                columns={[
                  {field: "id", title: "ID"},
                  {field: "name", title: "Name"},
                  {field: "city", title: "City"},
                  {field: "billName", title: "Bill Name"}
                ]}
                returnCol="id"
                displayCol="name"
                initialVal={record.customerName}
                recordField={record.customerId}
                onChange={e => setRecordValue(e, record, setRecord)}
              />
            </label>
          </div>
          <div className={"col-md-2"}>
            <label className="k-form-field">
              <span>Type</span>
              <CaSelectInput
                selectedValue={record.type}
                name="type"
                onChange={e => setRecordValue(e, record, setRecord)}
                apiUrl={"api/CodeMast/GetComboItems/INVTYPE/0"}
              />
            </label>
          </div>
        </div>
        <div class="row">
          <div className={"col-md-12"}>
            <label className="k-form-field">
              <span>Description</span>
              <TextArea
                rows={3}
                value={record.description}
                onChange={e => setTextAreaRecordValue(e, record, setRecord, "description")}
              />
            </label>
          </div>
        </div>
        <TabStrip selected={selectedTab} onSelect={(e) => {setSelectedTab(e.selected)} }>
          <TabStripTab title={"Accounting"}>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Invoice Date</span>
                        <DatePicker
                            value={record.invDate}
                            name="invDate"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            required={true}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Due Date</span>
                        <DatePicker
                            value={record.dueDate}
                            name="dueDate"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            required={true}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Paid Date</span>
                        <DatePicker
                            value={record.paidDate}
                            name="paidDate"
                            onChange={e => setRecordValue(e, record, setRecord)}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Last Update</span>
                        <DatePicker
                            value={record.lastUpdate}
                            name="lastUpdate"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            disabled={true}
                        />
                    </label>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Hours</span>
                        <NumericTextBox
                            min={0}
                            value={record.hours}
                            name="hours"
                            onChange={e => setRecordValue(e, record, setRecord)}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Amount</span>
                        <NumericTextBox
                            format="c2"
                            min={0}
                            value={record.amount}
                            name="amount"
                            onChange={e => setRecordValue(e, record, setRecord)}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Balance</span>
                        <NumericTextBox
                            format="c2"
                            min={0}
                            value={record.balance}
                            name="balance"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            disabled={true}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Last Export</span>
                        <DatePicker
                            value={record.lastExport}
                            name="lastExport"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            disabled={true}
                        />
                    </label>
                </div>
            </div>
          </TabStripTab>
        </TabStrip>
      </React.Fragment>
    );
  }

  const DataEntryTitle = () => {
    return <div>
      <span>{ editMode ? 'Edit Invoice' : 'New Invoice' }</span>
    </div>;
  }

  return(
    <React.Fragment>
      <CaGenericDataEntry
        editId={props.editId}
        toggleVisibility={props.toggleVisibility}
        crudUrl={'api/Invoice'}
        fetchUrl={'api/Invoice/InvoiceExtended'}
        onFormatSaveData={formatPreSaveData}
        onFormatFetchedData={formatFetched}
        title={<DataEntryTitle />}
        formControls={formComponents}
        onValidateRecord={validateRecord}
        onRecordFetched={(record, setEditId)=> {
          setRecord(record);
          setEditId(record.invoiceId);
          if (record.invoiceId) {
            setEditMode(true);
          }
        }}
      />
    </React.Fragment>
  )
}
