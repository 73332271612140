import * as React from 'react';
import { Input, MaskedTextBox, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CaSelectInput } from '../CaUI/CaSelectInput';
import { CaGenericDataEntry, setRecordValue } from '../CaUI/CaGenericDataEntry';
import { CreateInvoice } from "./CreateInvoice";
import { CaButton } from "../CaUI/CaButtons";
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";
import { CaNotification, toggleNotify } from "../CaUI/CaNotification";
import moment from 'moment';

export const CustomerDataEntry = props => {
  const [record, setRecord] = React.useState(props.record !== undefined ? props.record : {});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(!!props.editId);
  const [editId, setEditId] = React.useState(props.editId);
  const [createInvoiceVisible, setCreateInvoiceVisible] = React.useState(false);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [recordDeleted, setRecordDeleted] = React.useState(false);

  React.useEffect(() => {
    if (props.record) formatFetched(record);
  },[]);

  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment/>
  });

  const closeNotification = () => {
    let state = Object.assign({}, notify);
    state.visible = false;
    toggleNotify({...state}, setNotify);
    if (recordDeleted) {
      props.toggleVisibility(false);
    }
  }

  const toggleNotification = (state) => {
    toggleNotify({...state}, setNotify);
  }

  const handleConfirmationYesClick = (e) => {
    setConfirmationVisible(false);
    if (confirmationTitle === "Delete Customer?") {
      deleteCustomer();
    }
  }

  const deleteCustomer = () => {
    const url = `/api/Customer/${props.editId}`;
    const options = {
      method: 'DELETE',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(handleDelete)
      .catch(function(err) {});
  }

  const handleDelete = (response) => {
    let type;
    if (response.ok) {
      type = 'success';
      setRecordDeleted(true);
    } else {
      type = 'warning';
    }
    response.json().then(payload => {
      let message;
      if (payload) {
        message = <React.Fragment>
          <span>{payload.message}</span>
        </React.Fragment>;
      }
      let notify = {
        visible: true,
        type: type,
        message: message
      }
      toggleNotify(notify, setNotify);
    });
    return response.json();
  }

  const formatPreSaveData = () => {
    // Clone the worked object.
    let saveRecord = Object.assign({}, record);

    if ((saveRecord.state !== null) && (typeof saveRecord.state === 'object'))
    {
        saveRecord.state = saveRecord.state.id;
    }
    if ((saveRecord.status !== null) && (typeof saveRecord.status === 'object'))
    {
        saveRecord.status = saveRecord.status.id;
    }
    if ((saveRecord.invCycle !== null) && (typeof saveRecord.invCycle === 'object'))
    {
        saveRecord.invCycle = saveRecord.invCycle.id;
    }
    
    return saveRecord;
  }

  const formatFetched = (record) => {
    let fr = record;
    fr.startDate = fr.startDate !== null ? new Date(fr.startDate) : null;
    fr.endDate = fr.endDate !== null ? new Date(fr.endDate) : null;
    return fr;
  }

  const validateRecord = () => {
    let error = false;
    let messages = [];

    if (!record.name)
    {
        messages.push('Name is required!');
        error = true;
    }
    if (record.endDate && record.endDate < record.startDate)
    {
        messages.push("End Date must be after Start Date!");
        error = true;
    }

    return ({
      error: error,
      messages: messages
    });
  }

  const formComponents = () => {
    return(
      <React.Fragment>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>ID</span>
              <Input
                value={record.customerId}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Customer Name</span>
              <Input
                value={record.name}
                name="name"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
              />
            </label>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Request Name</span>
              <Input
                value={record.billName}
                name="billName"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
                <span>Phone</span>
                  <MaskedTextBox
                    value={record.phone === null ? '': record.phone}
                    name="phone"
                    //mask={"(000) 000-0000 aaaa"}
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
            </label>
          </div>
          <div className={"col-md-6"}>
            <label className="k-form-field">
              <span>Email</span>
              <Input
                value={record.email}
                name="email"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={false}
              />
            </label>
          </div>
        </div>
        <TabStrip selected={selectedTab} onSelect={(e) => {setSelectedTab(e.selected)} }>
          <TabStripTab title={"Location"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Address 1</span>
                  <Input
                    value={record.address1}
                    name="address1"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Address 2</span>
                  <Input
                    value={record.address2}
                    name="address2"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>City</span>
                  <Input
                    value={record.city}
                    name="city"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>State</span>
                  <CaSelectInput
                    selectedValue={record.state}
                    name="state"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    apiUrl={"api/CodeMast/GetComboItems/STATE/1"}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Zip</span>
                  <Input
                    value={record.zip}
                    name="zip"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title={"Accounting"}>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Status</span>
                        <CaSelectInput
                            selectedValue={record.status}
                            name="status"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            apiUrl={"api/CodeMast/GetComboItems/ACCTSTATUS/0"}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Start Date</span>
                  <DatePicker
                    value={record.startDate}
                    name="startDate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>End Date</span>
                  <DatePicker
                    value={record.endDate}
                    name="endDate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Bank Account</span>
                  <Input
                    value={record.bankAccount}
                    name="bankAccount"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label className="k-form-field">
                        <span>Invoice Cycle</span>
                        <CaSelectInput
                            selectedValue={record.invCycle}
                            name="invCycle"
                            onChange={e => setRecordValue(e, record, setRecord)}
                            apiUrl={"api/CodeMast/GetComboItems/INV_CYCLE/0"}
                        />
                    </label>
                </div>
                <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Invoice Format</span>
                  <Input
                    value={record.invFormat}
                    name="invFormat"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Term Days</span>
                  <NumericTextBox
                      min={0}
                      value={record.termDays}
                      name="termDays"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Bank Routing</span>
                  <Input
                    value={record.bankRouting}
                    name="bankRouting"
                    onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>{moment().year()} Sales</span>
                  <NumericTextBox
                    name="salesThisYear"
                    value={record.salesThisYear}
                    format="c2"
                    disabled={true}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Total Sales</span>
                  <NumericTextBox
                    name="totalSales"
                    value={record.totalSales}
                    format="c2"
                    disabled={true}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Total Outstanding</span>
                  <NumericTextBox
                    name="totalOutstanding"
                    value={record.totalOutstanding}
                    format="c2"
                    disabled={true}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Total Past Due</span>
                  <NumericTextBox
                    name="totalPastDue"
                    value={record.totalPastDue}
                    format="c2"
                    disabled={true}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
      </React.Fragment>
    );
  }
  
  const handleOpenCreateInvoiceWindow = (e) => {
    setCreateInvoiceVisible(true);
  }

  const DataEntryTitle = () => {
    return <div>
      <span>{ editMode ? 'Edit Customer' : 'New Customer' }</span>
      {editMode &&
      <React.Fragment>
        <span style={{paddingLeft: '10px'}}>|</span>
        <span style={{ paddingLeft: '10px' }}>
            <CaButton
              onClick={() => {
                setConfirmationMessage("Are you sure you want to delete this Customer?");
                setConfirmationTitle("Delete Customer?");
                setConfirmationVisible(true);
              }}
            >
              Delete
            </CaButton>
        </span>
        <span style={{paddingLeft: '10px'}}>|</span>
        <span style={{paddingLeft: '10px'}}>
            <CaButton
              onClick={(e) => handleOpenCreateInvoiceWindow(e)}
            >
              Create Invoice
            </CaButton>
          </span>
      </React.Fragment>
      }
    </div>;
  }

  return(
    <React.Fragment>
      <CaGenericDataEntry
        editId={editId}
        toggleVisibility={props.toggleVisibility}
        crudUrl={'api/Customer'}
        fetchUrl={'api/Customer/CustomerExtended'}
        onFormatSaveData={formatPreSaveData}
        onFormatFetchedData={formatFetched}
        title={<DataEntryTitle />}
        formControls={formComponents}
        onValidateRecord={validateRecord}
        onRecordFetched={(record, setEditId)=> {
          setRecord(record);
          setEditId(record.customerId);
          if (record.customerId) {
            setEditMode(true);
          }
        }}
        confirmDialog = { confirmationVisible && <CaConfirmDialog
          title = { confirmationTitle }
          message = { confirmationMessage }
          toggleVisibility = { setConfirmationVisible }
          onYesClick = { handleConfirmationYesClick }
        /> }
        customNotify={<CaNotification settings={notify} onClose={() => closeNotification()} />}
      />
      { createInvoiceVisible && <CreateInvoice customerId={editId} toggleVisibility={setCreateInvoiceVisible}/> }
    </React.Fragment>
  )
}
