import * as React from 'react';
import { withRouter } from 'react-router-dom';
import {
    AppBar,
    AppBarSection,
    AppBarSpacer,
    Drawer,
    DrawerContent,
    Card,
    CardBody,
    Menu,
    MenuItem
} from '@progress/kendo-react-layout';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { PortalLogo } from "./security/Logo";
import { TenantDataEntry } from "./Tenant/TenantDataEntry";
import { QuoteDataEntry } from './Quote/QuoteDataEntry';
import UserContext, { NotifyContext } from './CaUI/CaContext';
import { CaMainContainer } from "./CaUI/CaUIStyles";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { CaNewQuoteList } from './CaUI/CaNewQuoteList';

export const DrawerRouterContainer = withRouter((props) => {
    const [expanded, setExpanded] = React.useState(true);
    const [organizationVisible, setOrganizationVisible] = React.useState(false);
    const user = React.useContext(UserContext);
    const { showNotify, message, setShowNotify } = React.useContext(NotifyContext);
    const [themeValue, setThemeValue] = React.useState({ text: "Default", id: "./assets/css/Default.css" });
    const [newQuotesFound, setNewQuotesFound] = React.useState({
        found: true,
        numOfQuotes: 10
    });
    const [showQuote, setShowQuote] = React.useState(false);
    const [quoteId, setQuoteId] = React.useState(undefined);

    const items = [
        {
            text: 'Calendar',
            icon: 'k-i-calendar',
            selected: true,
            route: '/',
            crud: false
        },
        { separator: true },
        {
            text: 'Quote',
            icon: 'k-i-comment',
            route: '/Quote',
            crud: true,
        },
        {
            text: 'Assignment',
            icon: 'k-i-track-changes-accept-all',
            route: '/Assignment',
            crud: true,
        },
        {
            text: 'Customer',
            icon: 'k-i-folder',
            route: '/Customer',
            crud: true,
        },
        {
            text: 'Invoice',
            icon: 'k-i-report-header-section',
            route: '/Invoice',
            crud: true,
        },
        {
            text: 'Provider',
            icon: 'k-i-user',
            route: '/Provider',
            crud: true,
        },
        {
            text: 'Bill',
            icon: 'k-i-file',
            route: '/Bill',
            crud: true,
        },
        {
            text: 'CashJrnl',
            icon: 'k-i-currency',
            route: '/CashJrnl',
            crud: true,
        },
        {
            route: '/OAuthSuccess',
            crud: false,
            doNotShow: true
        },
        {
            route: '/OAuthFailure',
            crud: false,
            doNotShow: true
        },
    ];

    const handleClick = () => {
        setExpanded(!expanded);
    }

    const onSelect = (e) => {
        props.history.push(e.itemTarget.props.route);
    }

    const setSelectedItem = (pathName) => {
        let currentPath = items.find(item => item.route === pathName);
        if (currentPath.text) {
            return currentPath.text;
        }
    }

    const drawerProps = {
        position: 'start',
        mode: 'push',
        mini: true
    }

    const handleErrors = response => {
        if (!response.ok) {
            // TODO: Handle error
        }
        return response;
    }
    const onMenuItemSelected = (e) => {
        if (e.item.text === 'Settings') {
            setOrganizationVisible(true);
        } else if (e.item.text === 'Logout') {
            props.logOut();
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleViewNewQuote = (id) => {        
        setShowQuote(false);
        sleep(50).then(() => {
            setQuoteId(id);
            setShowQuote(true);
        });
    }

    return (
        <React.Fragment>
            <div>
                <AppBar themeColor="dark">
                    <AppBarSection>
                        <button className="app-bar-button k-button k-button-clear ca-hamburger" onClick={handleClick}>
                            <span className="k-icon k-i-menu" />
                        </button>
                    </AppBarSection>

                    <AppBarSpacer style={{ width: 4 }} />

                    <AppBarSection>
                        {/*<img src={logo} alt="CiteASL" className="logo"/>*/}
                        <PortalLogo />
                    </AppBarSection>

                    <AppBarSpacer style={{ width: 4 }} />

                    <AppBarSpacer />

                    <AppBarSection>
                        {showNotify && <Loader size="small" type="converging-spinner" />}&nbsp;&nbsp;
                        {showNotify && message}
                    </AppBarSection>

                    {newQuotesFound && <AppBarSection className="actions">
                        <CaNewQuoteList onNewQuotesFound={setNewQuotesFound} onViewNewQuote={handleViewNewQuote} />
                    </AppBarSection>}

                    <AppBarSection>
                        <span className="k-appbar-separator" />
                    </AppBarSection>

                    <AppBarSection>
                        {props.loggedInUser && <span className={"CaWelcome"}>Welcome, {props.loggedInUser.fullName}  </span>}
                        <Menu onSelect={(e) => onMenuItemSelected(e)}>
                            <MenuItem icon="gear">
                                <MenuItem icon="user" text="Settings" />
                                <MenuItem text="" cssClass={"k-separator"} enabled={false} />
                                <MenuItem icon="logout" text="Logout" />
                            </MenuItem>
                        </Menu>
                    </AppBarSection>
                </AppBar>
                {organizationVisible && <TenantDataEntry editId={user.tenantId} toggleVisibility={setOrganizationVisible} changeTheme={props.changeTheme} />}
                {showQuote && <QuoteDataEntry editId={quoteId} toggleVisibility={setShowQuote} />}
                <Drawer
                    expanded={expanded}
                    items={
                        items.map((item) => {
                            const selected = setSelectedItem(props.location.pathname);
                            if (!item.doNotShow)
                                return ({ ...item, selected: item.text === selected })
                        })
                    }
                    {...drawerProps}
                    width={170}

                    onSelect={onSelect}
                >
                    <DrawerContent style={{ "overflow-y": "hidden" }}>
                        <Card>
                            <CardBody style={CaMainContainer}>
                                {props.children}
                            </CardBody>
                        </Card>
                    </DrawerContent>
                </Drawer>
            </div>
        </React.Fragment>
    );
});
