import * as React from 'react';
import { Input, MaskedTextBox, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import { CaSelectInput } from '../CaUI/CaSelectInput';
import { CaSelectionList } from "../CaUI/CaSelectionList";
import { CaGenericDataEntry, setRecordValue } from '../CaUI/CaGenericDataEntry';
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";
import { EmailOffer } from "./EmailOffer";
import UserContext from "../CaUI/CaContext";
import { beginAuthentication } from "../security/GoogleAuth";
import { InvoiceDataEntry } from "../Invoice/InvoiceDataEntry";
import { BillDataEntry } from '../Bill/BillDataEntry';
import { CaNotification, toggleNotify } from "../CaUI/CaNotification";
import { CaButton } from "../CaUI/CaButtons";
import moment from 'moment-timezone';

export const AssignmentDataEntry = props => {
  const [record, setRecord] = React.useState({});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [editMode, setEditMode] = React.useState(!!props.editId);
  const [editId, setEditId] = React.useState(props.editId);
  const [invoiceVisible, setInvoiceVisible] = React.useState(false);
  const [invoiceEditId, setInvoiceEditId] = React.useState(0);
  const [billVisible, setBillVisible] = React.useState(false);
  const [billEditId, setBillEditId] = React.useState(0);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [emailParams, setEmailParams] = React.useState({
    StartDate: '',
    StartTime: '',
    EndDate: '',
    EndTime: '',
    City: '',
    State: '',
    AssignType: '',
    ServiceType: '',
    RatePeriod: ''
  });
  const [emailOfferVisible, setEmailOfferVisible] = React.useState(false);
  const [recordDeleted, setRecordDeleted] = React.useState(false);
  const user = React.useContext(UserContext);
  const [displaySyncIndicator, setDisplaySyncIndicator] = React.useState(false);

  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment/>
  });

  const closeNotification = () => {
    let state = Object.assign({}, notify);
    state.visible = false;
    toggleNotify({...state}, setNotify);
    if (recordDeleted) {
      props.toggleVisibility(false);
    }
  }

  const toggleNotification = (state) => {
    toggleNotify({...state}, setNotify);
    //setNotify({...state});
    //toggleNotify(state.show);
  }
  
  const formatPreSaveData = () => {
    // Clone the worked object.
    let saveRecord = Object.assign({}, record);

    if ((saveRecord.assignType !== null) && (typeof saveRecord.assignType == 'object')) {
      saveRecord.assignType = saveRecord.assignType.id;
    }
    if ((saveRecord.serviceType !== null) && (typeof saveRecord.serviceType == 'object')) {
      saveRecord.serviceType = saveRecord.serviceType.id;
    }
    if ((saveRecord.status !== null) && (typeof saveRecord.status == 'object')) {
      saveRecord.status = saveRecord.status.id;
    }
    if ((saveRecord.state !== null) && (typeof saveRecord.state == 'object')) {
      saveRecord.state = saveRecord.state.id;
    }
    if ((saveRecord.ratePeriod !== null) && (typeof saveRecord.ratePeriod == 'object')) {
      saveRecord.ratePeriod = saveRecord.ratePeriod.id;
    }
    if ((saveRecord.startTime !== null) && (typeof saveRecord.startTime === 'object')) {
      let time = saveRecord.startTime;
      saveRecord.startTime = `${time.getHours()}:${time.getMinutes()}:0`;
    }
    if ((saveRecord.endTime !== null) && (typeof saveRecord.endTime === 'object')) {
      let time = saveRecord.endTime;
      saveRecord.endTime = `${time.getHours()}:${time.getMinutes()}:0`;
    }
    saveRecord.lastUpdate = new Date();

    if (typeof record.status === 'object' && record.status.id === 'Unassigned') {
      saveRecord.providerId = null;
      saveRecord.provider = null;
    }

    return saveRecord;
  }

  const formatFetched = (record) => {
    let fr = record;
    fr.startDate = fr.startDate !== null ? new Date(fr.startDate) : null;
    fr.endDate = fr.endDate !== null ? new Date(fr.endDate) : null;
    fr.startTime = fr.startTime !== null ? new Date('01/01/0001 ' + fr.startTime) : null;
    fr.endTime = fr.endTime !== null ? new Date('01/01/0001 ' + fr.endTime) : null;
    fr.lastUpdate = fr.lastUpdate !== null ? new Date(fr.lastUpdate) : null;
    fr.lastCalSync = fr.lastCalSync !== null ? new Date(fr.lastCalSync) : null;
    return fr;
  }

  const validateRecord = () => {
    let error = false;
    let messages = [];

    if (!record.requestName) messages.push("Request Name is required!");
    if (!record.requestPhone) messages.push("Request Phone is required!");
    if (!record.requestEmail) messages.push("Request Email is required!");
    if (!record.assignType) messages.push("Assign Type is required!");
    if (!record.serviceType) messages.push("Service Type is required!");
    if (!record.startDate) messages.push("Start Date is required!");
    if (!record.startTime) messages.push("StartTime is required!");
    if (!record.endDate) messages.push("End Date is required!");
    if (record.endDate < record.startDate) messages.push("End Date must be after Start Date!")
    if (!record.endTime) messages.push("End Time is required!");
    if (record.endTime < record.startTime && record.endDate <= record.startDate) messages.push("End Time must be after Start Time!")

    if (typeof record.status === 'object' && record.status.id === 'Assigned' && record.providerId == null) {
      messages.push("If Status is Assigned, an Interpreter must be selected!");
    }

    if (messages.length > 0) error = true;
    return ({
      error: error,
      messages: messages
    });
  }

  const handleEmailOfferClick = (e) => {
    e.preventDefault();
    let params = Object.assign({}, emailParams);

    if ((record.assignType !== null) && (typeof record.assignType == 'object')) {
      params.AssignType = record.assignType.id;
    } else {
      params.AssignType = record.assignType;
    }

    if ((record.serviceType !== null) && (typeof record.serviceType == 'object')) {
      params.ServiceType = record.serviceType.id;
    } else {
      params.ServiceType = record.serviceType;
    }

    params.StartDate = record.startDate.toLocaleDateString("en-US");
    params.EndDate = record.endDate.toLocaleDateString("en-US");
    params.StartTime = moment(record.startTime, "h:mm A").format("h:mm A");
    params.EndTime = moment(record.endTime, "h:mm A").format("h:mm A");

    params.City = record.city;

    if ((record.state !== null) && (typeof record.state == 'object')) {
      params.State = record.state.id;
    } else {
      params.State = record.state;
    }

    if ((record.ratePeriod !== null) && (typeof record.ratePeriod == 'object')) {
      params.RatePeriod = record.ratePeriod.id;
    } else {
      params.RatePeriod = record.ratePeriod;
    }

    setEmailParams({...params});
    setEmailOfferVisible(true);
  }

  const handleErrors = response => {
    if (!response.ok) {
      let notify = {
        visible: true,
        type: 'warning',
        message: <React.Fragment>Unable to Sync with Google Calendar. Please try again later.</React.Fragment>
      }
      toggleNotify(notify, setNotify);
    }
    return response;
  }

  const isGoogleCalendarRequestValid = () => {
    // Request name, service type start time and end time are required
    const messages = [];
    let counter = 1;

    closeNotification();

    if ((record.requestName === undefined) || (record.requestName === null)) {
      messages.push(<React.Fragment>
        <span key={counter++}><li>Request Name is required.</li></span>
      </React.Fragment>);
    }
    if (record.serviceType === null) {
      messages.push(<React.Fragment>
        <span key={counter++}><li>Please select a service type.</li></span>
      </React.Fragment>);
    }
    if (record.startDate === null) {
      messages.push(<React.Fragment>
        <span key={counter++}><li>Please specify a starting date for this appointment.</li></span>
      </React.Fragment>);
    }
    if (record.startTime === null) {
      messages.push(<React.Fragment>
        <span key={counter++}><li>Please specify a starting time for this appointment.</li></span>
      </React.Fragment>);
    }
    if (record.endDate === null) {
      messages.push(<React.Fragment>
        <span key={counter++}><li>Please specify an ending date for this appointment.</li></span>
      </React.Fragment>);
    }
    if (record.endTime === null) {
      messages.push(<React.Fragment>
        <span key={counter++}><li>Please specify an ending time for this appointment.</li></span>
      </React.Fragment>);
    }

    if (messages.length > 0) {
      messages.unshift(<React.Fragment>
        <span key={0}>Unable to sync with Google Calendar. One or more of the following items were missing:</span>
      </React.Fragment>);
      let notify = {
        visible: true,
        type: 'warning',
        message: messages
      }
      toggleNotify(notify, setNotify);
      return false;
    } else {
      return true;
    }
  }

  const oAuthSuccess = (e) => {
    user.googleAuthorized = true;
    addToGoogleCalendar();
  }

  const handleAddToGoogleCalendarClick = (e) => {
    setDisplaySyncIndicator(true);
    e.preventDefault();
    if (user.googleAuthorized) {
      addToGoogleCalendar(e);
    } else {
      beginAuthentication(oAuthSuccess);
    }
  }

  const addToGoogleCalendar = (e) => {
    e.preventDefault();
    closeNotification();

    if (!isGoogleCalendarRequestValid()) return;

    let serviceType = 'none', state = '';
    if ((record.serviceType !== null) && (typeof record.serviceType == 'object')) {
      serviceType = record.serviceType.id;
    } else {
      serviceType = record.serviceType;
    }

    if ((record.state !== null) && (typeof record.state == 'object')) {
      state = record.state.id;
    } else {
      state = record.state;
    }

    let startDateString = moment(record.startDate).format("YYYY-MM-DD");
    let startTimeString = record.startTime.toTimeString().substring(0,8);
    let start = moment(`${startDateString} ${startTimeString}`).format();

    let endDateString = moment(record.endDate).format("YYYY-MM-DD");
    let endTimeString = record.endTime.toTimeString().substring(0,8);
    let end = moment(`${endDateString} ${endTimeString}`).format();

    let address = `${record.address1} ${record.address2} ${record.city}, ${state} ${record.zip}`;

    let request = {
      id: record.assignmentId,
      summary: `${serviceType}`,
      location: address,
      start: start,
      end: end,
      providerId: record.providerId
    }

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request)
    }

    const url = `./api/Calendar/AddAppointment`;

    fetch(url, options)
      .then(handleErrors)
      .then(response => {
        return response.text()
      })
      .then(text => {
        let message = text;
        let notify = {
          visible: true,
          type: 'success',
          message: <React.Fragment>{message}</React.Fragment>
        }
        toggleNotify(notify, setNotify);
        setDisplaySyncIndicator(false);
      })
      .catch(e => {
        setDisplaySyncIndicator(false);
      });
  }

  const handleInvoiceErrors = response => {
    if (!response.ok) {
      let notify = {
        visible: true,
        type: 'warning',
        message: <React.Fragment>Unable to Create Invoice. Please try again later.</React.Fragment>
      }
      toggleNotify(notify, setNotify);
    } else {
      let notify = {
        visible: true,
        type: 'success',
        message: <React.Fragment>Invoice created.</React.Fragment>
      }
      toggleNotify(notify, setNotify);
    }
    return response;
  }

  const handleBillErrors = response => {
    if (!response.ok) {
      let notify = {
        visible: true,
        type: 'warning',
        message: <React.Fragment>Unable to Create Bill. Please try again later.</React.Fragment>
      }
      toggleNotify(notify, setNotify);
    } else {
      let notify = {
        visible: true,
        type: 'success',
        message: <React.Fragment>Bill created.</React.Fragment>
      }
      toggleNotify(notify, setNotify);
    }
    return response;
  }

  const handleConfirmationYesClick = (e) => {
    setConfirmationVisible(false);
    if (confirmationTitle === "Create Invoice?") {
      createInvoice();
    } else if (confirmationTitle === "Create Bill?") {
      createBill();
    } else if (confirmationTitle === "Delete Assignment?") {
      deleteAssignment();
    }
    else {
      cancelAssignment();
    }
  }

  const createInvoice = () => {
    if (!isParamsForInvoiceValid()) return;
    const url = `/api/Invoice/CreateInvoiceFromAssignment/${props.editId}`;
    const options = {
      method: 'POST',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(handleInvoiceErrors)
      .then(response => response.json())
      .then(response => {
        setInvoiceEditId(response.invoiceId);
        closeNotification();
        let state = Object.assign({}, notify);
        state.type = 'success';
        state.message = <React.Fragment>
          <span>Invoice {response.invoiceId} created.&nbsp;&nbsp;&nbsp; <Button onClick={() => setInvoiceVisible(true)} className={"ca-notify-success-button"}>View Invoice</Button></span>
        </React.Fragment>;
        state.visible = true;
        toggleNotify({...state}, setNotify);

        // change status of record to invoiced
        let invoicedRecord = Object.assign({}, record);
        if (typeof invoicedRecord.status === 'object') {
          invoicedRecord.status.id = 'Invoiced';
          invoicedRecord.status.name = 'Invoiced';
        } else {
          invoicedRecord.status = 'Invoiced';
        }
        // set Assignment.Invoice_Id in the UI
        invoicedRecord.invoiceId = response.invoiceId;
        setRecord(invoicedRecord);
      });
  }

  const createBill = () => {
    if (!isParamsForBillValid()) return;
    const url = `/api/Bill/CreateBillFromAssignment/${props.editId}`;
    const options = {
      method: 'POST',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(handleBillErrors)
      .then(response => response.json())
      .then(response => {
        setBillEditId(response.billId);
        closeNotification();
        let state = Object.assign({}, notify);
        state.type = 'success';
        state.message = <React.Fragment>
          <span>Bill {response.billId} created.&nbsp;&nbsp;&nbsp; <Button onClick={() => setBillVisible(true)} className={"ca-notify-success-button"}>View Bill</Button></span>
        </React.Fragment>;
        state.visible = true;
        toggleNotify({...state}, setNotify);

        // change status of record to invoiced
        let billedRecord = Object.assign({}, record);
        if (typeof billedRecord.status === 'object') {
          billedRecord.status.id = 'Bill Received';
          billedRecord.status.name = 'Bill Received';
        } else {
          billedRecord.status = 'Bill Received';
        }
        // set Assignment.Bill_Id in the UI
        billedRecord.billId = response.billId;
        setRecord(billedRecord);
      });
  }

  const isParamsForInvoiceValid = () => {
    // need to make sure that the fields for this record are valid, otherwise the create
    // process will fail.
    if (!record.customerId) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Missing Customer is required to create an Invoice.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }

    if (!record.providerId) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Provider is required to create an Invoice.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }

    if (!record.saleRate) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Sale Rate is required to create an Invoice.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }

    if (!record.ratePeriod) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Rate Period is required to create an Invoice.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }

    return true;
  }

  const isParamsForBillValid = () => {
    // need to make sure that the fields for this record are valid, otherwise the create
    // process will fail.
    if (!record.providerId) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Provider is required to create a Bill.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }

    if (!record.costAmt) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Cost Amount is required to create a Bill.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }

    return true;
  }

  const checkCreateInvoiceStatus = () => {
    var realStatus = record.status;
    if (typeof record.status === 'object' && record.status != null) {
      realStatus = record.status.id;
    }
    return !(realStatus == 'Completed' || realStatus == 'Canceled') || !(record.invoiceId == null || record.invoiceId.trim() == "");
  }

  const checkCreateBillStatus = () => {
    var realStatus = record.status;
    if (typeof record.status === 'object' && record.status != null) {
      realStatus = record.status.id;
    }
    return !(realStatus == 'Invoiced');
  }

  const checkCancelAssignmentStatus = () => {
    var realStatus = record.status;
    if (typeof record.status === 'object' && record.status != null) {
      realStatus = record.status.id;
    }
    return !(realStatus == 'Unassigned' || realStatus == 'Assigned');
  }

  const handleClone = (response) => {
    if (!response.ok) {
      response.json().then(payload => {
        let message;
        if (payload) {
          setRecord(payload.record);
          message = <React.Fragment>
            <span>{payload.message}</span>
          </React.Fragment>;
        } else {
          message = <React.Fragment>
            <span>{payload.message}</span>
          </React.Fragment>;
        }
        let notify = {
          visible: true,
          type: 'warning',
          message: message
        }
        toggleNotify(notify, setNotify);
      });
      return response;
    }
    else {
      return response.json();
    }
  }

  const handleDelete = (response) => {
    let type;
    if (response.ok) {
      type = 'success';
      setRecordDeleted(true);
    } else {
      type = 'warning';
    }
    response.json().then(payload => {
      let message;
      if (payload) {
        message = <React.Fragment>
          <span>{payload.message}</span>
        </React.Fragment>;
      }
      let notify = {
        visible: true,
        type: type,
        message: message
      }
      toggleNotify(notify, setNotify);
    });
    return response.json();
  }

  const handleCancel = (response) => {
    let type;
    if (response.ok) {
      type = 'success';
    } else {
      type = 'warning';
    }
    response.json().then(payload => {
      let message;
      if (payload) {
        message = <React.Fragment>
          <span>{payload.message}</span>
        </React.Fragment>;
      }
      let notify = {
        visible: true,
        type: type,
        message: message
      }
      toggleNotify(notify, setNotify);
    });
    return response.json();
  }

  const cloneAssignment = () => {
    const url = `/api/Assignment/CloneAssignment/${props.editId}`;
    const options = {
      method: 'GET',
      headers: {'content-type': 'application/json'}
    }
    setRecord({});
    fetch(url, options)
      .then(handleClone)
      .then(response => {
        response = formatFetched(response);
        setRecord({...response})
        setEditId(undefined);
        setEditMode(false);
      })
      .catch(function(err) {});
  }

  const deleteAssignment = () => {
    const url = `/api/Assignment/${props.editId}`;
    const options = {
      method: 'DELETE',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(handleDelete)
      .catch(function(err) {});
  }

  const cancelAssignment = () => {
    const url = `/api/Assignment/CancelAssignment/${props.editId}`;
    const options = {
      method: 'GET',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(handleCancel)
      .catch(function(err) {});
  }

  const isInvoiced = (record) => {
    if (typeof record.status === 'object') {
      return record.status.id === 'Invoiced';
    } else {
      return record.status === 'Invoiced';
    }
  }

  const isBilled = (record) => {
    if (typeof record.status === 'object') {
      return record.status.id === 'Bill Received';
    } else {
      return record.status === 'Bill Received';
    }
  }

  const isCanceled = (record) => {
    if (typeof record.status === 'object') {
      return record.status.id === 'Canceled';
    } else {
      return record.status === 'Canceled';
    }
  }

  const formComponents = () => {
    return(
      <React.Fragment>
        <div className={"row"}>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Assignment ID</span>
              <Input
                value={record.assignmentId}
                disabled={true}
              />
            </label>
          </div>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Quote Reference</span>
              <Input
                value={record.quoteId}
                disabled={true}
              />
            </label>
          </div>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Customer</span>
              <CaSelectionList
                title="Select Customer"
                name="customerId"
                apiUrl="api/Customer/GetCustomerList"
                columns={[
                  {field: "id", title: "ID"},
                  {field: "name", title: "Name"},
                  {field: "city", title: "City"},
                  {field: "billName", title: "Bill Name"}
                ]}
                returnCol="id"
                displayCol="name"
                initialVal={record.customerName}
                recordField={record.customerId}
                onChange={e => setRecordValue(e, record, setRecord)}
                disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
              />
            </label>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Request Name</span>
              <Input
                value={record.requestName}
                name="requestName"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
                disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
              />
            </label>
          </div>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Request Phone</span>
              <MaskedTextBox
                mask={"000-000-0000"}
                value={record.requestPhone === null ? '' : record.requestPhone}
                name="requestPhone"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
                disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
              />
            </label>
          </div>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Request Email</span>
              <Input
                value={record.requestEmail}
                name="requestEmail"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
                disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
              />
            </label>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Status</span>
              <CaSelectInput
                selectedValue={record.status}
                name="status"
                onChange={e => {
                    if (e.target.value.id === "Canceled") {
                      setConfirmationMessage("Are you sure you want to cancel this Assignment?");
                      setConfirmationTitle("Cancel Assignment?");
                      setConfirmationVisible(true);
                    } else {
                      setRecordValue(e, record, setRecord)
                    }
                  }
                }
                apiUrl={"api/CodeMast/GetComboItems/ASSIGNSTAT/2"}
                required={true}
                disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Interpreter</span>
              <CaSelectionList
                title="Interpreter"
                name="providerId"
                apiUrl="api/Provider/GetProviderList"
                columns={[
                  {field: "id", title: "ID"},
                  {field: "name", title: "Name"}
                ]}
                returnCol="id"
                displayCol="name"
                initialVal={record.provider}
                recordField={record.providerId}
                onChange={e => setRecordValue(e, record, setRecord)}
                disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Assignment Type</span>
              <CaSelectInput
                selectedValue={record.assignType}
                name="assignType"
                onChange={e => setRecordValue(e, record, setRecord)}
                apiUrl={"api/CodeMast/GetComboItems/ASSIGNTYPE/2"}
                required={true}
                disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Service Type</span>
              <CaSelectInput
                selectedValue={record.serviceType}
                name="serviceType"
                onChange={e => setRecordValue(e, record, setRecord)}
                apiUrl={"api/CodeMast/GetComboItems/SERVTYPE/2"}
                required={true}
                disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
              />
            </label>
          </div>
        </div>
        <TabStrip selected={selectedTab} onSelect={(e) => {setSelectedTab(e.selected)} }>
          <TabStripTab title={"Assignment Details"}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Start Date</span>
                  <DatePicker
                    value={record.startDate}
                    name="startDate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    required={true}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Start Time</span>
                  <TimePicker
                    value={record.startTime}
                    name="startTime"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    required={true}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                    steps = {{
                      hour: 1,
                      minute: 15,
                      second: 60
                    }}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>End Date</span>
                  <DatePicker
                    value={record.endDate}
                    name="endDate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    required={true}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>End Time</span>
                  <TimePicker
                    value={record.endTime}
                    name="endTime"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    required={true}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                    steps = {{
                      hour: 1,
                      minute: 15,
                      second: 60
                    }}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Address</span>
                  <Input
                    value={record.address1}
                    name="address1"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Address 2</span>
                  <Input
                    value={record.address2}
                    name="address2"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>City</span>
                  <Input
                    value={record.city}
                    name="city"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>State</span>
                  <CaSelectInput
                    selectedValue={record.state}
                    name="state"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    apiUrl={"api/CodeMast/GetComboItems/STATE/1"}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Zip</span>
                  <Input
                    value={record.zip}
                    name="zip"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title={"Onsite Information"}>
            <div className={"row"}>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>ASL Consumer</span>
                  <Input
                    value={record.aslConsumer}
                    name="aslConsumer"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    required={true}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>Hearing Consumer</span>
                  <Input
                    value={record.hearingConsumer}
                    name="hearingConsumer"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    required={true}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>Onsite Name</span>
                  <Input
                    value={record.onsiteName}
                    name="onsiteName"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>Onsite Phone</span>
                  <MaskedTextBox
                    mask={"000-000-0000"}
                    value={record.onsitePhone === null ? '' : record.onsitePhone}
                    name="onsitePhone"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>Onsite Email</span>
                  <Input
                    value={record.onsiteEmail}
                    name="onsiteEmail"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <label className="k-form-field">
                  <span>Comments</span>
                  <TextArea
                    value={record.comments}
                    name="comments"
                    rows={2}
                    onChange={e => {
                      let changed = record;
                      changed["comments"] = e.value;
                      setRecord({...changed});
                    }}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title={"Accounting"}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Invoice#</span>
                  <CaSelectionList
                    title="Select Invoice"
                    name="invoiceId"
                    apiUrl="api/Invoice/GetInvoiceList"
                    columns={[
                      {field: "id", title: "ID"},
                      {field: "customer", title: "Customer"},
                      {field: "date", title: "Date"},
                      {field: "amount", title: "Amount"}
                    ]}
                    returnCol="id"
                    displayCol="invoiceId"
                    recordField={record.invoiceId}
                    initialVal={record.invoiceId}
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Sale Rate</span>
                  <NumericTextBox
                    format="c2"
                    min={0}
                    value={record.saleRate}
                    name="saleRate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Rate Period</span>
                  <CaSelectInput
                    selectedValue={record.ratePeriod}
                    name="ratePeriod"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    apiUrl={"api/CodeMast/GetComboItems/RATEPERIOD/1"}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Misc Description</span>
                  <Input
                    value={record.miscDescription}
                    name="miscDescription"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Bill#</span>
                  <CaSelectionList
                    title="Select Bill"
                    name="billId"
                    apiUrl="api/Bill/GetBillList"
                    columns={[
                      {field: "id", title: "ID"},
                      {field: "provider", title: "Provider"},
                      {field: "date", title: "Date"},
                      {field: "amount", title: "Amount"}
                    ]}
                    returnCol="id"
                    displayCol="billId"
                    recordField={record.billId}
                    initialVal={record.billId}
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Total Cost</span>
                  <NumericTextBox
                    format="c2"
                    min={0}
                    value={record.costAmt}
                    name="costAmt"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Last Update</span>
                  <DatePicker
                    value={record.lastUpdate}
                    name="lastUpdate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Misc Amount</span>
                  <NumericTextBox
                    format="c2"
                    min={0}
                    value={record.miscAmt}
                    name="miscAmt"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={isInvoiced(record) || isBilled(record) || isCanceled(record)}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
      </React.Fragment>
    );
  }

  //const editMode = !!props.editId;

  const DataEntryTitle = () => {
    return <div>
      <span>{ editMode ? 'Edit Assignment' : 'New Assignment' }</span>
      {editMode &&
      <React.Fragment>
        <span style={{ paddingLeft: '10px' }}>|</span>
        <span style={{ paddingLeft: '10px' }}>
            <CaButton
              onClick={() => cloneAssignment()}
            >
              Clone
            </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>
            <CaButton
              onClick={() => {
                setConfirmationMessage("Are you sure you want to delete this Assignment?");
                setConfirmationTitle("Delete Assignment?");
                setConfirmationVisible(true);
              }}
            >
              Delete
            </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>
            <CaButton
              onClick={() => {
                setConfirmationMessage("Are you sure you want to cancel this Assignment?");
                setConfirmationTitle("Cancel Assignment?");
                setConfirmationVisible(true);
              }}
              disabled = {checkCancelAssignmentStatus()}
            >
              Cancel
            </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>|</span>
        <span style={{ paddingLeft: '10px' }}>
            <CaButton
              onClick={(e) => handleEmailOfferClick(e)}
            >
              Email Offer
            </CaButton>
        </span>
        { displaySyncIndicator &&
            <span style={{ paddingLeft: '10px'}}>
              <CaButton>
                Google Sync&nbsp;&nbsp;<Loader themeColor={"info"} size={"small"} type={"converging-spinner"}/>
              </CaButton>
            </span>
        }
        { !displaySyncIndicator &&
          <span style={{ paddingLeft: '10px'}}>
            <CaButton
              onClick={(e) => handleAddToGoogleCalendarClick(e)}
            >
              Google Calendar
            </CaButton>
          </span>
        }
        <span style={{ paddingLeft: '10px' }}>|</span>
        <span style={{ paddingLeft: '10px'}}>
            <CaButton
              onClick={() => {
                setConfirmationMessage("Are you sure you want to create a new Invoice from this Assignment?");
                setConfirmationTitle("Create Invoice?");
                setConfirmationVisible(true);
              }}
              disabled = {checkCreateInvoiceStatus()}
            >
              Create Invoice
            </CaButton>
        </span>
        <span style={{ paddingLeft: '10px'}}>
            <CaButton
              onClick={() => {
                setConfirmationMessage("Are you sure you want to create a new Bill from this Assignment?");
                setConfirmationTitle("Create Bill?");
                setConfirmationVisible(true);
              }}
              disabled = {checkCreateBillStatus()}
            >
              Create Bill
            </CaButton>
        </span>
      </React.Fragment>
      }
    </div>
  }

  return(
    <React.Fragment>
      <CaGenericDataEntry
        editId={editId}
        toggleVisibility={props.toggleVisibility}
        crudUrl={'api/Assignment'}
        fetchUrl={'api/Assignment/AssignmentExtended'}
        onRecordFetched={(record, setEditId)=> {
          setRecord(record);
          setEditId(record.assignmentId);
          if (record.assignmentId) {
            setEditMode(true);
          }
        }}
        onFormatSaveData={formatPreSaveData}
        onFormatFetchedData={formatFetched}
        title={<DataEntryTitle />}
        formControls={formComponents}
        onValidateRecord={validateRecord}
        confirmDialog = { confirmationVisible && <CaConfirmDialog
          title = { confirmationTitle }
          message = { confirmationMessage }
          toggleVisibility = { setConfirmationVisible }
          onYesClick = { handleConfirmationYesClick }
        /> }
        customNotify={<CaNotification settings={notify} onClose={() => closeNotification()} />}
      />
      { emailOfferVisible && <EmailOffer
        toggleVisibility={() => setEmailOfferVisible(false)}
        loggedInUser={props.loggedInUser}
        emailParams={emailParams}
        onToggleNotify={toggleNotification}
      /> }
      { invoiceVisible && <InvoiceDataEntry editId={invoiceEditId} toggleVisibility={() => setInvoiceVisible(false)} /> }
      { billVisible && <BillDataEntry editId={billEditId} toggleVisibility={() => setBillVisible(false)} /> }
    </React.Fragment>
  )
}