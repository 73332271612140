import * as React from "react";

const TimeCell = props => {
  let value = props.dataItem[props.field];
  if (value !== null) {
    value = new Date('01/01/0001 ' + value);
  }
  
  return (
    <td className={props.className}>{value === null ? '' : (new Date(value)).toLocaleTimeString("en-US", {hour: 'numeric', minute: 'numeric', hour12: true})} </td>
  );
}

export const CaTimeCell = (props) => <TimeCell {...props} />;
