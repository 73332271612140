import * as React from 'react';
import { CaButton } from '../CaUI/CaButtons';
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Logo } from '../security/Logo';

export const AcceptQuote = (props) => {
    const [guid, setGuid] = React.useState("");
    const [quoteInfo, setQuoteInfo] = React.useState({
        reference: "",
        customer: "",
        assignType: "",
        serviceType: "",
        assignStartDate: "",
        assignStartTime: "",
        ratePeriod: "",
        quotedRate: "",
        processed: ""
    });
    const [dialogContent, setDialogContent] = React.useState({
        title: "",
        message: ""
    });
    const [showAlert, setShowAlert] = React.useState(false);
    const [processedVisible, setProcessedVisible] = React.useState(false);

    const prepUi = (quoteInfo) => {
        setQuoteInfo(quoteInfo);
        setDialogContent({
            title: "Alert",
            message: ""
        });
        setProcessedVisible(!(quoteInfo.processed == "1/1/0001" || quoteInfo.processed == "01/01/0001" || quoteInfo.processed == null));
    }

    const confirmQuote = (e) => {
        e.preventDefault();

        if (quoteInfo.processed == "1/1/0001" || quoteInfo.processed == "01/01/0001" || quoteInfo.processed == null) {
            const url = `/api/Quote/AcceptQuote?guid=${guid}`;
            const options = {
                method: 'GET',
                headers: {'content-type': 'application/json'}
            }
            fetch(url, options)
                .then(response => {
                    if (response.status === 400) {
                        setDialogContent({
                            title: "Quote Accept Failed",
                            message: <React.Fragment>
                                <p>An error occurred while processing this Quote. Please contact us using reference# {quoteInfo.reference}.</p>
                            </React.Fragment>
                        });
                        setShowAlert(true);
                    } else {
                        return response.text();
                    }
                })
                .then(result => {
                    if (result) {
                        setDialogContent({
                            title: "Quote Accepted",
                            message: <React.Fragment>
                                <p>You have accepted the terms of Quote Reference# {quoteInfo.reference}. <br /><br />Thanks for your business!</p>
                            </React.Fragment>
                        });
                        setShowAlert(true);
                    }
                })
                .catch(e => {
                    setDialogContent({
                        title: "Quote Accept Failed",
                        message: <React.Fragment>
                            <p>An error occurred while processing this Quote. Please contact us using reference# {quoteInfo.reference}.</p>
                        </React.Fragment>
                    });
                });
        } else {
            setDialogContent({
                title: "Quote Already Accepted",
                message: <React.Fragment>
                    <p>Quote Reference# {quoteInfo.reference} has already been accepted.</p>
                </React.Fragment>
            });
            setShowAlert(true);
        }
    }

    React.useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        let guid = query.get("guid");
        let url = `/api/Quote/GetQuoteInfo?guid=${guid}`;
        let options = {
            method: 'GET',
            headers: {'content-type': 'application/json'}
        };
        fetch(url, options)
            .then(response => response.json())
            .then(quoteInfo => {
                if (quoteInfo !== null) {
                    setGuid(guid);
                    prepUi(quoteInfo);
                }
            });
    }, []);

    return (
        <React.Fragment>
            <div className={"row"} style={{ background: "#4052B6" }}>
                <div className={"col-md-3"}>
                </div>
                <div className={"col-md-6"} style={{ margin: "20px", color: "#ffffff", fontSize: "16pt" }}>
                    <Logo />&nbsp;&nbsp;&nbsp;&nbsp;Accept Quote
                </div>
                <div className={"col-md-3"}>
                </div>
            </div>
            <div className={"row"} style={{ margin: "20px" }}>
                <div className={"col-md-3"}>
                </div>
                <div className={"col-md-6"}>
                    <form className="k-form">
                        <div className={"row"} style={{ margin: "20px" }}>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Quote Reference</span>
                                <Input
                                    value={quoteInfo.reference}
                                    disabled={true}
                                />
                                </label>
                            </div>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Customer</span>
                                <Input
                                    value={quoteInfo.customer}
                                    disabled={true}
                                />
                                </label>
                            </div>
                        </div>

                        <div className={"row"} style={{ margin: "20px" }}>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Assignment Type</span>
                                <Input
                                    value={quoteInfo.assignType}
                                    disabled={true}
                                />
                                </label>
                            </div>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Service Type</span>
                                <Input
                                    value={quoteInfo.serviceType}
                                    disabled={true}
                                />
                                </label>
                            </div>
                        </div>

                        <div className={"row"} style={{ margin: "20px" }}>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Date</span>
                                <Input
                                    type="text"
                                    value={quoteInfo.assignStartDate}
                                    disabled={true}
                                />
                                </label>
                            </div>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Time</span>
                                <Input
                                    type="time"
                                    value={quoteInfo.assignStartTime}
                                    disabled={true}
                                />
                                </label>
                            </div>
                        </div>

                        <div className={"row"} style={{ margin: "20px" }}>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Rate Period</span>
                                <Input
                                    value={quoteInfo.ratePeriod}
                                    disabled={true}
                                />
                                </label>
                            </div>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Rate</span>
                                <NumericTextBox
                                    format="c2"
                                    value={quoteInfo.quotedRate}
                                    disabled={true}
                                />
                                </label>
                            </div>
                        </div>

                        <div className={"row"} style={{ margin: "20px" }}>
                            <div className={"col-md-6"}>
                                <form>
                                    <CaButton
                                        onClick={(e) => confirmQuote(e)}
                                    >
                                        Accept Quote
                                    </CaButton>
                                </form>
                            </div>
                        </div>
                        <div className={"row"} style={{ margin: "20px" }}>
                            { processedVisible && <div className={"col-md-6"}>
                                <label className="k-form-field">
                                <span>Processed</span>
                                <Input
                                    type="text"
                                    value={quoteInfo.processed}
                                    disabled={true}
                                />
                                </label>
                            </div> }
                        </div>
                    </form>
                </div>
                <div className={"col-md-3"}>
                </div>
            </div>

            {showAlert && <Dialog title={dialogContent.title} onClose={() => setShowAlert(false)}>
                <p
                    style={{
                        margin: "10px",
                        width: "225px"
                    }}
                >
                    {dialogContent.message}
                </p>
                <DialogActionsBar>
                    <button className="k-button" onClick={() => setShowAlert(false)}>
                        Close
                    </button>
                </DialogActionsBar>
            </Dialog>}
        </React.Fragment>
    );
}