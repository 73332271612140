import * as React from 'react';
// import {Loader} from "@progress/kendo-react-indicators";

// const BackgroundTaskNotify = {
//   visible: false,
//   updateBackgroundTaskNotify: () => {}
// };

const UserContext = React.createContext({});
export const NotifyContext = React.createContext({
  showNotify: false,
  setShowNotify: (value) => {}
});

// export const NotifyProvider = NotifyContext.Provider;
// export const BackgroundTaskContext = NotifyContext;
export const UserProvider = UserContext.Provider;
export default UserContext;
