import * as React from 'react';
import { Window } from "@progress/kendo-react-dialogs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { telerikGridWithState } from "../CaUI/TelerikGridWithState";
import { CaDateCell } from "../CaUI/CaDateCell";
import { CaCurrencyCell } from "../CaUI/CaCurrencyCell";
import { CaTimeCell } from "../CaUI/CaTimeCell";
import { Loader } from "@progress/kendo-react-indicators";
import { CaNotification, toggleNotify } from "../CaUI/CaNotification";
import { CaButton } from "../CaUI/CaButtons";

export const CreateBill = props => {
  const StatefulGrid = telerikGridWithState(Grid, 
    `api/Assignment/GetNonBilledAssignmentsForProvider/${props.providerId}`, 
    undefined, 
    5);
  const [selectedAssignments, setSelectedAssignments] = React.useState([]);
  const [numOfAssignments, setNumOfAssignments] = React.useState(0);
  const [billTotal, setBillTotal] = React.useState(0);
  const [displayLoader, setDisplayLoader] = React.useState(false);
  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment/>
  });

  const closeNotification = () => {
    let state = Object.assign({}, notify);
    state.visible = false;
    toggleNotify({...state}, setNotify);
  }
  
  const handleItemChecked = (e, id, amount) => {
    let selected = Object.assign([], selectedAssignments);
    let index = selected.indexOf(id);

    if (e.value) {
      if (index === -1) {
        selected.push(id);
        setSelectedAssignments(selected);
        let newTotal = billTotal + Number(amount);
        setBillTotal(newTotal);
      }
    } else {
      if (index > -1) {
        selected.splice(index, 1);
        setSelectedAssignments(selected);
        let newTotal = billTotal - Number(amount);
        setBillTotal(newTotal);
      }
    }
    setNumOfAssignments(selected.length);
    console.log(`Item ${id} was clicked. Array: ${selected} `);
  }

  // used to format numeric values as currency (i.e. $1,500.00)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  const handleErrors = response => {
    if (!response.ok) {
      let notify = {
        visible: true,
        type: 'error',
        message: <React.Fragment>Unable to generate Bill. Please try again later.</React.Fragment>
      }
      toggleNotify(notify, setNotify);
    }
    return response;
  }
  
  const generateBill = (e) => {
    e.preventDefault();
    setDisplayLoader(true);

    let notify = {
      visible: true,
      type: 'success',
      message: ''
    };

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedAssignments)
    }

    const url = `./api/Bill/CreateMultipleAssignmentBill`;

    fetch(url, options)
      .then(handleErrors)
      .then(response => {
        setDisplayLoader(false);
        setBillTotal(0);
        setNumOfAssignments(0);
        setSelectedAssignments([]);
        
        if (response.status >= 400) return;
        
        notify.type = 'success';
        notify.message = <span>Bill Created.</span>;
        toggleNotify(notify, setNotify);
      })
      .catch(e => {});
  }
  
  // custom style for the Stateful grid
  let gridStyle = {
    "margin-top": "5px",
    "margin-bottom": "15px",
    "height": "300px"
  }
  
  const cols = [
      {field: "id", title: "ID"},
      {field: "date", title: "Date", format: "{0:d}"},
      {field: "starttime", title: "Start Time"},
      {field: "endtime", title: "End Time"},
      {field: "amount", title: "Amount"}
  ];

  return (
      <Window
        title={"Create Bill"}
        onClose={() => props.toggleVisibility(false)}
        initialHeight={475}
        initialWidth={1024}
        appendTo={document.getElementById('root')}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <div className={"row"}>
          <div className={"col-md-12"}>
            &nbsp;
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-12"}>
            <h6><b>Select Assignments</b></h6>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-12"}>
            <StatefulGrid style={ gridStyle } filterable={false}>
              <GridColumn
                filterable={false} width={"60px"}
                cell={ cellProps => (
                  <td>
                    <Checkbox
                      checked={selectedAssignments.find(x => x === cellProps.dataItem["id"]) !== undefined}
                      className={"k-primary ca-edit-dialog-button"}
                      icon={"check"}
                      onChange={(e) => {
                        handleItemChecked(e, cellProps.dataItem["id"], cellProps.dataItem["amount"])
                      }}
                    />
                  </td>
                )}
              />
              {cols.map((col) => {
                if (typeof col === 'object') {
                  if (col.title === "Date") {
                    return <GridColumn field={col.field} title={col.title} cell={CaDateCell} />
                  } else if (col.title == "Amount" || col.title == "Cost Rate") {
                    return <GridColumn field={col.field} title={col.title} cell={CaCurrencyCell} />
                  } else if (col.title === "Start Time" || col.title === "End Time") {
                    return <GridColumn field={col.field} title={col.title} cell={CaTimeCell} />
                  } else {
                    return <GridColumn field={col.field} title={col.title} />
                  }
                }
                else
                  return <GridColumn field={col} title="" />
              })}
            </StatefulGrid>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-4"}>
            <b>Number of Assignments:&nbsp;&nbsp;{numOfAssignments}</b> 
          </div>
          <div className={"col-md-2"}>
            <b>Total:&nbsp;&nbsp;{formatter.format(billTotal)}</b>
          </div>
          <div className={"col-md-6 text-right"}>
            { displayLoader &&
            <div className={"col-md-12 text-right"}>
              <span><b>Please Wait...</b>&nbsp;&nbsp;&nbsp;<Loader themeColor={"info"} size={"small"} type={"converging-spinner"}/></span>
            </div>
            }
            { !displayLoader &&
            <CaButton
              onClick={(e) => generateBill(e)}
            >
              Generate Bill
            </CaButton> }
            &nbsp;&nbsp;
            { !displayLoader && <CaButton
              onClick={(e) => props.toggleVisibility(false)}
            >
              Close
            </CaButton> }
          </div>  
        </div>
        <CaNotification settings={notify} onClose={() => closeNotification()} />
      </Window>
  );
}

