import * as React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import { CustomerDataEntry } from "./CustomerDataEntry";
import { telerikGridWithState } from "../CaUI/TelerikGridWithState";
import { CaGridHeader } from "../CaUI/CaGridHeader";
import { CaButton } from "../CaUI/CaButtons";
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { toggleNotify } from "../CaUI/CaNotification";
import moment from 'moment';

/*
 This component shows detailed information in 
 */
const DetailComponent = (props) => {
  return(
    <React.Fragment>
      { props.dataItem.city == null || props.dataItem.city == "" || props.dataItem.state == null || props.dataItem.state == "" ? (
        <section>
          <div class="row">
            <div class="col-6">
              <p><strong>Name:</strong> {props.dataItem.billName}</p>
              <p><strong>Location:</strong> {props.dataItem.city} {props.dataItem.state}</p>
            </div>
            <div class="col-6">
              <p><strong>Phone:</strong> {props.dataItem.phone}</p>
              <p><strong>Email:</strong> {props.dataItem.email}</p>
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div class="row">
            <div class="col-6">
              <p><strong>Name:</strong> {props.dataItem.billName}</p>
              <p><strong>Location:</strong> {props.dataItem.city}, {props.dataItem.state}</p>
            </div>
            <div class="col-6">
              <p><strong>Phone:</strong> {props.dataItem.phone}</p>
              <p><strong>Email:</strong> {props.dataItem.email}</p>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

// Stateful grid contains some wizz-bang features taken from Telerik Docs, such as infinite paging, sorting, etc...
// that we would like on by default. So we're going to semi-wrap this into a reusable component for you to use.
const StatefulGrid = telerikGridWithState(Grid, 'api/Customer/GetCustomerGridView', DetailComponent);

export const CustomerGridView = props => {
  const [visible, setVisible] = React.useState(false);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [editId, setEditId] = React.useState(0);
  const [addVisible, setAddVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(0);
  const gridElement = React.createRef();
  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment />
  });
  const [additionalFilters, setAdditionalFilters] = React.useState({});

  const handleAddNewClick = e => {
    setAddVisible(true);
  }

  const handleEditClick = (e, id) => {
    setEditId(id);
    setVisible(true);
  }

  const handleVisibility = isVisible => {
    setVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  const handleAddVisibility = isVisible => {
    setAddVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  const handleConfirmationClick = () => {
    setConfirmationVisible(false);
    deleteCustomer(deleteId);
  }

  const deleteCustomer = (id) => {
    const url = `/api/Customer/${id}`;
    const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' }
    }

    gridElement.current.deleteRecord(url, options, handleDelete);
  }

  const handleDelete = (response) => {
    let type;
    if (response.ok) {
      type = 'success';
    } else {
      type = 'warning';
    }
    response.json().then(payload => {
      let message;
      if (payload) {
        message = <React.Fragment>
          <span>{payload.message}</span>
        </React.Fragment>;
      }
      let notify = {
        visible: true,
        type: type,
        message: message
      }
      toggleNotify(notify, setNotify);
    });
    return response;
  }

  const closeNotification = () => {
    let state = Object.assign({}, notify);
    state.visible = false;
    setNotify({ ...state });
  }

  return (
    <React.Fragment>
      <CaGridHeader title={"Customers"} onAddNewClick={handleAddNewClick} />
      <StatefulGrid ref={gridElement}>
        <GridColumn field="id" title="ID" width="150px" />
        <GridColumn field="name" title="Name"/>
        <GridColumn field="salesThisYear" title={moment().year() + " Sales"} format="{0:c}" filterable={false} />
        <GridColumn field="totalSales" title="Total Sales" format="{0:c}" filterable={false} />
        <GridColumn field="totalOutstanding" title="Outstanding" format="{0:c}" filterable={false} />
        <GridColumn field="totalPastDue" title="Past Due" format="{0:c}" filterable={false} />
        <GridColumn filterable={false} width="60px"
          cell={ props => (
            <td>
              <CaButton 
                className="k-primary ca-edit-dialog-button" 
                icon="edit"
                onClick={(e) => handleEditClick(e, props.dataItem.id)}
              />
            </td>
          )}
        />
        <GridColumn
            filterable={false}
            width="60px"
            cell={props => (
                <td title="Delete">
                    <Button
                        className="k-primary ca-delete-button"
                        icon="delete"
                        onClick={() => {
                            setDeleteId(props.dataItem.id);
                            setConfirmationMessage("Are you sure you want to delete Customer [" + props.dataItem.id + "]?");
                            setConfirmationTitle("Delete Customer?");
                            setConfirmationVisible(true);
                        }}
                    />
                </td>
            )}
        />
      </StatefulGrid>
      <NotificationGroup
          style={{
              top: 0, left: '50%', transform: 'translateX(-50%)'
          }}
      >
          <Fade enter={true} exit={true}>
              {notify.visible && <Notification
                  type={{ style: notify.type, icon: true }}
                  closable={true}
                  onClose={() => closeNotification()}
              >
                  {notify.message}
              </Notification>}
          </Fade>
      </NotificationGroup>
      { visible && <CustomerDataEntry editId={editId} toggleVisibility={handleVisibility} /> }
      { addVisible && <CustomerDataEntry toggleVisibility={handleAddVisibility} /> }
      { confirmationVisible && <CaConfirmDialog
          title={confirmationTitle}
          message={confirmationMessage}
          toggleVisibility={setConfirmationVisible}
          onYesClick={() => { handleConfirmationClick() }}
      />}
    </React.Fragment>
  );
}