import * as React from 'react';

export var CaContainerGridStyle = {
  height: "94%",
  padding: "0px",
  "border-top-width": "0px",
  "border-left-width": "0px",
  "border-right-width": "0px"
}

export var CaContainerGridHeader = {
  color: "#ffffff",
  background: "#6271c8",
  "padding-bottom": "1px",
  "border-bottom": "1px solid #4f60c1"
}

export var CaContainerGridHeaderContents = {
  "font-size": "20px",
  "margin-left": "30px", 
  "padding-top": "5px", 
  "padding-bottom": "5px"
}

export var CaMainContainer = {
  padding: "0px"
}