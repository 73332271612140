import * as React from 'react';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import {Window} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";
import {Input, Checkbox} from "@progress/kendo-react-inputs";
import { ListView } from "@progress/kendo-react-listview";
import UserContext, {NotifyContext} from "../CaUI/CaContext";
import {CaButton} from "../CaUI/CaButtons";

const {
  Bold, Italic, Underline, Strikethrough,
  AlignLeft, AlignCenter, AlignRight, AlignJustify,
  Indent, Outdent, OrderedList, UnorderedList,
  Undo, Redo, FontSize, FontName
} = EditorTools;

export const EmailOffer = props => {
  const prepSubject =() => {
    return user.emailOfferSubject.replace("<Start_Date/>", props.emailParams.StartDate);
  }
  
  const prepBody = () => {
    var body = user.emailOfferBody;
    body = body
      .replace("<Assign_Type/>", props.emailParams.AssignType)
      .replace("<Service_Type/>", props.emailParams.ServiceType)
      .replace("<Start_Date/>", props.emailParams.StartDate)
      .replace("<Start_Time/>", props.emailParams.StartTime)
      .replace("<End_Time/>", props.emailParams.EndTime);

    if (props.emailParams.ServiceType == "Video") {
      body = body.replace("Location: <City/>, <State/>", "<br>");
    } else {
      body = body
        .replace("<City/>", props.emailParams.City)
        .replace("<State/>", props.emailParams.State);
    }
    return body;
  }
  
  const [recipients, setRecipients] = React.useState([]);
  const user = React.useContext(UserContext);
  const [subject, setSubject] = React.useState(prepSubject());
  const [body, setBody] = React.useState(prepBody());
  const [editor, setEditor] = React.useState(null);

  const {showNotify, message, setShowNotify} = React.useContext(NotifyContext);
  
  const [notify, setNotify] = React.useState({
    show: false,
    type: 'success',
    message: <React.Fragment />
  })
  
  const handleErrors = response => {
    if (!response.ok) {
      response.text().then(text => {
        // TODO: Set error.
      });
    }
    return response;
  }
  
  React.useEffect(() => {
    // Fetch list of recipients from webapi for email offer feature
    const fetchUrl = "api/Provider/GetProviderEmails";
    let options = {
      method: 'GET',
    }
    
    fetch(fetchUrl, options)
      .then(handleErrors)
      .then(response => response.json())
      .then(result => {
        let recipients = result.data;
        recipients.map(r => {
          r.checked = false;
        });
        
        // recipients array will take the following format.
        // [
        //   {id: '1', "name": 'Jane Name', "email": 'johndoe@company.com', checked: false},
        //   {id: '2', "name": 'John Doe', "email": 'janedoe@outlook.com', checked: false},
        //   ...additional Providers
        // ];
        setRecipients(recipients);
      });
  }, []);
  
  const handleSubmit = (e) => {
    e.preventDefault();

    let emails = [];
    for (let x = 0; x < recipients.length; x++) {
      if (recipients[x].checked)
        emails.push(recipients[x].email);
    }
    const view = editor.view;
    const html = EditorUtils.getHtml(view.state);
    const body = {
      Recipients: emails,
      SendSeparate: true,
      Subject: subject,
      Message: html
    }
    const emailUrl = 'api/Routines/SendMessage';
    let options = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    }

    setShowNotify(true, "Sending Email...");
    props.toggleVisibility(false);

    fetch(emailUrl, options)
      .then(response => {
        if (response.ok) {
          let state = Object.assign({}, notify);
          state.type = 'success';
          state.message = <React.Fragment>
            <span>Email offer sent to selected Interpreters.</span>
          </React.Fragment>;
          state.visible = true;
          // setNotify({...state});

          setShowNotify(false, "");
          props.onToggleNotify(state);
          //props.toggleVisibility(false);
        }
      })
      .catch(e => {});
  }
  
  const clearNotify = () => {
    setNotify({
      show: false,
      type: 'success',
      message: <React.Fragment />
    });
  }
  
  const handleOnChange = (e, name) => {
    let rcpts = Object.assign([], recipients);
    for( let x = 0; x < rcpts.length; x++) {
      if (rcpts[x].name === name) rcpts[x].checked = e.value;
    }
    setRecipients([...rcpts]);
  }
  
  const ListViewItemRender = props => {
    let item = props.dataItem;
    return(
      <React.Fragment>
        <div className='row p-1 border-bottom align-middle' style={{ margin: 0 }}>
          <div className='col-12 align-left'>
            <Checkbox label={item.name} value={item.checked} onChange={e => handleOnChange(e, item.name)} />
          </div>
        </div>
      </React.Fragment>
    );
  }


  const styles = `
    p {
        font-family: 'Calibri';
    }
  `;

  const onMount = (event) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);
}
  
  return (
    <React.Fragment>
      <Window
        title={"Email Offer"}
        onClose={() => props.toggleVisibility(false)}
        initialHeight={620}
        initialWidth={880}
        appendTo={document.getElementById('root')}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <form className="k-form">
          <div className={"row email-offer-header"}>
            <div className={"col-md-4"}>
              <div className={"row"}>
                  <div className={"col-md-12"}>
                    <b>Choose Recipients</b>
                  </div>
              </div>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <ListView
                    data={recipients}
                    item={ListViewItemRender}
                    style={{ width: "100%", height: "100%", fontSize: "10pt" }}
                  />  
                </div>
              </div>
            </div>
            <div className={"col-md-8"}>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <b>Subject</b>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <Input value={subject} onChange={(e) => setSubject(e.target.value)}/>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  &nbsp;
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <b>Message</b>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <Editor
                    tools={[
                      [Bold, Italic, Underline, Strikethrough],
                      [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                      [Indent, Outdent],
                      [OrderedList, UnorderedList],
                      [Undo, Redo]
                    ]}
                    contentStyle={{ height: 330, fontSize: "12pt" }}
                    onMount={onMount}
                    defaultContent={body}
                    ref={editor => setEditor(editor)}
                    defaultEditMode={"div"}
                  />
                </div>
              </div>
            </div>
          </div>
          <span className={'k-form-separator'} />
          <span>&nbsp;</span>
          <div className="text-right">
            <CaButton
              onClick={(e) => handleSubmit(e)}>
              Send Offer
            </CaButton>&nbsp;&nbsp;
            <Button style={{"font-weight": "bold"}} onClick={() => props.toggleVisibility(false)}>Cancel</Button>
          </div>
        </form>
      </Window>
      <NotificationGroup
        style={{
          top: 0, left: '50%', transform: 'translateX(-50%)'
        }}
      >
        <Fade enter={true} exit={true}>
          {notify.show && <Notification
            type={{ style: notify.type, icon: true}}
            closable={true}
            onClose={() => clearNotify()}
          >
            {notify.message}
          </Notification>}
        </Fade>
      </NotificationGroup>
    </React.Fragment>
  );
}