import * as React from 'react';
import { Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { telerikGridWithState } from "./TelerikGridWithState";
import { Input } from "@progress/kendo-react-inputs";
import ReactDOM from 'react-dom';
import { CaPrimaryButton } from "./CaUIStyles";
import { CaButton } from "./CaButtons";

export const CaRecordSelectionList = props => {
    const StatefulGrid = telerikGridWithState(Grid, props.apiUrl, undefined, 5);
    const [visible, setVisible] = React.useState(false);
    const [displayValue, setDisplayValue] = React.useState("");

    React.useEffect(() => {
        setDisplayValue(props.initialVal);
    }, [props.initialVal]);

    const handleSelectClick = (e, dataItem) => {
        if (!props.recordFieldsToChange) return;

        // iterate through props.fieldsToChange. Set the values of the record, and finally, its state.    
        let record = Object.assign({}, props.recordState.record);
        props.recordFieldsToChange.forEach(item => {
            record[item.fieldName] = dataItem[item.column];
        });
        props.recordState.setState(record);
    }

    var inputStyle = { position: "absolute", width: "85%" };
    var buttonStyle = {
        position: "absolute",
        right: "20px",
        "background-image": "none",
        "margin-left": "25px",
        "font-weight": "bold"
    };

    // custom style for the Stateful grid
    var gridStyle = {
        "margin-top": "15px",
        "margin-bottom": "15px",
        "height": "84%"
    }

    const rowRender = (element, obj) => {
        const rowProps = {
            ...element.props,
            onDoubleClick: () => {
                handleSelectClick(undefined, obj.dataItem);
                setVisible(false);
            }
        };
        return React.cloneElement(element, { ...rowProps });
    }

    return (
        <React.Fragment>
            <div>
                <div>
                    <Input
                        name={props.name}
                        value={displayValue}
                        required={props.required}
                        onChange={props.onChange}
                        style={inputStyle}
                        disabled={props.disabled}
                    />
                    <CaButton
                        icon={"search"}
                        onClick={(e) => {
                            e.preventDefault();
                            setVisible(true);
                        }}
                        style={buttonStyle}
                        disabled={props.disabled}
                    />
                </div>
            </div>
            {visible && <Window
                title={props.title}
                onClose={() => setVisible(false)}
                initialHeight={510}
                initialWidth={1024}
                appendTo={document.getElementById('root')}
                minimizeButton={() => null}
                maximizeButton={() => null}
            >
                <StatefulGrid style={gridStyle} rowRender={rowRender}>
                    {props.columns.map((col) => {
                        if (typeof col === 'object')
                            return <GridColumn field={col.field} title={col.title} />
                        else
                            return <GridColumn field={col} title="" />
                    })}
                    <GridColumn
                        filterable={false} width={"60px"}
                        cell={cellProps => (
                            <td>
                                <CaButton
                                    className={"k-primary ca-edit-dialog-button"}
                                    icon={"check"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSelectClick(e, cellProps.dataItem);
                                        setVisible(false);
                                    }}
                                />
                            </td>
                        )}
                    />
                </StatefulGrid>
                <div className={"text-right"}>
                    <CaButton
                        onClick={(e) => setVisible(false)}
                    >
                        Cancel
                    </CaButton>
                </div>
            </Window>}
        </React.Fragment>
    );
}
