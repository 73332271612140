import * as React from 'react';
import { Grid, GridColumn, GridCell } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import { AssignmentDataEntry } from "./AssignmentDataEntry";
import { CustomerDataEntry } from "../Customer/CustomerDataEntry";
import { ProviderDataEntry } from "../Provider/ProviderDataEntry";
import { telerikGridWithState } from "../CaUI/TelerikGridWithState";
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";
import { CaDateCell } from "../CaUI/CaDateCell";
import { CaGridHeader } from "../CaUI/CaGridHeader";
import { CaTimeCell } from "../CaUI/CaTimeCell";
import { CaSelectInput } from '../CaUI/CaSelectInput';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { toggleNotify } from "../CaUI/CaNotification";
import { CaButton } from "../CaUI/CaButtons";

/*
 This component shows detailed information in Grid view below.
 */
const DetailComponent = (props) => {
    return (
        <React.Fragment>
            { props.dataItem.serviceType == 'On-Site' || props.dataItem.serviceType == 'On-Site Interpretation' ? (
                <section>
                    <div class="row">
                        <div class="col-3">
                            <p><strong>Quote Reference:&nbsp;&nbsp;</strong> {props.dataItem.quoteId}</p>
                        </div>
                        <div class="col-3">
                            <p><strong>Service Type:&nbsp;&nbsp;</strong> {props.dataItem.serviceType}</p>
                            <p><strong>Location:&nbsp;&nbsp;</strong> {props.dataItem.city} {props.dataItem.state}</p>
                        </div>
                        <div class="col-3">
                            <p><strong>Hearing Consumer:&nbsp;&nbsp;</strong> {props.dataItem.hearingConsumer}</p>
                            <p><strong>ASL Consumer:&nbsp;&nbsp;</strong> {props.dataItem.aslConsumer}</p>
                        </div>
                        <div class="col-3">
                            <p><strong>Onsite Contact:&nbsp;&nbsp;</strong> {props.dataItem.onsiteName}</p>
                            <p><strong>Onsite Phone:&nbsp;&nbsp;</strong> {props.dataItem.onsitePhone}</p>
                        </div>
                    </div>
                </section>
            ) : (
                    <section>
                        <div class="row">
                            <div class="col-3">
                                <p><strong>Quote Reference:&nbsp;&nbsp;</strong> {props.dataItem.quoteId}</p>
                            </div>
                            <div class="col-3">
                                <p><strong>Service Type:&nbsp;&nbsp;</strong> {props.dataItem.serviceType}</p>
                            </div>
                            <div class="col-3">
                                <p><strong>Hearing Consumer:&nbsp;&nbsp;</strong> {props.dataItem.hearingConsumer}</p>
                                <p><strong>ASL Consumer:&nbsp;&nbsp;</strong> {props.dataItem.aslConsumer}</p>
                            </div>
                            <div class="col-3">
                                <p><strong>Onsite Contact:&nbsp;&nbsp;</strong> {props.dataItem.onsiteName}</p>
                                <p><strong>Onsite Phone:&nbsp;&nbsp;</strong> {props.dataItem.onsitePhone}</p>
                            </div>
                        </div>
                    </section>
                )}
        </React.Fragment>
    );
}

// Need to render background color based off of assignment status. Colors are specified in gems.css
const GetCellColor = props => {
    let className = '';
    switch (props.dataItem.status) {
        case 'Canceled':
            className = 'appointment-canceled';
            break;
        case 'Completed':
            className = 'appointment-completed';
            break;
        case 'Unassigned':
            className = 'appointment-unassigned';
            break;
        case 'Bill Received':
            className = 'appointment-bill-received';
            break;
        case 'Assigned':
            className = 'appointment-assigned';
            break;
    }
    return className;
  }

const StatusCell = props => {
    const cellProps = Object.assign({}, props);
    cellProps.className = GetCellColor(props);
    return <GridCell {...cellProps} />;
}

// Stateful grid contains some wizz-bang features taken from Telerik Docs, such as infinite paging, sorting, etc...
// that we would like on by default. So we're going to semi-wrap this into a reusable component for you to use.
const StatefulGrid = telerikGridWithState(Grid, 'api/Assignment/GetListOfAssignments', DetailComponent);

export const AssignmentView = props => {
    const [visible, setVisible] = React.useState(false);
    const [confirmationVisible, setConfirmationVisible] = React.useState(false);
    const [confirmationMessage, setConfirmationMessage] = React.useState("");
    const [confirmationTitle, setConfirmationTitle] = React.useState("");
    const [editId, setEditId] = React.useState(0);
    const [deleteId, setDeleteId] = React.useState(0);
    const [addVisible, setAddVisible] = React.useState(false);
    const [editCustomer, setEditCustomer] = React.useState({
        editId: 0,
        visible: false
    });
    const [editProvider, setEditProvider] = React.useState({
        editId: 0,
        visible: false
    });
    const gridElement = React.createRef();
    const [notify, setNotify] = React.useState({
        visible: false,
        type: 'success',
        message: <React.Fragment />
    });
    const [additionalFilters, setAdditionalFilters] = React.useState({});

    const handleVisibility = isVisible => {
        setVisible(isVisible);
        if (!isVisible) {
            gridElement.current.refreshGrid();
        }
    }

    const handleAddVisibility = isVisible => {
        setAddVisible(isVisible);
        if (!isVisible) {
            gridElement.current.refreshGrid();
        }
    }

    const handleEditClick = (e, id) => {
        setEditId(id);
        setVisible(true);
    }

    const handleConfirmationClick = () => {
        setConfirmationVisible(false);
        deleteAssignment(deleteId);
    }

    const deleteAssignment = (id) => {
        const url = `/api/Assignment/${id}`;
        const options = {
            method: 'DELETE',
            headers: { 'content-type': 'application/json' }
        }

        gridElement.current.deleteRecord(url, options, handleDelete);
    }

    const handleDelete = (response) => {
        let type;
        if (response.ok) {
            type = 'success';
        } else {
            type = 'warning';
        }
        response.json().then(payload => {
            let message;
            if (payload) {
                message = <React.Fragment>
                    <span>{payload.message}</span>
                </React.Fragment>;
            }
            let notify = {
                visible: true,
                type: type,
                message: message
            }
            toggleNotify(notify, setNotify);
            setDeleteId(0);
        });
    }

    const closeNotification = () => {
        let state = Object.assign({}, notify);
        state.visible = false;
        setNotify({ ...state });
    }

    const handleDisplayCustomerEntry = (e, id) => {
        e.preventDefault();
        let custObj = Object.assign({}, editCustomer);
        custObj.editId = id;
        custObj.visible = true;
        setEditCustomer({ ...custObj });
    }

    const closeCustomerEntry = (e) => {
        let custObj = Object.assign({}, editCustomer);
        custObj.visible = false;
        setEditCustomer({ ...custObj });
        gridElement.current.refreshGrid();
    }

    const handleDisplayProviderEntry = (e, id) => {
        e.preventDefault();
        let intObj = Object.assign({}, editProvider);
        intObj.editId = id;
        intObj.visible = true;
        setEditProvider({ ...intObj });
    }

    const closeProviderEntry = (e) => {
        let intObj = Object.assign({}, editProvider);
        intObj.visible = false;
        setEditProvider({ ...intObj });
        gridElement.current.refreshGrid();
    }

    const handleAddNewClick = e => {
        setAddVisible(true);
    }

    return (
        <React.Fragment>
            <CaGridHeader title={"Assignments"} onAddNewClick={handleAddNewClick}>
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>|</span>
                Status
                <CaSelectInput
                    onChange={e => {
                        if (e.target.index == -1) {
                            setAdditionalFilters({
                                field: "Status"
                            });
                            gridElement.current.refreshGrid({
                                field: "Status"
                            });
                        } else {
                            setAdditionalFilters({
                                field: "Status",
                                operator: "eq",
                                value: e.value.id,
                            });
                            gridElement.current.refreshGrid({
                                field: "Status",
                                operator: "eq",
                                value: e.value.id,
                            });
                        }
                    }}
                    style={{ paddingLeft: '10px' }}
                    apiUrl={"api/CodeMast/GetComboItems/ASSIGNSTAT/2"}
                />
            </CaGridHeader>
            <StatefulGrid ref={gridElement} additionalFilters={additionalFilters}>
                <GridColumn field="assignmentId" title="ID" width="120px" />
                <GridColumn field="startDate" title="Start Date" width="110px" filter="date" format="{0:d}" cell={CaDateCell} />
                <GridColumn field="startTime" title="Time" width="90px" filterable={false} cell={CaTimeCell}
                />
                <GridColumn
                    field="customer"
                    title="Customer"
                    width="240px"
                    cell={props => {
                        let value = props.dataItem[props.field];
                        let idField = props.dataItem.customerId;
                        return (
                            <td><a href={'#'} onClick={(e) => handleDisplayCustomerEntry(e, idField)}><b>{value}</b></a></td>
                        );
                    }}
                />
                <GridColumn
                    field="provider"
                    title="Provider"
                    cell={props => {
                        let value = props.dataItem[props.field];
                        let idField = props.dataItem.providerId;
                        return (
                            <td><a href={'#'} onClick={(e) => handleDisplayProviderEntry(e, idField)}><b>{value}</b></a></td>
                        );
                    }}
                />
                <GridColumn 
                    field="status"
                    title="Status"
                    width="110px"
                    cell={StatusCell}
                    filterable={false}
                />
                <GridColumn
                    filterable={false}
                    width="55px"
                    cell={props => (
                        <td title="Edit">
                            <CaButton
                                className="k-primary ca-edit-dialog-button"
                                icon="edit"
                                // make sure to change recnum to the proper id field you will be working with.
                                onClick={(e) => handleEditClick(e, props.dataItem.assignmentId)}
                            />
                        </td>
                    )}
                />
                <GridColumn
                    filterable={false}
                    width="55px"
                    cell={props => (
                        <td title="Delete">
                            <Button
                                className="k-primary ca-delete-button"
                                icon="delete"
                                onClick={() => {
                                    setDeleteId(props.dataItem.assignmentId);
                                    setConfirmationMessage("Are you sure you want to delete Assignment [" + props.dataItem.assignmentId + "]?");
                                    setConfirmationTitle("Delete Assignment?");
                                    setConfirmationVisible(true);
                                }}
                            />
                        </td>
                    )}
                />
            </StatefulGrid>
            <NotificationGroup
                style={{
                    top: 0, left: '50%', transform: 'translateX(-50%)'
                }}
            >
                <Fade enter={true} exit={true}>
                    {notify.visible && <Notification
                        type={{ style: notify.type, icon: true }}
                        closable={true}
                        onClose={() => closeNotification()}
                    >
                        {notify.message}
                    </Notification>}
                </Fade>
            </NotificationGroup>
            { visible && <AssignmentDataEntry editId={editId} toggleVisibility={handleVisibility} />}
            { addVisible && <AssignmentDataEntry toggleVisibility={handleAddVisibility} />}
            { editCustomer.visible && <CustomerDataEntry editId={editCustomer.editId} toggleVisibility={closeCustomerEntry} />}
            { editProvider.visible && <ProviderDataEntry editId={editProvider.editId} toggleVisibility={closeProviderEntry} />}
            { confirmationVisible && <CaConfirmDialog
                title={confirmationTitle}
                message={confirmationMessage}
                toggleVisibility={setConfirmationVisible}
                onYesClick={() => { handleConfirmationClick() }}
            />}
        </React.Fragment>
    );
}