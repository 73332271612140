import * as React from 'react';
import { Grid, GridColumn, GridCell } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import { CashJrnlDataEntry } from "./CashJrnlDataEntry";
import { CustomerDataEntry} from "../Customer/CustomerDataEntry";
import { ProviderDataEntry} from "../Provider/ProviderDataEntry";
import { telerikGridWithState } from "../CaUI/TelerikGridWithState";
import { CaDateCell } from "../CaUI/CaDateCell";
import UserContext from "../CaUI/CaContext";
import { CaGridHeader } from "../CaUI/CaGridHeader";
import { CaButton } from "../CaUI/CaButtons";
import { Checkbox } from '@progress/kendo-react-inputs';
import { toggleNotify } from "../CaUI/CaNotification";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";

/*
 This component shows detailed information when the user clicks the + in the grid row
 */
const DetailComponent = (props) => {
  return(
    <section>
      <div class="row">
        <div class="col-6">
          <p><strong>Name:&nbsp;&nbsp;</strong> {props.dataItem.name}</p>
        </div>
        <div class="col-6">
          <p><strong>Description:&nbsp;&nbsp;</strong> {props.dataItem.description}</p>
        </div>
      </div>
    </section>
  );
}

const balanceCell = props => {
  const cellProps = Object.assign({}, props);
  cellProps.className = "cashjrnl-gridview-balance";
  return <GridCell {...cellProps} />;
}

// Stateful grid contains some wizz-bang features taken from Telerik Docs, such as infinite paging, sorting, etc...
// that we would like on by default. So we're going to semi-wrap this into a reusable component for you to use.
const StatefulGrid = telerikGridWithState(Grid, 'api/CashJrnl/GetCashJrnlGridView', DetailComponent);

export const CashJrnlGridView = props => {
  const [visible, setVisible] = React.useState(false);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [editId, setEditId] = React.useState(0);
  const [deleteId, setDeleteId] = React.useState(0);
  const [reportViewerVisible, setReportViewerVisible] = React.useState(false);
  const [cashJrnlNo, setCashJrnlNo] = React.useState(-1);
  const user = React.useContext(UserContext);
  const [addVisible, setAddVisible] = React.useState(false);
  const gridElement = React.createRef();
  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment />
  });
  const [editCustomer, setEditCustomer] = React.useState({
    editId: 0,
    visible: false
  });
  const [editProvider, setEditProvider] = React.useState({
    editId: 0,
    visible: false
  });
  const [additionalFilters, setAdditionalFilters] = React.useState({
    field: "Balance",
    operator: "neq",
    value: 0
  });

  const handleAddNewClick = e => {
    setAddVisible(true);
  }

  const handleEditClick = (e, id) => {
    setEditId(id);
    setVisible(true);
  }

  const handleConfirmationClick = () => {
    setConfirmationVisible(false);
    deleteCashJrnl(deleteId);
  }

  const deleteCashJrnl = (id) => {
    const url = `/api/CashJrnl/${id}`;
    const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' }
    }

    gridElement.current.deleteRecord(url, options, handleDelete);
  }

  const handleDelete = (response) => {
    let type;
    if (response.ok) {
        type = 'success';
    } else {
        type = 'warning';
    }
    response.json().then(payload => {
        let message;
        if (payload) {
            message = <React.Fragment>
                <span>{payload.message}</span>
            </React.Fragment>;
        }
        let notify = {
            visible: true,
            type: type,
            message: message
        }
        toggleNotify(notify, setNotify);
        setDeleteId(0);
    });
  }

  const closeNotification = () => {
    let state = Object.assign({}, notify);
    state.visible = false;
    setNotify({ ...state });
  }

  const handleVisibility = isVisible => {
    setVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  const handleAddVisibility = isVisible => {
    setAddVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  const handleDisplayParentEntry = (e, id, type) => {
    if (type == "Customer") {
      e.preventDefault();
      let custObj = Object.assign({}, editCustomer);
      custObj.editId = id;
      custObj.visible = true;
      setEditCustomer({...custObj});
    } else {
      e.preventDefault();
      let intObj = Object.assign({}, editProvider);
      intObj.editId = id;
      intObj.visible = true;
      setEditProvider({...intObj});
    }
  }

  const closeCustomerEntry = (e) => {
    let custObj = Object.assign({}, editCustomer);
    custObj.visible = false;
    setEditCustomer({...custObj});
    gridElement.current.refreshGrid();
  }

  const closeProviderEntry = (e) => {
    let intObj = Object.assign({}, editProvider);
    intObj.visible = false;
    setEditProvider({...intObj});
    gridElement.current.refreshGrid();
  }

  return (
    <React.Fragment>
      <CaGridHeader title={"CashJrnl"} onAddNewClick={handleAddNewClick}>
        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>|</span>
        Open Balance Only&nbsp;&nbsp;
        <Checkbox
            onChange={e => {
              if (e.target.element.current.checked) {
                setAdditionalFilters({
                  field: "Balance",
                  operator: "neq",
                  value: 0
                });
                gridElement.current.refreshGrid({
                  field: "Balance",
                  operator: "neq",
                  value: 0
                });
              } else {
                setAdditionalFilters({
                  field: "Balance"
                });
                gridElement.current.refreshGrid({
                  field: "Balance"
                });
              }
            }}
            defaultChecked={true}
        />
      </CaGridHeader>
      <StatefulGrid ref={gridElement} additionalFilters={additionalFilters}>
        <GridColumn field="id" title="ID" width="150px" />
        <GridColumn field="trxDate" title="Date" format="{0:d}" width="175px" cell={CaDateCell} />
        <GridColumn 
          field="name" 
          title="Parent"
          cell={ props => {
            let value = props.dataItem[props.field];
            let idField = props.dataItem.parentId;
            let type = props.dataItem.parentType;
            return (
              <td><a href={'#'} onClick={(e) => handleDisplayParentEntry(e, idField, type)}><b>{value}</b></a></td>
            );
          }}
        />
        <GridColumn field="reference" title="Reference" width="200px" />
        <GridColumn field="description" title="Description" />
        <GridColumn field="receipt" title="Receipt" width="100px" format="{0:a}" filterable={false} />
        <GridColumn field="disburse" title="Disburse" width="100px" format="{0:a}" filterable={false} />
        <GridColumn field="balance" title="Balance" width="100px" format="{0:a}" cell={balanceCell} filterable={false} />
        <GridColumn filterable={false} width="60px"
                    cell={ props => (
                      <td>
                        <CaButton
                          className="k-primary ca-edit-dialog-button"
                          icon="edit"
                          onClick={(e) => handleEditClick(e, props.dataItem.id)}
                        />
                      </td>
                    )}
        />
        <GridColumn
                    filterable={false}
                    width="60px"
                    cell={props => (
                        <td title="Delete">
                            <Button
                                className="k-primary ca-delete-button"
                                icon="delete"
                                onClick={() => {
                                    setDeleteId(props.dataItem.id);
                                    setConfirmationMessage("Are you sure you want to delete CashJrnl [" + props.dataItem.id + "]?");
                                    setConfirmationTitle("Delete CashJrnl?");
                                    setConfirmationVisible(true);
                                }}
                            />
                        </td>
                    )}
                />
      </StatefulGrid>
      <NotificationGroup
          style={{
              top: 0, left: '50%', transform: 'translateX(-50%)'
          }}
      >
          <Fade enter={true} exit={true}>
              {notify.visible && <Notification
                  type={{ style: notify.type, icon: true }}
                  closable={true}
                  onClose={() => closeNotification()}
              >
                  {notify.message}
              </Notification>}
          </Fade>
      </NotificationGroup>
      { visible && <CashJrnlDataEntry editId={editId} toggleVisibility={handleVisibility} /> }
      { addVisible && <CashJrnlDataEntry toggleVisibility={handleAddVisibility} /> }
      { editCustomer.visible && <CustomerDataEntry editId={editCustomer.editId} toggleVisibility={closeCustomerEntry} /> }
      { editProvider.visible && <ProviderDataEntry editId={editProvider.editId} toggleVisibility={closeProviderEntry} /> }
      { confirmationVisible && <CaConfirmDialog
          title={confirmationTitle}
          message={confirmationMessage}
          toggleVisibility={setConfirmationVisible}
          onYesClick={() => { handleConfirmationClick() }}
      />}
    </React.Fragment>
  );
}