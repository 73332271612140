import * as React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { telerikGridWithState } from "../CaUI/TelerikGridWithState";
import { QuoteDataEntry } from "./QuoteDataEntry";
import { CaDateCell } from "../CaUI/CaDateCell";
import { CaTimeCell } from "../CaUI/CaTimeCell";
import { CaGridHeader } from "../CaUI/CaGridHeader";
import { CaButton } from "../CaUI/CaButtons";
import { Checkbox } from '@progress/kendo-react-inputs';

/*
 This component shows detailed information in 
 */
const DetailComponent = (props) => {
  return(
    <section>
      <p><strong>Phone:</strong> {props.dataItem.requestPhone}</p>
      <p><strong>Email:</strong> {props.dataItem.requestEmail}</p>
      <p><strong>Service Type:</strong> {props.dataItem.serviceType}</p>
    </section>
  );
}

// Stateful grid contains some wizz-bang features taken from Telerik Docs, such as infinite paging, sorting, etc...
// that we would like on by default. So we're going to semi-wrap this into a reusable component for you to use.
const StatefulGrid = telerikGridWithState(Grid, 'api/Quote/GetListOfQuotes', DetailComponent);

export const QuoteGridView = props => {
  const [visible, setVisible] = React.useState(false);
  const [addVisible, setAddVisible] = React.useState(false);
  const [editId, setEditId] = React.useState(0);
  const gridElement = React.createRef();
  const [additionalFilters, setAdditionalFilters] = React.useState({
    field: "TaskStatus",
    operator: "eq",
    value: "New"
  });

  const handleEditClick = (e, id) => {
    setEditId(id);
    setVisible(true);
  }

  const handleAddNewClick = e => {
    setAddVisible(true);
  }

  const handleVisibility = isVisible => {
    setVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }

  const handleAddVisibility = isVisible => {
    setAddVisible(isVisible);
    if (!isVisible) {
      gridElement.current.refreshGrid();
    }
  }
  
  return (
    <React.Fragment>
      <CaGridHeader title={"Quotes"} onAddNewClick={handleAddNewClick}>
        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>|</span>
        New Quotes Only&nbsp;&nbsp;
        <Checkbox
            onChange={e => {
              if (e.target.element.current.checked) {
                setAdditionalFilters({
                  field: "TaskStatus",
                  operator: "eq",
                  value: "New"
                });
                gridElement.current.refreshGrid({
                  field: "TaskStatus",
                  operator: "eq",
                  value: "New"
                });
              } else {
                setAdditionalFilters({
                  field: "TaskStatus"
                });
                gridElement.current.refreshGrid({
                  field: "TaskStatus"
                });
              }
            }}
            defaultChecked={true}
        />
      </CaGridHeader>
      <StatefulGrid ref={gridElement} additionalFilters={additionalFilters} >
        <GridColumn field="reference" title="Reference" width="140px" />
        <GridColumn field="assignmentDate" title="Assignment Date" filter="date" width="110px" format="{0:d}" cell={CaDateCell} />
        <GridColumn field="assignmentStartTime" title="Time" filterable={false} width="90px" cell={CaTimeCell} />
        <GridColumn field="customer" title="Customer" />
        <GridColumn field="requestName" title="Request Name" />
        <GridColumn field="taskStatus" title="Status" filterable={false} />
        <GridColumn filterable={false} width="60px"
          cell={ props => (
            <td>
              <CaButton 
                className="k-primary ca-edit-dialog-button" 
                icon="edit"
                // make sure to change reference to the proper id field you will be working with.
                onClick={(e) => handleEditClick(e, props.dataItem.reference)}
              />
            </td>
          )}
        />
      </StatefulGrid>
      { visible && <QuoteDataEntry editId={editId} toggleVisibility={handleVisibility} /> }
      { addVisible && <QuoteDataEntry toggleVisibility={handleAddVisibility} /> }
    </React.Fragment>
  );
}