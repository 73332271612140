import * as React from 'react';
import { Input, TextArea, NumericTextBox } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CaSelectInput } from '../CaUI/CaSelectInput';
import { CaGenericDataEntry, setRecordValue, setTextAreaRecordValue } from '../CaUI/CaGenericDataEntry';
import { CashAppDataEntry } from './CashAppEntry';
import { CaButton } from '../CaUI/CaButtons';
import { CaNotification, toggleNotify } from "../CaUI/CaNotification";
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";
import { CaRecordSelectionList } from '../CaUI/CaRecordSelectionList';
import moment from 'moment';

export const CashJrnlDataEntry = props => {
  const [record, setRecord] = React.useState(props.record !== undefined ? props.record : {});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(!!props.editId);
  const [editId, setEditId] = React.useState(props.editId);
  const [defaultDate, setDefaultDate] = React.useState(moment().toDate());
  const [customerVisible, setCustomerVisible] = React.useState(true);
  const [providerVisible, setProviderVisible] = React.useState(false);
  const [parentTypeTitle, setParentTypeTitle] = React.useState();
  const [cashAppVisible, setCashAppVisible] = React.useState(false);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const [cashAppFeatures, setCashAppFeatures] = React.useState({
    applyEnabled: false,
    unapplyEnabled: false
  });

  React.useEffect(() => {
    if (props.record) formatFetched(record);
  },[]);

  const parentTypes = [
    "Customer",
    "Provider"
  ];

  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment/>
  });

  const toggleNotification = (state) => {
    toggleNotify({...state}, setNotify);
    refreshCashAppOptions(record.cashJrnlId, record.amount);
  }

  const closeNotification = () => {
    let state = Object.assign({}, notify);
    state.visible = false;
    toggleNotify({...state}, setNotify);
  }

  const formatPreSaveData = () => {
    let saveRecord = Object.assign({}, record);

    if ((saveRecord.type !== null) && (typeof saveRecord.type == 'object')) {
        saveRecord.type = saveRecord.type.id;
    }
    if ((saveRecord.format !== null) && (typeof saveRecord.format == 'object')) {
        saveRecord.format = saveRecord.format.id;
    }

    return saveRecord;
  }

  const formatFetched = (record) => {
    let fr = record;
    fr.trxDate = fr.trxDate !== null ? new Date(fr.trxDate) : defaultDate;
    fr.settleDate = fr.settleDate !== null ? new Date(fr.settleDate) : null;
    setParentTypeTitle("Select " + record.parentType);
    setCustomerVisible(record.parentType == "Customer");
    setProviderVisible(record.parentType == "Provider");
    return fr;
  }

  const validateRecord = () => {
    let error = false;
    let messages = [];

    if (!record.trxDate) record.trxDate = defaultDate;

    if (!record.parentId) messages.push("Parent is required!");
    if (!record.trxDate) messages.push("Transaction Date is required!");

    if (messages.length > 0) error = true;
    return ({
      error: error,
      messages: messages
    });
  }

  const refreshCashAppOptions = (cashJrnlId, amount) => {
    const getUrl = `./api/CashApp/GetNetBalance/${cashJrnlId}/${amount}`;
    let options = {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    };
    fetch(getUrl, options)
    .then(response => response.json())
    .then(response => {
        setCashAppFeatures({
          applyEnabled: response.netBalance !== 0,
          unapplyEnabled: response._cashAppCount !== 0
        });
    });
  }

  const unApplyApplication = () => {
    setConfirmationVisible(false);
    const postUrl = "./api/CashApp/UnApplyPayment";
    var data = { cashJrnlId: record.cashJrnlId };
    let options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    }
    fetch(postUrl, options)
        .then(response => {
            if (response.status === 200) {
                let notify = {};
                notify.type = 'success';
                notify.message = <React.Fragment>
                    <span>Journal Entry UnApplied successfully.</span>
                </React.Fragment>;
                notify.visible = true;
                toggleNotify(notify, setNotify);
                // toggleVisibility();
                refreshCashAppOptions(record.cashJrnlId, record.amount);
            } else if (response.status === 400 || response.status === 500) {
                let notify = {};
                notify.type = 'error';
                notify.message = <React.Fragment>
                    <span>An error occurred while unapplying this journal entry. Please try again later.</span>
                </React.Fragment>;
                notify.visible = true;
                toggleNotify(notify, setNotify);
            }
        });
  }

  const isCC = (record) => {
    var realFormat = record.format;
    if (typeof record.format === 'object' && record.format != null) {
      realFormat = record.format.id;
    }
    return realFormat == "CC";
  }

  const formComponents = () => {
    return(
      <React.Fragment>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>ID</span>
              <Input
                value={record.cashJrnlId}
                disabled={true}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
                <span>Type</span>
                <CaSelectInput
                    selectedValue={record.type}
                    name="type"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    apiUrl={"api/CodeMast/GetComboItems/JRNLTYPE/0"}
                />
            </label>
          </div>
          <div className={"col-md-3"}>
              <label className="k-form-field">
                  <span>Format</span>
                  <CaSelectInput
                      selectedValue={record.format}
                      name="format"
                      onChange={e => setRecordValue(e, record, setRecord)}
                      apiUrl={"api/CodeMast/GetComboItems/JRNLFORMAT/0"}
                  />
              </label>
          </div>
          <div className={"col-md-3"}>
              <label className="k-form-field">
                  <span>Date</span>
                  <DatePicker
                      defaultValue={moment().toDate()}
                      value={record.trxDate}
                      name="trxDate"
                      onChange={e => setRecordValue(e, record, setRecord)}
                      required={true}
                  />
              </label>
          </div>
        </div>
        <div class="row">
          <div className={"col-md-3"}>
                <label className="k-form-field">
                    <span>Parent Type</span>
                    <ComboBox
                      name="parentType"
                      value={record.parentType}
                      filterable={false}
                      data={parentTypes}
                      onChange={e => {
                        setRecordValue(e, record, setRecord)
                        if (e.target.value == "Customer") {
                          setCustomerVisible(true);
                          setProviderVisible(false);
                          setParentTypeTitle("Select Customer");
                        } else if (e.target.value == "Provider") {
                          setProviderVisible(true);
                          setCustomerVisible(false);
                          setParentTypeTitle("Select Provider");
                        }
                      }}
                    />
                </label>
          </div>
          { customerVisible && <div className={"col-md-3"}>
              <label className="k-form-field">
                  <span>Parent Id</span>
                  <CaRecordSelectionList
                      title={parentTypeTitle}
                      name="parentId"
                      apiUrl="api/Customer/GetCustomerList"
                      columns={[
                        {field: "id", title: "ID"},
                        {field: "name", title: "Name"},
                        {field: "city", title: "City"},
                        {field: "billName", title: "Bill Name"}
                      ]}
                      initialVal={record.parentId}
                      onChange={e => setRecordValue(e, record, setRecord)}
                      recordFieldsToChange={[
                        {
                          fieldName: "name",
                          column: "name"
                        },
                        {
                          fieldName: "parentId",
                          column: "id"
                        }
                      ]}
                      recordState={{setState: setRecord, record: record}}
                    />

              </label>
          </div> }
          { providerVisible && <div className={"col-md-3"}>
              <label className="k-form-field">
                  <span>Parent Id</span>
                  <CaRecordSelectionList
                      title={parentTypeTitle}
                      name="parentId"
                      apiUrl="api/Provider/GetProviderList"
                      columns={[
                        {field: "id", title: "ID"},
                        {field: "name", title: "Name"}
                      ]}
                      initialVal={record.parentId}
                      onChange={e => setRecordValue(e, record, setRecord)}
                      recordFieldsToChange={[
                        {
                          fieldName: "name",
                          column: "name"
                        },
                        {
                          fieldName: "parentId",
                          column: "id"
                        }
                      ]}
                      recordState={{setState: setRecord, record: record}}
                    />
              </label>
          </div> }
          <div className={"col-md-6"}>
              <label className="k-form-field">
              <span>Name</span>
                  <Input
                      value={record.name}
                      name="name"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
              </label>
          </div>
        </div>
        <div class="row">
          <div className={"col-md-2"}>
              <label className="k-form-field">
                  <span>Reference</span>
                  <Input
                      value={record.reference}
                      name="reference"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
              </label>
          </div>
          <div className={"col-md-6"}>
              <label className="k-form-field">
                  <span>Description</span>
                  <Input
                      value={record.description}
                      name="description"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
              </label>
          </div>
          <div className={"col-md-2"}>
              <label className="k-form-field">
                  <span>Amount</span>
                  <NumericTextBox
                      format="c2"
                      min={0}
                      value={record.amount}
                      name="amount"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
              </label>
          </div>
          <div className={"col-md-2"}>
              <label className="k-form-field">
                  <span>Balance</span>
                  <NumericTextBox
                      format="c2"
                      min={0}
                      value={record.balance}
                      name="balance"
                      onChange={e => setRecordValue(e, record, setRecord)}
                      disabled={true}
                  />
              </label>
          </div>
        </div>
        <TabStrip selected={selectedTab} onSelect={(e) => {setSelectedTab(e.selected)} }>
          <TabStripTab title={"Credit Card Information"} disabled={!isCC(record)}>
            <div className={"row"}>
                <div className={"col-md-3"}>
                  <label className="k-form-field">
                    <span>Payment Code</span>
                    <Input
                        value={record.pmtCode}
                        name="pmtCode"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                  </label>
                </div>
                <div className={"col-md-3"}>
                  <label className="k-form-field">
                    <span>Expiration</span>
                    <Input
                        value={record.expiration}
                        name="expiration"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                  </label>
                </div>
                <div className={"col-md-3"}>
                  <label className="k-form-field">
                    <span>Address</span>
                    <Input
                        value={record.address}
                        name="address"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                  </label>
                </div>
                <div className={"col-md-3"}>
                  <label className="k-form-field">
                    <span>Zip</span>
                    <Input
                        value={record.zip}
                        name="zip"
                        onChange={e => setRecordValue(e, record, setRecord)}
                    />
                  </label>
                </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Response</span>
                  <Input
                      value={record.response}
                      name="response"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Authorization</span>
                  <Input
                      value={record.authorization}
                      name="authorization"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Settle Date</span>
                  <DatePicker
                      value={record.settleDate}
                      name="settleDate"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Reconciled</span>
                  <Input
                      value={record.reconciled}
                      name="reconciled"
                      onChange={e => setRecordValue(e, record, setRecord)}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
      </React.Fragment>
    );
  }

  const DataEntryTitle = () => {
    return <div>
      <span>{ editMode ? 'Edit CashJrnl' : 'New CashJrnl' }</span>
      {editMode && 
      <React.Fragment>
        <span style={{ paddingLeft: '10px' }}>|</span>
        <span style={{ paddingLeft: '10px' }}>
            <CaButton
              onClick={() => setCashAppVisible(true)}
              disabled={!cashAppFeatures.applyEnabled}
            >
              Cash App
            </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>
            <CaButton
              onClick={() => setConfirmationVisible(true)}
              disabled={!cashAppFeatures.unapplyEnabled}
            >
              UnApply
            </CaButton>
        </span>
      </React.Fragment>}
    </div>;
  }

  return(
    <React.Fragment>
      <CaGenericDataEntry
        editId={editId}
        toggleVisibility={props.toggleVisibility}
        crudUrl={'api/CashJrnl'}
        fetchUrl={'api/CashJrnl/CashJrnlExtended'}
        initialHeight={500}
        onFormatSaveData={formatPreSaveData}
        onFormatFetchedData={formatFetched}
        title={<DataEntryTitle />}
        formControls={formComponents}
        onValidateRecord={validateRecord}
        onRecordFetched={(record, setEditId)=> {
          setRecord(record);
          setEditId(record.cashJrnlId);
          if (record.cashJrnlId) {
            setEditMode(true);
            refreshCashAppOptions(record.cashJrnlId, record.amount);
          }
        }}
        customNotify={<CaNotification settings={notify} onClose={() => closeNotification()} />}
        confirmDialog = { confirmationVisible && <CaConfirmDialog
          title = "UnApply Payments?"
          message = "Unapply this Journal Entry?"
          toggleVisibility = { setConfirmationVisible }
          onYesClick = { unApplyApplication }
        /> }
      />
      { cashAppVisible && 
        <CashAppDataEntry 
          cashJrnlId={record.cashJrnlId} 
          toggleVisibility={() => setCashAppVisible(false)} 
          onToggleNotify={toggleNotification}
          /> }
    </React.Fragment>
  )
}
