import React, { Component } from 'react';

export const OAuthFailure = props => {
    React.useEffect(() => {
      const params = window.location.search;
      if (window.opener) {
        // Close the window. OAuth already handled by .NET Google API.
        window.opener.postMessage("OAuthFailure");
        window.close();
      }
    });
  
    return (
      <div>
        <h1>Unauthorized</h1>
        <p>You are not authorized to use Google Calendar features. Please reauthenticate again later. You may close this window.</p>
      </div>
    );
}
