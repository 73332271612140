import * as React from 'react';
import { Input } from "@progress/kendo-react-inputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CaSelectInput } from '../CaUI/CaSelectInput';
import { CaGenericDataEntry, setRecordValue } from '../CaUI/CaGenericDataEntry';
import { Button } from "@progress/kendo-react-buttons";
import {CaNotification, toggleNotify} from "../CaUI/CaNotification";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import { Checkbox } from '@progress/kendo-react-inputs';
import UserContext from '../CaUI/CaContext';

export const TenantDataEntry = props => {
    const [record, setRecord] = React.useState({});
    const [emailSettings, setEmailSettings] = React.useState({});
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [editMode, setEditMode] = React.useState(!!props.editId);
    const [testEmail, setTestEmail] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [themeValue, setThemeValue] = React.useState({text: "Default", id: "./assets/css/Default.css"});
    const [stripeSettings, setStripeSettings] = React.useState({
        enabled: false,
        apiKey: ''
    });
    const user = React.useContext(UserContext);

    const [notify, setNotify] = React.useState({
        visible: false,
        type: 'success',
        message: <React.Fragment/>
    });

    let buttonStyle = {
        position: "absolute",
        right: "-100px",
        "font-weight": "bold"
    };
    
    const colorThemes  = [
        {text: "Default", id: "./assets/css/Default.css"},
        {text: "Dark", id: "./assets/css/Dark.css"}
    ];
    
    const formatPreSaveData = () => {        
        let saveRecord = Object.assign({}, record);

        if ((saveRecord.state !== null) && (typeof saveRecord.state == 'object')) {
            saveRecord.state = saveRecord.state.id;
        }

        if ((saveRecord.primaryUserId !== null) && (typeof saveRecord.primaryUserId == 'object')) {
            saveRecord.primaryUserId = saveRecord.primaryUserId.id;
        }

        return saveRecord;
    }

    const formatFetched = (record) => {
        let fr = record;

        /*
        Use this method to format any record values prior to use in Data Entry form.
         */

        return fr;
    }

    const validateRecord = () => {
        let error = false;
        let messages = [];

        /*
        Use this method to perform record level validation prior to save.
         */

        return ({
            error: error,
            messages: messages
        });
    }

    const setMailServerValue = (e) => {
        let changed = emailSettings;
        changed[e.target.props.name] = e.target.value;
        setEmailSettings({ ...changed });
    }

    const testEmailServer = (e) => {
        e.preventDefault();

        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }

        const url = `api/Routines/TestMessage/${testEmail}`;

        fetch(url, options)
            .then(handleEmailErrors)
            .then(response => {
                let notify = {
                    visible: true,
                    type: 'success',
                    message: <span>Test message Sent.</span>
                }
                toggleNotify(notify, setNotify);
                return response.json();
            })
            .catch(e => { });
    }

    const handleEmailErrors = res => {
        return res;
    }

    const areEmailSettingsValid = () => {
        let error = false;
        let messages = [];

        if (!emailSettings.SmtpServer) messages.push('Email Server is required.');
        if (!emailSettings.SmtpUser) messages.push('UserName is required.');
        if (!emailSettings.SmtpPassword) messages.push('Password is required.');
        if (!confirmPassword) messages.push('Please confirm password entry.');
        if (emailSettings.SmtpPassword && confirmPassword) {
            if (emailSettings.SmtpPassword !== confirmPassword) {
                messages.push('Passwords do not match!');
            }
        }
        if (!emailSettings.SmtpPort) messages.push('Port is required');
        if (messages.length > 0) error = true;

        return ({
            error: error,
            messages: messages
        });
    }

    const saveEmailSettings = (toggleDisplayIndicator) => {
        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(emailSettings)
        }

        const saveUrl = `api/Settings/SaveEmailSettings`;
        toggleDisplayIndicator(true);

        fetch(saveUrl, options)
            .then(handleEmailErrors)
            .then(response => {
                // toggleDisplayIndicator(false);
                // let notify = {
                //     visible: true,
                //     type: 'success',
                //     message: <span>Email Settings Saved</span>
                // }
                // toggleNotify(notify, setNotify);
                // return response.json();
                saveColorScheme(toggleDisplayIndicator);
            })
            .catch(e => {
                toggleDisplayIndicator(false);
            });
    }

    const areStripeSettingsValid = () => {
        let messages = [];
        let error = false;
        if (stripeSettings.apiKey === '' || stripeSettings.apiKey === undefined) {
            messages.push('Please enter your Stripe API key. Then try saving again.');
        }
        if (messages.length > 0) error = true;
        return ({
            error: error,
            messages: messages
        });
    }

    const saveStripeSettings = (toggleDisplayIndicator) => {
        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(stripeSettings)
        }

        const saveUrl = `api/Settings/SaveStripeSettings`;
        toggleDisplayIndicator(true);

        fetch(saveUrl, options)
            .then(response => {
                toggleDisplayIndicator(false);
                let notify = {
                    visible: true,
                    type: 'success',
                    message: <span>Stripe Settings Saved</span>
                }
                toggleNotify(notify, setNotify);
                return response.json();
            })
            .catch(e => {
                toggleDisplayIndicator(false);
            });
    }
    
    const saveColorScheme = (toggleDisplayIndicator) => {
        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(themeValue.id)
        }
        
        const saveUrl = 'api/Settings/SaveColorScheme';
        toggleDisplayIndicator(true);
        
        fetch(saveUrl, options)
            .then(handleEmailErrors)
            .then(response => {
                toggleDisplayIndicator(false);
                let notify = {
                    visible: true,
                    type: 'success',
                    message: <span>UI/Email Settings Saved.</span>
                }
                toggleNotify(notify, setNotify);
                return response.json();
            })
            .catch(e => {
                toggleDisplayIndicator(false);
            });
    }

    const fetchEmailSettings = () => {
        const init = { method: 'GET', accept: 'application/json', headers: {} };
        const url = 'api/Settings/GetEmailSettings';

        fetch(`${url}`, init)
            .then(handleEmailErrors)
            .then(response => response.json())
            .then((res) => {
                let settings = {};
                for (let x = 0; x < res.length; x++) {
                    if (res[x].key === "SmtpUserName") {
                        settings["SmtpUser"] = res[x].value;
                    } else {
                        settings[res[x].key] = res[x].value;
                    }
                    setConfirmPassword(settings.SmtpPassword);
                }
                setEmailSettings({ ...settings });
            });
    }

    const fetchStripeSetting = () => {
        const init = { method: 'GET', accept: 'application/json', headers: {} };
        const url = 'api/Settings/GetStripeSetting';

        fetch(`${url}`, init)
            .then(handleEmailErrors)
            .then(response => response.json())
            .then(res => {
                setStripeSettings(res);
            });
    }

    React.useEffect(() => {
        // Fetch email settings for user
        fetchEmailSettings();
        fetchStripeSetting();
        
        if (user && user.colorScheme) {
            const theme = colorThemes.find(a => a.id === user.colorScheme);
            setThemeValue(theme);
        }
    }, []);

    const handleThemeChange = (e) => {
        setThemeValue(e.value);
        props.changeTheme(e.value.id);
    }

    const handleEnableStripeChanged = (e) => {
        let state = Object.assign({}, stripeSettings);
        state.enabled = e.value;
        setStripeSettings(state);
    }

    const handleStripeKeyChanged = (e) => {
        let state = Object.assign({}, stripeSettings);
        state.apiKey = e;
        setStripeSettings(state);
    }
    
    const formComponents = () => {
        return (
            <React.Fragment>
                <TabStrip style={{ "margin-top": "15px" }} selected={selectedTab} onSelect={(e) => {
                    setSelectedTab(e.selected)
                }}>
                    <TabStripTab title={"Organization Settings"}>
                        <div className={"row"}>
                            <div className={"col-md-2"}>
                                <label className="k-form-field">
                                    <span>ID</span>
                                    <Input
                                        value={record.tenantId}
                                        disabled={true}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-7"}>
                                <label className="k-form-field">
                                    <span>Organization Name</span>
                                    <Input
                                        value={record.tenantName}
                                        name="tenantName"
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                        required={true}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-3"}>
                                <label className="k-form-field">
                                    <span>Primary User</span>
                                    <CaSelectInput
                                        selectedValue={record.primaryUserId}
                                        name="primaryUserId"
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                        apiUrl={"api/User/GetTenantUsers"}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                    <span>Address 1</span>
                                    <Input
                                        name="address1"
                                        value={record.address1}
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                        required={true}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                    <span>Address 2</span>
                                    <Input
                                        name="address2"
                                        value={record.address2}
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-4"}>
                                <label className="k-form-field">
                                    <span>City</span>
                                    <Input
                                        value={record.city}
                                        name="city"
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-4"}>
                                <label className="k-form-field">
                                    <span>State</span>
                                    <CaSelectInput
                                        selectedValue={record.state}
                                        name="state"
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                        apiUrl={"api/CodeMast/GetComboItems/STATE/1"}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-4"}>
                                <label className="k-form-field">
                                    <span>Zip</span>
                                    <Input
                                        value={record.zip}
                                        name="zip"
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <label className="k-form-field">
                                    <span>Contact Person</span>
                                    <Input
                                        name="contactPerson"
                                        value={record.contactPerson}
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-4"}>
                                <label className="k-form-field">
                                    <span>Phone 1</span>
                                    <Input
                                        name="phone1"
                                        value={record.phone1}
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-4"}>
                                <label className="k-form-field">
                                    <span>Phone 2</span>
                                    <Input
                                        name="phone2"
                                        value={record.phone2}
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-4"}>
                                <label className="k-form-field">
                                    <span>Tax ID</span>
                                    <Input
                                        name="taxId"
                                        value={record.taxId}
                                        onChange={e => setRecordValue(e, record, setRecord)}
                                    />
                                </label>
                            </div>
                        </div>
                    </TabStripTab>
                    <TabStripTab title={"Theme & Email Settings"}>
                        <div className={"row"}>
                            <div className={"col-md-3"}>
                                <label className="k-form-field">
                                    <span>Theme:</span>
                                    <DropDownList
                                        textField={"text"}
                                        dataItemKey={"id"}
                                        data={colorThemes}
                                        value={themeValue}
                                        onChange={handleThemeChange}
                                    />
                                </label>
                            </div>
                        </div>
                        <div class={"row"}><div className={"col-md-12"}><hr /></div></div>
                        <div className={"row"}>
                            <div className={"col-md-3"}>
                                <label className="k-form-field">
                                    <span>SMTP Server</span>
                                    <Input
                                        value={emailSettings.SmtpServer}
                                        name="SmtpServer"
                                        onChange={e => setMailServerValue(e)}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-4"}>
                                <label className="k-form-field">
                                    <span>Test Email</span>
                                    <div className={"row"}>
                                        <Input
                                            style={{ "margin-left": "15px" }}
                                            value={testEmail}
                                            name="TestEmailAddress"
                                            onChange={e => setTestEmail(e.target.value)}
                                        />
                                        <Button
                                            style={buttonStyle}
                                            onClick={(e) => testEmailServer(e)}
                                        >
                                            Test Settings
                                        </Button>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-1"}>
                                <label className="k-form-field">
                                    <span>Port</span>
                                    <Input
                                        value={emailSettings.SmtpPort}
                                        name="SmtpPort"
                                        onChange={e => setMailServerValue(e)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-3"}>
                                <label className="k-form-field">
                                    <span>User Name</span>
                                    <Input
                                        value={emailSettings.SmtpUser}
                                        name="SmtpUser"
                                        onChange={e => setMailServerValue(e)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-3"}>
                                <label className="k-form-field">
                                    <span>Password</span>
                                    <Input
                                        value={emailSettings.SmtpPassword}
                                        name="SmtpPassword"
                                        onChange={e => setMailServerValue(e)}
                                        type={"password"}
                                    />
                                </label>
                            </div>
                            <div className={"col-md-3"}>
                                <label className="k-form-field">
                                    <span>Confirm Password</span>
                                    <Input
                                        value={confirmPassword}
                                        name="SmtpPassword"
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        type={"password"}
                                    />
                                </label>
                            </div>
                        </div>
                    </TabStripTab>
                    <TabStripTab title={"Online Payments"}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <b>NOTE</b>: Gems uses Stripe to permit your customers to make online payments via credit card. To enable this feature please check the box and enter your Stripe Account API key.
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                &nbsp;
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-3"}>
                                <label className="k-form-field">
                                    <span>Enable Stripe</span>&nbsp;&nbsp;&nbsp;
                                    <Checkbox value={stripeSettings.enabled} onChange={e => handleEnableStripeChanged(e)} />
                                </label>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-12"}>&nbsp;</div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <label className="k-form-field">
                                    <span>Stripe API Key (For security reasons, your saved API key will not be shown below.)</span>
                                    <Input
                                        value={stripeSettings.apiKey}
                                        name="SmtpPassword"
                                        onChange={e => handleStripeKeyChanged(e.target.value)}
                                        type={"password"}
                                    />
                                </label>
                            </div>
                        </div>                        
                    </TabStripTab>
                </TabStrip>
            </React.Fragment>
        );
    }

    const DataEntryTitle = () => {
        return <div>
            <span>Settings</span>
        </div>;
    }

    return (
        <React.Fragment>
            <CaGenericDataEntry
                editId={props.editId}
                toggleVisibility={props.toggleVisibility}
                crudUrl={'api/Tenant'}
                onRecordFetched={(record, setEditId) => {
                    setRecord(record);
                    setEditId(record.tenantId);
                    if (record.tenantId) {
                        setEditMode(true);
                    }
                }}
                onFormatSaveData={formatPreSaveData}
                onFormatFetchedData={formatFetched}
                title={<DataEntryTitle />}
                formControls={formComponents}
                onValidateRecord={validateRecord}
                // By default, CaGenericDataEntry is setup to work with only one record. 
                // We will augment this to save the email settings record.
                augmentSave={[
                    {
                        // If true, record will save when button is clicked. Here we are 
                        // using the selected tab holding the record to determine that.
                        selected: selectedTab === 1,
                        // record object to save
                        record: emailSettings,
                        // event handler for saving the record
                        onSave: saveEmailSettings,
                        // event handler for validating the record prior to save.
                        onValidate: areEmailSettingsValid
                    },
                    {
                        selected: selectedTab === 2,
                        record: stripeSettings,
                        onSave: saveStripeSettings,
                        onValidate: areStripeSettingsValid
                    }
                ]}
                customNotify={<CaNotification settings={notify} onClose={() => setNotify(false)} />}
            />
        </React.Fragment>
    )
}