import * as React from 'react';
import { Input } from "@progress/kendo-react-inputs";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { Button } from "@progress/kendo-react-buttons";
import {Window} from "@progress/kendo-react-dialogs";
import { setRecordValue } from './CaGenericDataEntry';
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";
import UserContext from "./CaContext";
import {NotifyContext} from "./CaContext";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {CaButton} from "../CaUI/CaButtons";

const {
  Bold, Italic, Underline, Strikethrough,
  AlignLeft, AlignCenter, AlignRight, AlignJustify,
  Indent, Outdent, OrderedList, UnorderedList,
  Undo, Redo, FontName, FontSize
} = EditorTools;

export const CaEmailReportViewer = props => {
  const user = React.useContext(UserContext);
  const {showNotify, message, setShowNotify} = React.useContext(NotifyContext);
  const [visible, setVisible] = React.useState(false);
  const outputType = [
    { id: "PDF", text: "Portal Document Format (PDF)"}
    // { id: "RTF", text: "Rich Text Format (RTF)"}
  ];
  const [emailArguments, setEmailArguments] = React.useState({
    from: user.email,
    to: props.defaultTo ? props.defaultTo : "",
    subject: props.defaultSubject ? props.defaultSubject : "",
    attachedFileName: props.attachmentFileName,
    attachmentType: outputType[0]
  });
  const [editor, setEditor] = React.useState(null);
  const [body, setBody] = React.useState(props.defaultBody ? props.defaultBody : "");

  const [notify, setNotify] = React.useState({
    show: false,
    type: 'success',
    message: <React.Fragment />
  });
  const [quoteReference, setQuoteReference] = React.useState(props.quoteReference);

  const toggleNotify = flag => {
    if (flag) {
      setTimeout(() => {
        let n = notify;
        n.show = false;
        setNotify({...n});
      }, 3000)
    }
  }

  // React.useEffect(() => {},[]);
  
  const validateRequest = () => {
    let error = false;
    let messages = [];

    // TODO: Add logic...

    return ({
      error: error,
      messages: messages
    });
  }
  
  const handleErrors = (response) => {
    if (!response.ok) {
      response.text().then(text => {
        // TODO: Set error.
      });
    }
    return response;
  }

  const clearNotify = () => {
    setNotify({
      show: false,
      type: 'success',
      message: <React.Fragment />
    });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    setShowNotify(true, "Sending Email...");

    props.toggleVisibility(false);

    const view = editor.view;
    const html = EditorUtils.getHtml(view.state);
    let body = {...emailArguments};
    body.Message = html;
    body.ReportArgs = props.reportArguments;
    body.attachmentType = body.attachmentType.id;
    body.quoteReference = props.quoteReference;
    
    const emailUrl = 'api/reports/EmailReport';
    let options = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    }

    fetch(emailUrl, options)
      .then(response => {
        let state = Object.assign({}, notify);
        if (response.status === 200) {
          // Upon success, toggle visibility and show notification from calling app.\
          state.type = 'success';
          state.message = <React.Fragment>
            <span>Email Sent.</span>
          </React.Fragment>;
          state.show = true;
          setShowNotify(false);
          props.onToggleNotify(state);
        } else {
          state.type = 'warning';
          state.message = <React.Fragment>
            <span>Unable to send email messages at this time. Please try again later.</span>
          </React.Fragment>;
          state.show = true;
          
          setNotify({...state});
          toggleNotify(state.show);
        }
      })
      .catch(e => {});
  }
  
  const handleAttachAsChange = (e) => {
    let args = emailArguments;
    args.attachmentType = e.target.value;
    if (args.attachmentType.id === "PDF") {
      args.attachedFileName = args.attachedFileName.replace(".rtf", ".pdf");
    } else {
      args.attachedFileName = args.attachedFileName.replace(".pdf", ".rtf");
    }
    setEmailArguments({...args});
  }

  return (
    <React.Fragment>
      <Window
        title={"Send Email"}
        onClose={() => props.toggleVisibility(false)}
        initialHeight={660}
        initialWidth={800}
        appendTo={document.getElementById('root')}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <React.Fragment>
          <form className="k-form">
            <div className={"row"}>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>From</span>
                  <Input
                    value={emailArguments.from}
                    name="from"
                    onChange={e => setRecordValue(e, emailArguments, setEmailArguments)}
                    required={true}
                    disabled={true}
                  />
                </label>
              </div>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>To</span>
                  <Input
                    value={emailArguments.to}
                    name="to"
                    onChange={e => setRecordValue(e, emailArguments, setEmailArguments)}
                    required={true}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <label className="k-form-field">
                  <span>Subject</span>
                  <Input
                    value={emailArguments.subject}
                    name="subject"
                    onChange={e => setRecordValue(e, emailArguments, setEmailArguments)}
                    required={true}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Attachment</span>
                  <Input
                    value={emailArguments.attachedFileName}
                    disabled={true}
                  />
                </label>
              </div>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Attach File As</span>
                  <DropDownList 
                    filterable={false} 
                    dataItemKey="id" textField="text" 
                    data={outputType} 
                    value={emailArguments.attachmentType} 
                    onChange={handleAttachAsChange}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <Editor
                  tools={[
                    [Bold, Italic, Underline, Strikethrough],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    [Undo, Redo]
                  ]}
                  contentStyle={{ height: 280, fontSize: "12pt" }}
                  defaultContent={body}
                  ref={editor => {setEditor(editor)}}
                  defaultEditMode={"div"}
                />
              </div>
            </div>
          </form>
          <span className={'k-form-separator'} />
          <span>&nbsp;</span>
          <div className="text-right">
            <CaButton onClick={(e) => handleSubmit(e)}>
              Send Email
            </CaButton>&nbsp;&nbsp;
            <Button style={{"font-weight": "bold"}} onClick={() => props.toggleVisibility(false)}>Cancel</Button>
          </div>
        </React.Fragment>
      </Window>
      <NotificationGroup
        style={{
          top: 0, left: '50%', transform: 'translateX(-50%)'
        }}
      >
        <Fade enter={true} exit={true}>
          {notify.show && <Notification
            type={{ style: notify.type, icon: true}}
            closable={true}
            onClose={() => clearNotify()}
          >
            {notify.message}
          </Notification>}
        </Fade>
      </NotificationGroup>
    </React.Fragment>
  );
}