import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export const CaConfirmDialog = props => {
    return (
        <Dialog className="confirm-dialog" title={ props.title } onClose={ () => props.toggleVisibility(false)}>
            <p style={{ margin: "25px", textAlign: "center" }}>{ props.message }</p>
            <DialogActionsBar>
                <button className="k-button" onClick={ () => props.toggleVisibility(false) }>No</button>
                <button className="k-button" onClick={ () => props.onYesClick() }>Yes</button>
            </DialogActionsBar>
        </Dialog>
    )
}