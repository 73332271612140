import React, { useEffect } from 'react';
import { Window } from "@progress/kendo-react-dialogs";
import Iframe from 'react-iframe';
import {CaEmailReportViewer} from "./CaEmailReportViewer";
import {CaButton} from "./CaButtons";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";

export const CaReportPdfViewer = (props) => {
  const linkRef = React.useRef(null);
  const [reportUrl, setReportUrl] = React.useState('');
  const [dimensions, setDimensions] = React.useState({
    height: 600,
    width: 800
  });
  
  const [prevDimensions, setPrevDimensions] = React.useState({});
  const [displayEmail, setDisplayEmail] = React.useState(false);
  const [reportArguments, setReportArguments] = React.useState({});

  const [notify, setNotify] = React.useState({
    show: false,
    type: 'success',
    message: <React.Fragment />
  });

  const toggleNotify = flag => {
    if (flag) {
      setTimeout(() => {
        let n = notify;
        n.show = false;
        setNotify({...n});
      }, 3000)
    }
  }

  const toggleNotification = (state) => {
    setNotify({...state});
    toggleNotify(state.show);
  }

  const clearNotify = () => {
    setNotify({
      show: false,
      type: 'success',
      message: <React.Fragment />
    });
  }

  // Display the error message in a notification towards the top
  const handleErrors = response => {
    if (!response.ok) {
      response.text().then(text => {

      });
    }
    return response;
  }

  const cleanUp = () => {
    const url = "api/Reports/Cleanup";
    const options = {method: 'POST'};
    fetch(url, options).then(() => {});
  }
  
  const getReport = () => {
    const url = "api/Reports/CreateReport";
    const args = {
      "ReportName": props.reportName,
      "FileNameIncludesId": props.fileNameIncludesId ? props.fileNameIncludesId : false,
      "IdKeyName": props.idKeyName,
      "Arguments": { ...props.reportParams },
      "OutputType": "PDF",
    };
    const options = {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify(args)
    };

    fetch(url, options)
      .then(handleErrors)
      .then(response => {
        return response.text();
      })
      .then(text => {
        setReportArguments({...args});
        setReportUrl(text);
      })
      .catch(e => {console.error(e)});
  }
  
  const getAndDownloadReport = (outputType) => {
    const url = "api/Reports/CreateReport";
    const args = {
      "ReportName": props.reportName,
      "FileNameIncludesId": props.fileNameIncludesId ? props.fileNameIncludesId : false,
      "IdKeyName": props.idKeyName,
      "Arguments": { ...props.reportParams },
      "OutputType": outputType
    };
    const options = {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify(args)
    };

    fetch(url, options)
      .then(handleErrors)
      .then(response => {
        return response.text();
      })
      .then(url => {
        downloadReport(url);
      })
      .catch(e => {console.error(e)});
  }
  
  const downloadReport = (url) => {
    let filename = url.split("/")[url.split("/").length-1];
    fetch(url)
      .then(handleErrors)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const href = window.URL.createObjectURL(blob);
        const a = linkRef.current;
        a.download = filename;
        a.target = "_blank";
        a.href = href;
        a.click();
        a.href = '';
        cleanUp();
      })
      .catch(e => {console.error(e)});
  }

  useEffect(() => {
    getReport();
  }, []);

  const windowResized = (e) => {
    setDimensions({width: e.width, height: e.height});
  }

  const windowStageChanged = (e) => {
    if (e.state === 'FULLSCREEN') {
      setPrevDimensions({...dimensions});
      setDimensions({width: window.innerWidth, height: window.innerHeight})
    }
    else {
      setDimensions({width: prevDimensions.width, height: prevDimensions.height});
    }
  }

  const PreviewTitle = () => {
    return <div>
      <span>{props.reportTitle}</span>
      { props.enableEmail &&
      <React.Fragment>
        <span style={{ paddingLeft: '10px' }}>|</span>
        <span style={{ paddingLeft: '10px' }}>
          <CaButton
            onClick={() => {setDisplayEmail(true)} }
          >
            Email Report
          </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>
          <CaButton
            onClick={() => {getAndDownloadReport("PDF")} }
          >
            Download PDF
          </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>
          <CaButton
            onClick={() => {getAndDownloadReport("RTF")} }
          >
            Download RTF
          </CaButton>
        </span>
      </React.Fragment> }
    </div>;
  }
  
  return <React.Fragment>
    <Window
      title={<PreviewTitle enableEmail={props.enableEmail} />}
      onClose={() => props.toggleVisibility(false)}
      initialHeight={600}
      initialWidth={800}
      appendTo={document.getElementById('root')}
      minimizeButton={() => null}
      maximizeButton={() => null}
      onResize={windowResized}
      onStageChange={windowStageChanged}
    >
      <div style={{"padding-top": "10px", "padding-bottom": "3px"}}>
        <Iframe url={reportUrl}
                id="reportIframe"
                width={dimensions.width - 30}
                height={dimensions.height - 75}
        />  
      </div>
      {displayEmail && <CaEmailReportViewer 
        attachmentFileName={reportUrl.split("/")[reportUrl.split("/").length-1]} 
        toggleVisibility={setDisplayEmail}
        defaultSubject={props.defaultSubject}
        defaultBody={props.defaultBody}
        reportArguments = {reportArguments}
        defaultTo={props.defaultTo}
        onToggleNotify={toggleNotification}
        quoteReference={props.quoteReference}
      />}
      <a ref={linkRef} target="_blank" rel="noopener noreferrer" download />
    </Window>
    <NotificationGroup
        style={{
          top: 0, left: '50%', transform: 'translateX(-50%)'
        }}
      >
        <Fade enter={true} exit={true}>
          {notify.show && <Notification
            type={{ style: notify.type, icon: true}}
            closable={true}
            onClose={() => clearNotify()}
          >
            {notify.message}
          </Notification>}
        </Fade>
      </NotificationGroup>
  </React.Fragment>
}