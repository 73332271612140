import * as React from 'react';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { CaButton } from '../CaUI/CaButtons';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { CaDateCell } from '../CaUI/CaDateCell';
import { CaNotification, toggleNotify } from "../CaUI/CaNotification";
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";
import { setRecordValue } from '../CaUI/CaGenericDataEntry';

export const CashAppDataEntry = props => {
    const [record, setRecord] = React.useState({});
    const [toInvoice, setToInvoice] = React.useState(false);
    const [toBill, setToBill] = React.useState(false);
    const [gridData, setGridData] = React.useState([]);
    const [editID, setEditID] = React.useState(null);
    const comboData = [
        "Non-Zero Balances",
        "Positive Balances",
        "Negative Balances",
        "Zero Balances",
        "All Balances"
    ]
    const initialDataState = {
        skip: 0,
        take: 5,
    };
    const [comboValue, setComboValue] = React.useState("Non-Zero Balances");
    const [page, setPage] = React.useState(initialDataState);
    const [notify, setNotify] = React.useState({
        visible: false,
        type: 'success',
        message: <React.Fragment/>
    });
    const [confirmationVisible, setConfirmationVisible] = React.useState(false);

    const rowClick = (event) => {
        if (event.dataItem.payment === 0) {
            event.dataItem.payment = (record.unApplied < event.dataItem.balance ? record.unApplied : event.dataItem.balance);
            event.dataItem.balance = event.dataItem.balance - event.dataItem.payment;
            record.unApplied = record.unApplied - event.dataItem.payment;
            record.applied = record.applied + event.dataItem.payment;
        }
        setEditID(event.dataItem.reference);
        const newData = gridData.map((item) => 
            item.reference === event.dataItem.reference ? { ...item, ["payment"]: event.dataItem.payment, ["balance"]: event.dataItem.balance } : item
        );
        setGridData(newData);
    };

    const closeEdit = (event) => {
        if (event.target === event.currentTarget) {
          setEditID(null);
        }
    };

    const updateApplication = (previousAmount, currentAmount) => {
        let currentRecord = Object.assign({}, record);
        if (!previousAmount) previousAmount = 0;
        currentRecord.unApplied = currentRecord.unApplied + previousAmount - currentAmount;
        currentRecord.applied = currentRecord.applied - previousAmount + currentAmount;
        setRecord(currentRecord);
    }

    const itemChange = (event) => {
        const inEditID = event.dataItem.reference;
        const field = event.field || "";

        const foundRecord = gridData.find(a => a.reference === inEditID);
        if (foundRecord)
            updateApplication(foundRecord.payment, event.value);

        const newData = gridData.map((item) => 
            item.reference === inEditID ? { ...item, [field]: event.value, ["balance"]: (item.balance + item.payment - event.value) } : item
        );
        setGridData(newData);
    };

    var gridStyle = {
        "margin-top": "15px",
        "margin-bottom": "15px",
        "height": "300px"
    }

    const pageChange = (event) => {
        setPage(event.page);
    };
    
    const submitCashApplication = () => {
        setConfirmationVisible(false);

        let data = [];
        // only send ones that have an amount to apply
        gridData.forEach(item => {
            if (item.payment && item.payment > 0) {
                data.push({
                    TenantId: item.tenantId,
                    FromType: "CashJrnl",
                    FromId: props.cashJrnlId,
                    ToType: record.toType,
                    ToId: item.reference,
                    TrxDate: record.applicationDate,
                    Amount: item.payment
                });
            }
        });

        if (data.length > 0) {
            // send to api
            const postUrl = `./api/CashApp/ApplyPayments`;
            let options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            }
            fetch(postUrl, options)
                .then(response => {
                    if (response.status === 200) {
                        let notify = {};
                        notify.type = 'success';
                        notify.message = <React.Fragment>
                            <span>Amounts applied successfully.</span>
                        </React.Fragment>;
                        notify.visible = true;
                        props.onToggleNotify(notify);
                        props.toggleVisibility();
                    } else if (response.status === 500) {
                        let notify = {};
                        notify.type = 'error';
                        notify.message = <React.Fragment>
                            <span>An error occurred while making cash applications.</span>
                        </React.Fragment>;
                        notify.visible = true;
                        props.onToggleNotify(notify);
                    }
                });
        }
    }

    const DataEntryTitle = () => {
        return <div>
            <span>Cash Application</span>
            <React.Fragment>
            <span style={{ paddingLeft: '10px' }}>|</span>
            <span style={{ paddingLeft: '10px' }}>
                <CaButton onClick={() => handleGoApply()}>
                    Go Apply
                </CaButton>
            </span>
        </React.Fragment>
        </div>;
    }

    const handleGoApply = e => {
        setConfirmationVisible(true);
    }

    const fetchGridData = (toType, toId, filter) => {
        if (toType == "Bill") {
            setToBill(true);
            const url = `./api/CashApp/FetchCashAppBillsByProvider/${toId}/${filter}`;
            const options = { method: 'GET', accept: 'application/json', headers: {} };
            fetch(url,options)
                .then(response => response.json())
                .then((result) => {
                    setGridData(result);
                });
        } else {
            setToInvoice(true);
            const url = `./api/CashApp/FetchCashAppInvoicesByCustomer/${toId}/${filter}`;
            const options = { method: 'GET', accept: 'application/json', headers: {} };
            fetch(url,options)
                .then(response => response.json())
                .then((result) => {
                    setGridData(result);
                });
        }
    }

    React.useEffect(() => {
        if (props.cashJrnlId) {
            const url = `./api/CashApp/GetCashAppInfoForParent/${props.cashJrnlId}`;
            const options = { method: 'GET', accept: 'application/json', headers: {} };

            fetch(url, options)
                .then(response => response.json())
                .then((result) => {
                    var record = result;
                    record.applicationDate = record.applicationDate !== null ? new Date(record.applicationDate) : null;
                    setRecord(record);
                    fetchGridData(record.toType, record.toId, "Non-Zero Balances");
                })
                .catch(e => {});
        }
    }, []);

    return(
        <React.Fragment>
            <Window
                onClose={() => props.toggleVisibility(false)}
                toggleVisibility={props.toggleVisibility}
                title={<DataEntryTitle />}
                initialHeight={580}
                initialWidth={1024}
                appendTo={document.getElementById('root')}
                minimizeButton={() => null}
                maximizeButton={() => null}
            >
                <React.Fragment>
                    <form className="k-form">
                        <div className={"row"}>
                            <div className={"col-md-2"}>
                                <label className="k-form-field">
                                    { toInvoice && <span>Customer ID</span> }
                                    { toBill && <span>Provider ID</span> }
                                    <Input value={record.toId} />
                                </label>
                            </div>
                            <div className={"col-md-7"}>
                                <label className="k-form-field">
                                    { toInvoice && <span>Customer Name</span> }
                                    { toBill && <span>Provider Name</span> }
                                    <Input value={record.toName} />
                                </label>
                            </div>
                            <div className={"col-md-3"}>
                                <label className="k-form-field">
                                    <span>Mode</span>
                                    <ComboBox 
                                        data={comboData} 
                                        value={comboValue} 
                                        onChange={(e) => {
                                            setComboValue(e.value);
                                            fetchGridData(record.toType, record.toId, e.value);
                                        }} />
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div className={"col-md-12"} onClick={closeEdit}>
                                <Grid
                                    style={ gridStyle }
                                    pageable={true}
                                    total={gridData.length}
                                    skip={page.skip}
                                    take={page.take}
                                    onPageChange={pageChange}
                                    editField="inEdit"
                                    onRowClick={rowClick}
                                    onItemChange={itemChange}
                                    
                                    data={gridData.length === 0 ? [] : gridData.map((item) => ({
                                        ...item,
                                        inEdit: item.reference === editID,
                                    })).slice(page.skip, page.take + page.skip)}
                                >
                                    { toInvoice && <GridColumn field="invoiceDate" title="Inv Date" width="110px" editable={false} cell={CaDateCell}/> }
                                    { toBill && <GridColumn field="billDate" title="Bill Date" width="110px" editable={false} cell={CaDateCell}/> }
                                    <GridColumn field="dueDate" title="Due Date" width="110px" editable={false} cell={CaDateCell} />
                                    { toInvoice && <GridColumn field="reference" title="Invoice" width="100px" editable={false} /> }
                                    { toBill && <GridColumn field="reference" title="Bill" width="100px" editable={false} /> }
                                    <GridColumn field="description" title="Description" width="340px" editable={false} />
                                    <GridColumn field="amount" title="Amount" width="100px" editor="numeric" format="{0:c}" editable={false} />
                                    <GridColumn field="balance" title="Balance" width="100px" editor="numeric" format="{0:c}" editable={false} />
                                    <GridColumn field="payment" title="Payment" width="110px" editor="numeric" format="{0:c}" />
                                </Grid>
                            </div>
                        </div>
                        <div class="row" onClick={closeEdit}>&nbsp;</div>
                        <div class="row">  
                            <div className={"col-md-2"} onClick={closeEdit}>&nbsp;</div>
                            <div className={"col-md-2 text-right"}>
                                <span>Apply Date</span>
                            </div>                          
                            <div className={"col-md-2"}>
                                <DatePicker
                                    value={record.applicationDate}
                                    onChange={e => {
                                        let newRecord = Object.assign({}, record);
                                        newRecord.applicationDate = e.value;
                                        setRecord(newRecord);
                                    }}
                                />
                            </div>
                            <div className={"col-md-1 text-right"}>
                                <span>Unapplied</span>
                            </div>                          
                            <div className={"col-md-2"}>
                                <NumericTextBox
                                    format="c2"
                                    min={0}
                                    value={record.unApplied}
                                    disabled={true}
                                    spinners={false}
                                />
                            </div>
                            <div className={"col-md-1 text-right"}>
                                <span>Applied</span>
                            </div>                          
                            <div className={"col-md-2"}>
                                <NumericTextBox
                                    format="c2"
                                    min={0}
                                    value={record.applied}
                                    disabled={true}
                                    spinners={false}
                                />
                            </div>
                        </div>
                        <span className={'k-form-separator'} />
                        <div class="row">&nbsp;</div>
                        <div class="row">
                            <div className={"col-md-10"} onClick={closeEdit}>&nbsp;</div>
                            <div className={"col-md-2 text-right"}>
                                <Button style={{"font-weight": "bold"}} onClick={() => props.toggleVisibility(false)}>Close</Button>
                            </div>
                        </div>
                    </form>
                    { confirmationVisible && <CaConfirmDialog
                        title = "Apply Payments?"
                        message = "Are you sure you want to apply payments?"
                        toggleVisibility = { setConfirmationVisible }
                        onYesClick = { submitCashApplication }
                    /> }
                </React.Fragment>
            </Window>
        </React.Fragment>
    )
}
