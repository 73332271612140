import * as React from 'react';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';

const QuoteHeader = () => {
    return (
        <ListViewHeader
            style={{
                background: "#6271c8",
                color: "#FFFFFF",
                fontSize: 14,
            }}
            className="pl-3 pb-2 pt-2"
        >
            New Quotes Available!
        </ListViewHeader>
    );
};

const QuoteCard = props => {
    // const onQuoteClick = props.onQuoteClick;
    const [page, setPage] = React.useState({
        skip: 0,
        take: 3,
    });
    const { skip, take } = page;

    const onQuoteClick = id => {
        props.onQuoteClick(id);
        props.onShowHide(false);
    }

    const QuoteDataItem = props => {
        let item = props.dataItem;

        return (
            <div style={{padding: "15px"}}>
                <div className={"row"}>
                    <div className={"col-sm-9"}>
                        <b>Quote:&nbsp;</b>{item.id}<br/>
                        <b>Date:&nbsp;</b>{item.date}<br/>
                        <b>Requester:&nbsp;</b>{item.name}<br/>
                        <b>Service Type:&nbsp;</b>{item.serviceType}
                    </div>
                    <div className={"col-sm-3 text-right"}>
                        <Button className={"CaPrimaryButton"} onClick={() => onQuoteClick(item.id)}>View</Button>     
                    </div>
                </div>
            </div>
        );
    }

    const handlePageChange = (e) => {
        setPage({
          skip: e.skip,
          take: e.take,
        });
    };

    return(
        <React.Fragment>
            <ListView
                data={props.dataItem.slice(skip, skip + take)}
                item={QuoteDataItem}
                header={QuoteHeader}
            />
            <Pager
                skip={skip}
                take={take}
                onPageChange={handlePageChange}
                total={props.dataItem.length}
            />
        </React.Fragment>
    );
}

const NewQuoteList = props => {
    const [numOfNewQuotes, setNumOfNewQuotes] = React.useState(0);
    const [newQuotes, setNewQuotes] = React.useState({});
    const [show, setShow] = React.useState(false);
    const anchor = React.useRef(null);

    const createItem = () => {
        // TODO: Add logic to create new 
    }

    const checkForNewQuotes = () => {
        let options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}   
        };
    
        const getUrl = `./api/Quote/GetNewQuotes`;

        fetch(getUrl, options)
        .then(response => response.json())
        .then(response => {
            setNewQuotes(response);
            setNumOfNewQuotes(response.length);
            props.onNewQuotesFound({
                found: true,
                numOfQuotes: response.length
            });
        });
    }

    const onClick = () => {
        setShow(!show);
    };

    const handleQuoteClick = (id) => {
        props.onViewNewQuote(id);
    }

    React.useEffect(() => {
        checkForNewQuotes();
    }, []);

    return (
        <React.Fragment>
            <button className="app-bar-button k-button k-button-clear" onClick={onClick} ref={anchor}>
                <BadgeContainer>
                    <span className="k-icon k-i-bell" />
                    { numOfNewQuotes > 0 && <Badge className="CaBadge">{numOfNewQuotes}</Badge> }
                </BadgeContainer>
            </button>
            { numOfNewQuotes > 0 &&
                <Popup 
                    anchor={anchor.current} 
                    popupAlign={{
                        horizontal: "right",
                        vertical: "top",
                    }} 
                    anchorAlign={{
                        horizontal: "right",
                        vertical: "bottom",
                    }}
                    popupClass={"popup-content"}
                    show={show}
                >
                    <QuoteCard dataItem={newQuotes} onQuoteClick={handleQuoteClick} onShowHide={onClick} />
                </Popup>
            }
        </React.Fragment>
    );
  }
  
export const CaNewQuoteList = (props) => <NewQuoteList {...props} />;
  