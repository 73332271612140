import React, { Component } from 'react';

export const OAuthSuccessView = props => {
  React.useEffect(() => {
    const params = window.location.search;
    if (window.opener) {
      // Close the window. OAuth already handled by .NET Google API.
      window.opener.postMessage("OAuthSuccess");
      window.close();
    }
  });
  
  return (
    <div>
      <h1>Success</h1>
      <p>Google Calendar is now setup for use. Please Close this tab.</p>
    </div>
  );
}
