import * as React from 'react';
import { Input, MaskedTextBox, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CaSelectInput } from '../CaUI/CaSelectInput';
import { CaSelectionList } from "../CaUI/CaSelectionList";
import { CaGenericDataEntry, setRecordValue } from '../CaUI/CaGenericDataEntry';
import { Button } from "@progress/kendo-react-buttons";
import { CaReportPdfViewer } from "../CaUI/CaReportPdfViewer";
import { AssignmentDataEntry } from "../assignment/AssignmentDataEntry";
import { CustomerDataEntry } from "../Customer/CustomerDataEntry";
import { CaConfirmDialog } from "../CaUI/CaConfirmDialog";
import UserContext from "../CaUI/CaContext";
import { CaNotification, toggleNotify } from "../CaUI/CaNotification";
import moment from 'moment';
import { CaButton } from "../CaUI/CaButtons";

export const QuoteDataEntry = props => {
  const user = React.useContext(UserContext);
  const [record, setRecord] = React.useState({});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [agreementVisible, setAgreementVisible] = React.useState(false);
  const [assignmentVisible, setAssignmentVisible] = React.useState(false);
  const [assignmentEditId, setAssignmentEditId] = React.useState(0);
  const [agreementSubject, setAgreementSubject] = React.useState("");
  const [agreementBody, setAgreementBody] = React.useState("");
  const [customerVisible, setCustomerVisible] = React.useState(false);
  const [customerRecord, setCustomerRecord] = React.useState(0);
  const [editMode, setEditMode] = React.useState(!!props.editId);
  const [editId, setEditId] = React.useState(props.editId);
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  
  const [notify, setNotify] = React.useState({
    visible: false,
    type: 'success',
    message: <React.Fragment/>
  });
  
  const formatPreSaveData = () => {
    // Clone the worked object.
    let saveRecord = Object.assign({}, record);
    
    // We need to fetch just the IDs from the combo values and format all date/time values
    // before invoking the Save API.
    if ((saveRecord.assignState !== null) && (typeof saveRecord.assignState === 'object')) {
      saveRecord.assignState = saveRecord.assignState.id;
    }
    if ((saveRecord.taskStatus !== null) && (typeof saveRecord.taskStatus === 'object')) {
      saveRecord.taskStatus = saveRecord.taskStatus.id;
    }
    if ((saveRecord.serviceType !== null) && (typeof saveRecord.serviceType === 'object')) {
      saveRecord.serviceType = saveRecord.serviceType.id;
    }
    if ((saveRecord.assignStartTime !== null) && (typeof saveRecord.assignStartTime === 'object')) {
      let time = saveRecord.assignStartTime;
      saveRecord.assignStartTime = `${time.getHours()}:${time.getMinutes()}:0`;
    }
    if ((saveRecord.assignEndTime !== null) && (typeof saveRecord.assignEndTime === 'object')) {
      let time = saveRecord.assignEndTime;
      saveRecord.assignEndTime = `${time.getHours()}:${time.getMinutes()}:0`;
    }
    if ((saveRecord.ratePeriod !== null) && (typeof saveRecord.ratePeriod === 'object')) {
      saveRecord.ratePeriod = saveRecord.ratePeriod.id;
    }
    if ((saveRecord.assignType !== null) && (typeof saveRecord.assignType === 'object')) {
      saveRecord.assignType = saveRecord.assignType.id;
    }
    
    // make sure to set the tenant for the Quote
    if (!editId) {
      saveRecord.tenantId = user.tenantId;
    }
    
    return saveRecord;
  }
  
  const formatFetched = (record) => {
    let fr = record;
    fr.createDate = new Date(fr.createDate);
    fr.assignStartDate = fr.assignStartDate !== null ? new Date(fr.assignStartDate) : null;
    fr.assignEndDate = fr.assignEndDate !== null ? new Date(fr.assignEndDate) : null;
    fr.assignStartTime = fr.assignStartTime !== null ? new Date('01/01/0001 ' + fr.assignStartTime) : null;
    fr.assignEndTime = fr.assignEndTime !== null ? new Date('01/01/0001 ' + fr.assignEndTime) : null;

    if (user.agreementSubject != null && user.agreementSubject != '') {
      setAgreementSubject(user.agreementSubject.replace("<Assign_Start_Date/>", 
      moment(fr.assignStartDate).format("MM/DD/YYYY")).replace("<Reference/>", fr.reference));
    }

    if (user.agreementBody != null && user.agreementBody != '') {
      setAgreementBody(user.agreementBody
        .replace("<Assign_Start_Date/>", moment(fr.assignStartDate).format("MM/DD/YYYY"))
        .replace("<Reference/>", fr.reference));
    }

    return fr;
  }
  
  const validateRecord = () => {
    let error = false;
    let messages = [];

    if (!record.requestName) messages.push('Request Name is required!');
    if (!record.requestPhone) messages.push("Request Phone is required!");
    if (!record.requestEmail) messages.push("Request Email is required!");
    if (!record.customer) messages.push('Customer is required!');
    if (!record.taskStatus) messages.push("Task Status is required!");
    if (!record.assignType) messages.push("Assign Type is required!");
    if (!record.serviceType) messages.push("Service Type is required!");
    if (!record.assignStartDate) messages.push("Start Date is required!");
    if (!record.assignStartTime) messages.push("StartTime is required!");
    if (!record.assignEndDate) messages.push("End Date is required!");
    if (record.assignEndDate < record.assignStartDate) messages.push("End Date must be after Start Date!")
    if (!record.assignEndTime) messages.push("End Time is required!");
    if (record.assignEndTime < record.assignStartTime && record.assignEndDate <= record.assignStartDate) {
      messages.push("End Time must be after Start Time!");
    }
    //if (!record.aslConsumer) messages.push("ASL Consumer is required!");
    //if (!record.hearingConsumer) messages.push("Hearing Consumer is required!");

    if (messages.length > 0) error = true;
    return ({
      error: error,
      messages: messages
    });
  }
  
  const handleResponse = (response) => {
    if (!response.ok) {
      response.json().then(payload => {
        let message;
        if (payload.id) {
          setAssignmentEditId(payload.id);
          message = <React.Fragment>
            <span>{payload.message} &nbsp;&nbsp;&nbsp; <Button
              onClick={() => setAssignmentVisible(true)} className={"ca-notify-warning-button"}>View Assignment</Button></span>
          </React.Fragment>;
        } else {
          message = <React.Fragment>
            <span>{payload.message}</span>
          </React.Fragment>;
        }
        let notify = {
          visible: true,
          type: 'warning',
          message: message
        }
        toggleNotify(notify, setNotify);
      });
      return response;
    }
    else {
      return response.json();
    }
  }

  const handleClone = (response) => {
    if (!response.ok) {
      response.json().then(payload => {
        let message;
        if (payload) {
          setRecord(payload.record);
          message = <React.Fragment>
            <span>{payload.message}</span>
          </React.Fragment>;
        } else {
          message = <React.Fragment>
            <span>{payload.message}</span>
          </React.Fragment>;
        }
        let notify = {
          visible: true,
          type: 'warning',
          message: message
        }
        toggleNotify(notify, setNotify);
      });
      return response;
    }
    else {
      return response.json();
    }
  }
  
  const closeNotification = () => {
    let state = Object.assign({}, notify);
    state.visible = false;
    toggleNotify({...state}, setNotify);
  }
  
  const isParamsForAssignmentValid = () => {
    if (!record.customer) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Missing Customer. Please select or create a New Customer, save the Quote, and try again.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }
    return true;
  }

  const createAgreement = () => {

    const url = `/api/Quote/QuoteExtended/${record.reference}`;
    const options = {
      method: 'GET',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(response => response.json())
      .then(response => {
        response = formatFetched(response);
        setRecord(response);

        if (!record.quotedRate) {
          closeNotification();
          let state = Object.assign({}, notify);
          state.message = <React.Fragment>
            Missing Quoted Rate. Enter the Quoted Rate, save the Quote, and try again.
          </React.Fragment>;
          state.visible = true;
          state.type = 'warning';
          toggleNotify({...state}, setNotify);
          return false;
        }

        if (!record.ratePeriod) {
          closeNotification();
          let state = Object.assign({}, notify);
          state.message = <React.Fragment>
            Missing Rate Period. Enter the Rate Period, save the Quote, and try again.
          </React.Fragment>;
          state.visible = true;
          state.type = 'warning';
          toggleNotify({...state}, setNotify);
          return false;
        }

        setAgreementVisible(true);
      })
      .catch(function(err) {});
  }

  const createCustomer = () => {
    const url = `/api/Customer/CreateCustomerFromQuote/${props.editId}`;
    const options = {
      method: 'GET',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(handleResponse)
      .then(response => {
        setCustomerRecord(response);
        setCustomerVisible(true);
      })
      .catch(function(err) {});
  }
  
  const createAssignment = () => {
    setConfirmationVisible(false);

    if (!record.quotedRate) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Missing Quoted Rate. Enter the Quoted Rate, save the Quote, and try again.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }

    if (!record.ratePeriod) {
      closeNotification();
      let state = Object.assign({}, notify);
      state.message = <React.Fragment>
        Missing Rate Period. Enter the Rate Period, save the Quote, and try again.
      </React.Fragment>;
      state.visible = true;
      state.type = 'warning';
      toggleNotify({...state}, setNotify);
      return false;
    }

    if (!isParamsForAssignmentValid()) return;
    const url = `/api/Assignment/CreateAssignmentFromQuote/${props.editId}`;
    const options = {
      method: 'POST',
      headers: {'content-type': 'application/json'}
    }
    fetch(url, options)
      .then(handleResponse)
      .then(response => {
        if (response.assignmentId) {
          setAssignmentEditId(response.assignmentId);
          closeNotification();
          let state = Object.assign({}, notify);
          state.type = 'success';
          state.message = <React.Fragment>
            <span>Assignment {response.assignmentId} created.&nbsp;&nbsp;&nbsp; <Button
              onClick={() => setAssignmentVisible(true)} className={"ca-notify-success-button"}>View Assignment</Button></span>
          </React.Fragment>;
          state.visible = true;
          toggleNotify({...state}, setNotify);
        }
      })
      .catch(function(err) {});
  }

  const cloneQuote = () => {
    const url = `/api/Quote/CloneQuote/${props.editId}`;
    const options = {
      method: 'GET',
      headers: {'content-type': 'application/json'}
    }
    setRecord({});
    fetch(url, options)
      .then(handleClone)
      .then(response => {
        response = formatFetched(response);
        setRecord({...response})
        setEditId(undefined);
        setEditMode(false);
      })
      .catch(function(err) {});
  }
  
  const formComponents = () => {
    return(
      <React.Fragment>
        <div className={"row"}>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Reference</span>
              <Input
                value={record.reference}
                disabled={true}
              />
            </label>
          </div>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Customer</span>
              <CaSelectionList
                title="Select Customer"
                name="customer"
                apiUrl="api/Customer/GetCustomerList"
                columns={[
                  {field: "id", title: "ID"}, 
                  {field: "name", title: "Name"}, 
                  {field: "city", title: "City"}, 
                  {field: "billName", title: "Bill Name"}
                ]}
                returnCol="name"
                displayCol="name"
                recordField={record.customer}
                initialVal={record.customer}
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
                disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
              />
            </label>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Requester Name</span>
              <Input
                value={record.requestName}
                name="requestName"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
                disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
              />
            </label>
          </div>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Request Phone</span>
              <MaskedTextBox
                value={record.requestPhone === null ? '': record.requestPhone}
                name="requestPhone"
                mask={"000-000-0000"}
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
                disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
              />
            </label>
          </div>
          <div className={"col-md-4"}>
            <label className="k-form-field">
              <span>Request Email</span>
              <Input
                value={record.requestEmail}
                name="requestEmail"
                onChange={e => setRecordValue(e, record, setRecord)}
                required={true}
                disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
              />
            </label>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Status</span>
              <CaSelectInput
                selectedValue={record.taskStatus}
                name="taskStatus"
                onChange={e => setRecordValue(e, record, setRecord)}
                apiUrl={"api/CodeMast/GetComboItems/QUOTESTAT/2"}
                required={true}
              />
            </label>
          </div>
          {/*<div className={"col-md-2"}>*/}
          {/*  <label className="k-form-field">*/}
          {/*    <span>Assign To</span>*/}
          {/*    <Input*/}
          {/*      value={record.taskAssignedTo}*/}
          {/*      name="taskAssignedTo"*/}
          {/*      onChange={e => setRecordValue(e, record, setRecord)}*/}
          {/*    />*/}
          {/*  </label>*/}
          {/*</div>*/}
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Assignment Type</span>
              <CaSelectInput
                selectedValue={record.assignType}
                name="assignType"
                onChange={e => setRecordValue(e, record, setRecord)}
                apiUrl={"api/CodeMast/GetComboItems/ASSIGNTYPE/2"}
                required={true}
                disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
              />
            </label>
          </div>
          <div className={"col-md-3"}>
            <label className="k-form-field">
              <span>Service Type</span>
              <CaSelectInput
                selectedValue={record.serviceType}
                name="serviceType"
                onChange={e => setRecordValue(e, record, setRecord)}
                apiUrl={"api/CodeMast/GetComboItems/SERVTYPE/2"}
                required={true}
                disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
              />
            </label>
          </div>
        </div>
        <TabStrip selected={selectedTab} onSelect={(e) => {setSelectedTab(e.selected)} }>
          <TabStripTab title={"Assignment Details"}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Start Date</span>
                  <DatePicker
                    value={record.assignStartDate}
                    name="assignStartDate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Start Time</span>
                  <TimePicker
                    value={record.assignStartTime}
                    name="assignStartTime"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                    steps = {{
                      hour: 1,
                      minute: 15,
                      second: 60
                    }}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>End Date</span>
                  <DatePicker
                    value={record.assignEndDate}
                    name="assignEndDate"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>End Time</span>
                  <TimePicker
                    value={record.assignEndTime}
                    name="assignEndTime"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                    steps = {{
                      hour: 1,
                      minute: 15,
                      second: 60
                    }}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Address 1</span>
                  <Input
                    value={record.assignAddress1}
                    name="assignAddress1"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-6"}>
                <label className="k-form-field">
                  <span>Address 2</span>
                  <Input
                    value={record.assignAddress2}
                    name="assignAddress2"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>City</span>
                  <Input
                    value={record.assignCity}
                    name="assignCity"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>State</span>
                  <CaSelectInput
                    selectedValue={record.assignState}
                    name="assignState"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    apiUrl={"api/CodeMast/GetComboItems/STATE/1"}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Zip</span>
                  <Input
                    value={record.assignZip}
                    name="assignZip"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title={"Onsite & Consumer Info"}>
            <div className={"row"}>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>ASL Consumer</span>
                  <Input
                    value={record.aslConsumer}
                    name="aslConsumer"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>Hearing Consumer</span>
                  <Input
                    value={record.hearingConsumer}
                    name="hearingConsumer"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>Onsite Name</span>
                  <Input
                    value={record.onsiteName}
                    name="onsiteName"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>Onsite Phone</span>
                  <MaskedTextBox
                    mask={"000-000-0000"}
                    value={record.onsitePhone === null ? '' : record.onsitePhone}
                    name="onsitePhone"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-4"}>
                <label className="k-form-field">
                  <span>Onsite Email</span>
                  <Input
                    value={record.onsiteEmail}
                    name="onsiteEmail"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <label className="k-form-field">
                  <span>Comments</span>
                  <TextArea
                    value={record.comments}
                    name="comments"
                    rows={2}
                    onChange={e => {
                      let changed = record;
                      changed["comments"] = e.value;
                      setRecord({...changed});
                    }}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title={"Rate Information"}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Rate Period</span>
                  <CaSelectInput
                    selectedValue={record.ratePeriod}
                    name="ratePeriod"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    apiUrl={"api/CodeMast/GetComboItems/RATEPERIOD/1"}
                    disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Quoted Rate</span>
                  <NumericTextBox
                      format="c2"
                      min={0}
                      value={record.quotedRate}
                      name="quotedRate"
                      onChange={e => setRecordValue(e, record, setRecord)}
                      disabled={record.taskStatus == 'Accepted' || record.taskStatus == 'Rejected'}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Create Source</span>
                  <Input
                    value={record.createSource}
                    name="createSource"
                    onChange={e => setRecordValue(e, record, setRecord)}
                    disabled={true}
                  />
                </label>
              </div>
              <div className={"col-md-3"}>
                <label className="k-form-field">
                  <span>Create Date</span>
                  <DatePicker
                    value={record.createDate}
                    name="createDate"
                    onChange={e=> setRecordValue(e, record, setRecord)}
                    disabled={true}
                  />
                </label>
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
      </React.Fragment>
    ); 
  }
  
  const DataEntryTitle = () => {
    return <div>
      <span>{ editMode ? 'Edit Quote' : 'New Quote' }</span>
      { editMode && 
      <React.Fragment>
        <span style={{ paddingLeft: '10px' }}>|</span>
        <span style={{ paddingLeft: '10px' }}>
          <CaButton
            onClick={() => cloneQuote()}
          >
            Clone
          </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>|</span>
        <span style={{ paddingLeft: '10px' }}>
          <CaButton
            onClick={() => createCustomer()}
          >
            Create Customer
          </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>
          <CaButton
            onClick={() => setConfirmationVisible(true)}
          >
            Create Assignment
          </CaButton>
        </span>
        <span style={{ paddingLeft: '10px' }}>
          <CaButton
            onClick={() => createAgreement()}
          >
            Interpreter Agreement
          </CaButton>
        </span>
      </React.Fragment> }
    </div>;
  }
  
  return (
    <React.Fragment>
      <CaGenericDataEntry
        editId={editId}
        toggleVisibility={props.toggleVisibility}
        crudUrl={'api/Quote'}
        fetchUrl={'api/Quote/QuoteExtended'}
        onFormatSaveData={formatPreSaveData}
        onFormatFetchedData={formatFetched}
        title={<DataEntryTitle />}
        formControls={formComponents}
        onValidateRecord={validateRecord}
        onRecordFetched={(record, setEditId)=> {
          setRecord(record);
          setEditId(record.reference);
          if (record.reference) {
            setEditMode(true);
          }
        }}
        confirmDialog = { confirmationVisible && <CaConfirmDialog
          title = "Create Assignment?"
          message = "Are you sure you want to create a new Assignment from this Quote?"
          toggleVisibility = { setConfirmationVisible }
          onYesClick = { createAssignment }
          /> }
        customNotify={<CaNotification settings={notify} onClose={() => closeNotification()} />}
      />
      { agreementVisible && <CaReportPdfViewer
        reportName={user.agreementReport}
        reportParams={{
          Customer: record.customer,
          Assign_Start_Date: record.assignStartDate,
          Assign_Start_Time: record.assignStartTime.toLocaleTimeString(),
          Assign_End_Date: record.assignEndDate,
          Assign_End_Time: record.assignEndTime.toLocaleTimeString(),
          Reference: record.reference,
          Rate: record.quotedRate,
          RatePeriod: record.ratePeriod,
          AcceptUrl: record.acceptUrl
        }}
        reportTitle="Preview - Interpreter Agreement"
        toggleVisibility={setAgreementVisible}
        enableEmail={true}
        fileNameIncludesId={true}
        idKeyName={'Reference'}
        defaultSubject={agreementSubject}
        defaultBody={agreementBody}
        defaultTo={record.requestEmail}
        customNotify={<CaNotification settings={notify} onClose={() => closeNotification()} />}
        quoteReference={record.reference}
      />}
      { customerVisible && <CustomerDataEntry record={customerRecord} toggleVisibility={() => setCustomerVisible(false)} /> }
      { assignmentVisible && <AssignmentDataEntry editId={assignmentEditId} toggleVisibility={() => setAssignmentVisible(false)} /> }
    </React.Fragment>
  )
}
